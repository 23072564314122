import type { Country } from '@seek/melways-sites';
import { JobsdbLogoSvg } from './Logo/JobsdbLogo';
import React from 'react';
import { JobstreetLogoSG } from './Logo/JobstreetLogo';
import { SeekAnzLogoSvg } from './Logo/SeekAnzLogo';

export const getLogoByCountry = (country: Country) => {
  switch (country) {
    case 'ph':
    case 'sg':
    case 'id':
    case 'my':
      return <JobstreetLogoSG />;
    case 'hk':
    case 'th':
      return <JobsdbLogoSvg />;
    default:
      return <SeekAnzLogoSvg />;
  }
};
