import type { BlocksFragment } from '@seek/cmsu-cms-connect';
import type { FindByTypename } from '../FindByTypename';
import type { AdditionalData } from '../AdditionalData';

/**
 * Union of all Block types
 */
type Block = BlocksFragment['items'][number];

/**
 * Returns the Block type given the Block typename
 */
type BlockOf<Type extends Block['__typename']> = FindByTypename<Block, Type>;

export type BlockItemRenderers = Partial<{
  [Property in Block['__typename']]: (
    block: Omit<BlockOf<Property>, '__typename'>,
    additionalData: AdditionalData,
  ) => JSX.Element | null;
}>;

type Props = {
  renderers: BlockItemRenderers;
  block: Block;
  additionalData: AdditionalData;
};

/**
 * Matches a Block to the given renders
 */
export const BlockItemRenderer = ({
  renderers,
  block,
  additionalData,
}: Props) =>
  // Setting `block` as any to suppress `never` errors
  renderers[block.__typename]?.(block as any, additionalData) || null;
