import { Root } from './client/Root';
import { Page } from './client/components/pages/Page/Page';
import type { RouteObject } from 'react-router-dom';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { ConfigStateContext } from './client/hooks/useConfig';
import { ErrorBoundary } from './ErrorBoundary';
import { pageLoader } from './utils/pageLoader';
import { EmailPreview } from './client/components/modules/ContactForm/EmailContentComponent';

export type RouteProps = {
  client: ApolloClient<NormalizedCacheObject>;
  config: ConfigStateContext;
};

export const makeRoutes = ({ config, client }: RouteProps): RouteObject[] => {
  const configProps = { config, client };
  const devRoutes =
    config.environment === 'development'
      ? [{ path: 'email-preview', element: <EmailPreview /> }]
      : [];

  return [
    {
      path: '/',
      errorElement: <ErrorBoundary />,
      element: <Root client={client} config={config} />,
      children: [
        {
          path: ':section?',
          loader: pageLoader(configProps),
          children: [
            {
              path: ':subSection?',
              element: <Page />,
              loader: pageLoader(configProps),
              children: [
                {
                  path: ':slug?',
                  element: <Page />,
                  loader: pageLoader(configProps),
                },
              ],
            },
          ],
        },
        ...devRoutes,
      ],
    },
  ];
};
