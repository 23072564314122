// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Required field': () =>
      | 'Required field'
      | 'จำเป็นต้องระบุ'
      | 'Wajib diisi'
      | '[Řẽẽq̇ǚǚììřẽẽƌ ƒììẽẽƚƌ]';
    'Min length': (values: { value: string }) => string;
    'Max length': (values: { value: string }) => string;
    'Min number': (values: { value: string }) => string;
    'Max number': (values: { value: string }) => string;
    'Invalid email': () =>
      | 'Invalid email address, use format example@domain.com'
      | 'อีเมลไม่ถูกต้อง กรุณาระบุอีเมลในรูปแบบ example@domain.com'
      | 'Alamat email tidak sah, gunakan format example@domain.com'
      | '[Ïกี้ṽăăăƚìììƌ ẽẽẽm̂ăăăìììƚ ăăăƌƌřẽẽẽšš, ǚǚǚšẽẽẽ ƒöööřm̂ăăăṯ ẽẽẽꭕăăăm̂ƥƚẽẽẽ@ƌöööm̂ăăăìììกี้.çöööm̂]';
    'Min checked item': (values: { value: string }) => string;
    'Max checked item': (values: { value: string }) => string;
    Submit: () => 'Submit' | 'ส่ง' | 'Kirim' | '[Šǚǚǚßm̂ìììṯ]';
  }
>({
  en: createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  th: createLanguage({
    'Required field': 'จำเป็นต้องระบุ',
    'Min length': '{value} ตัวอักษรหรือมากกว่า',
    'Max length': '{value} ตัวอักษรหรือน้อยกว่า',
    'Min number': 'ต้องเท่ากับ {value} หรือมากกว่า',
    'Max number': 'ต้องเท่ากับ {value} หรือน้อยกว่า',
    'Invalid email':
      'อีเมลไม่ถูกต้อง กรุณาระบุอีเมลในรูปแบบ example@domain.com',
    'Min checked item': 'ต้องเลือก {value} หรือมากกว่า',
    'Max checked item': 'ต้องเลือก {value} หรือน้อยกว่า',
    Submit: 'ส่ง',
  }),
  id: createLanguage({
    'Required field': 'Wajib diisi',
    'Min length': '{value} karakter atau lebih',
    'Max length': '{value} karakter atau kurang',
    'Min number': 'Harus {value} atau lebih',
    'Max number': 'Harus {value} atau kurang',
    'Invalid email':
      'Alamat email tidak sah, gunakan format example@domain.com',
    'Min checked item': 'Harus memilih {value} atau lebih',
    'Max checked item': 'Harus memilih {value} atau kurang',
    Submit: 'Kirim',
  }),
  zh: createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-AU': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-HK': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-ID': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-MY': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-NZ': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-PH': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-SG': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-TH': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'th-TH': createLanguage({
    'Required field': 'จำเป็นต้องระบุ',
    'Min length': '{value} ตัวอักษรหรือมากกว่า',
    'Max length': '{value} ตัวอักษรหรือน้อยกว่า',
    'Min number': 'ต้องเท่ากับ {value} หรือมากกว่า',
    'Max number': 'ต้องเท่ากับ {value} หรือน้อยกว่า',
    'Invalid email':
      'อีเมลไม่ถูกต้อง กรุณาระบุอีเมลในรูปแบบ example@domain.com',
    'Min checked item': 'ต้องเลือก {value} หรือมากกว่า',
    'Max checked item': 'ต้องเลือก {value} หรือน้อยกว่า',
    Submit: 'ส่ง',
  }),
  'id-ID': createLanguage({
    'Required field': 'Wajib diisi',
    'Min length': '{value} karakter atau lebih',
    'Max length': '{value} karakter atau kurang',
    'Min number': 'Harus {value} atau lebih',
    'Max number': 'Harus {value} atau kurang',
    'Invalid email':
      'Alamat email tidak sah, gunakan format example@domain.com',
    'Min checked item': 'Harus memilih {value} atau lebih',
    'Max checked item': 'Harus memilih {value} atau kurang',
    Submit: 'Kirim',
  }),
  'zh-HK': createLanguage({
    'Required field': 'Required field',
    'Min length': '{value} characters or more',
    'Max length': '{value} characters or less',
    'Min number': 'Must be {value} or more',
    'Max number': 'Must be {value} or less',
    'Invalid email': 'Invalid email address, use format example@domain.com',
    'Min checked item': 'Must select {value} or more',
    'Max checked item': 'Must select {value} or less',
    Submit: 'Submit',
  }),
  'en-PSEUDO': createLanguage({
    'Required field': '[Řẽẽq̇ǚǚììřẽẽƌ ƒììẽẽƚƌ]',
    'Min length': '[{value} çḩăăăřăăăçṯẽẽẽřš öööř m̂öööřẽẽẽ]',
    'Max length': '[{value} çḩăăăřăăăçṯẽẽẽřš öööř ƚẽẽẽšš]',
    'Min number': '[Ṃǚǚǚšṯ ßẽẽẽ {value} öööř m̂öööřẽẽẽ]',
    'Max number': '[Ṃǚǚǚšṯ ßẽẽẽ {value} öööř ƚẽẽẽšš]',
    'Invalid email':
      '[Ïกี้ṽăăăƚìììƌ ẽẽẽm̂ăăăìììƚ ăăăƌƌřẽẽẽšš, ǚǚǚšẽẽẽ ƒöööřm̂ăăăṯ ẽẽẽꭕăăăm̂ƥƚẽẽẽ@ƌöööm̂ăăăìììกี้.çöööm̂]',
    'Min checked item': '[Ṃǚǚǚšṯ šẽẽẽƚẽẽẽçṯ {value} öööř m̂öööřẽẽẽ]',
    'Max checked item': '[Ṃǚǚǚšṯ šẽẽẽƚẽẽẽçṯ {value} öööř ƚẽẽẽšš]',
    Submit: '[Šǚǚǚßm̂ìììṯ]',
  }),
});

export default translations;
