import {
  type ElementNode,
  isText,
  type Node,
  type Text,
} from '@graphcms/rich-text-types';
import React from 'react';
import { RenderNode } from './RenderNode';
import type { RichTextProps } from './types';
import { getArrayOfElements } from './utils/getArrayOfElements';
import { formatNumberedList } from './utils/formatNumberedLists';

type RenderElementsProps = RichTextProps & {
  parent?: Node | null;
};

export function RenderElements({
  content,
  references,
  renderers,
  parent,
  wrapper,
}: RenderElementsProps) {
  const elements = getArrayOfElements(content);
  const hasNumberedList = elements.some(
    (element) => element.type === 'numbered-list',
  );
  const formattedElements = hasNumberedList
    ? (formatNumberedList(elements) as ElementNode[])
    : elements;

  const isTopLevel = !parent;
  const nodes = formattedElements
    // Filter out any empty paragraphs.
    // Hygraph adds an empty line at the end of every rich-text intentionally so we clean this up.
    .reduce<(ElementNode | Text)[]>(filterEmpty, [])
    .map((node, index) => (
      <RenderNode
        node={node}
        parent={parent || null}
        renderers={renderers}
        references={references}
        key={'id' in node ? node.id : index}
      />
    ));

  if (isTopLevel && wrapper) {
    const Wrapper = wrapper;
    return <Wrapper>{nodes}</Wrapper>;
  }

  return <>{nodes}</>;
}

/**
 * Reducer that filters out empty paragraphs recursively.
 */
const filterEmpty = (
  carry: (ElementNode | Text)[],
  elm: ElementNode | Text,
): (ElementNode | Text)[] => {
  if (isText(elm)) {
    return Boolean(elm.text) ? [...carry, elm] : carry;
  }

  // Skip if it's not a paragraph
  if (elm.type !== 'paragraph') {
    return [...carry, elm];
  }

  // Filter the children recursively
  const children = elm.children.reduce<(ElementNode | Text)[]>(filterEmpty, []);

  return children.length ? [...carry, { ...elm, children }] : carry;
};
