import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  title: string;
}

const Helm = ({ title }: HelmetProps) => (
  <Helmet>
    <title>{`SEEK SARA Awards - ${title}`}</title>
    <meta
      name="description"
      content={`The SEEK Annual Recruitment Awards (SARAs) celebrate the people and recruitment businesses striving for excellence in the industry.
      The awards recognise the dedication and innovation of recruitment professionals from across the country, as chosen by a panel of expert judges`}
    />
  </Helmet>
);

export { Helm };
