import { Text, IconChevron, Box, TextLink, Bleed } from 'braid-design-system';
import React from 'react';

type BreadCrumbItem = {
  url?: string;
  text: string;
};

type Props = {
  items: BreadCrumbItem[];
};

const LineSpacer = () => (
  <Box
    component="span"
    display="inlineBlock"
    paddingBottom={{ mobile: 'xxsmall' }}
  >
    &#8288;
  </Box>
);

const Breadcrumbs = ({ items }: Props) => (
  <Box marginY="gutter">
    <Bleed component="span" bottom={{ mobile: 'xxsmall' }}>
      <Text size="small">
        {items
          .filter(({ text }) => text)
          .map(({ url, text }, idx) => (
            <Box key={idx} component={'span'}>
              {url ? (
                <>
                  <TextLink hitArea="large" href={url}>
                    {text}
                  </TextLink>
                  <LineSpacer />{' '}
                  {idx !== items.length - 1 && (
                    <IconChevron
                      direction="right"
                      alignY="lowercase"
                      tone="secondary"
                    />
                  )}{' '}
                  <LineSpacer />
                </>
              ) : (
                <>
                  <LineSpacer />
                  {text}
                  <LineSpacer />
                </>
              )}
            </Box>
          ))}
      </Text>
    </Bleed>
  </Box>
);

export { Breadcrumbs, type BreadCrumbItem };
