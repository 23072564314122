// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'min read': () =>
      | 'min read'
      | 'นาทีในการอ่าน'
      | 'menit membaca'
      | '[m̂ìììกี้ řẽẽẽăăăƌ]';
    'min listen': () =>
      | 'min listen'
      | 'นาทีในการฟัง'
      | 'menit mendengar'
      | '[m̂ìììกี้ ƚìììšṯẽẽẽกี้]';
    'min watch': () =>
      | 'min watch'
      | 'นาทีในการรับชม'
      | 'menit melihat'
      | '[m̂ìììกี้ ŵăăăṯçḩ]';
  }
>({
  en: createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  th: createLanguage({
    'min read': 'นาทีในการอ่าน',
    'min listen': 'นาทีในการฟัง',
    'min watch': 'นาทีในการรับชม',
  }),
  id: createLanguage({
    'min read': 'menit membaca',
    'min listen': 'menit mendengar',
    'min watch': 'menit melihat',
  }),
  zh: createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-AU': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-HK': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-ID': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-MY': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-NZ': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-PH': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-SG': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-TH': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'th-TH': createLanguage({
    'min read': 'นาทีในการอ่าน',
    'min listen': 'นาทีในการฟัง',
    'min watch': 'นาทีในการรับชม',
  }),
  'id-ID': createLanguage({
    'min read': 'menit membaca',
    'min listen': 'menit mendengar',
    'min watch': 'menit melihat',
  }),
  'zh-HK': createLanguage({
    'min read': 'min read',
    'min listen': 'min listen',
    'min watch': 'min watch',
  }),
  'en-PSEUDO': createLanguage({
    'min read': '[m̂ìììกี้ řẽẽẽăăăƌ]',
    'min listen': '[m̂ìììกี้ ƚìììšṯẽẽẽกี้]',
    'min watch': '[m̂ìììกี้ ŵăăăṯçḩ]',
  }),
});

export default translations;
