// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    "We're working on it.": () =>
      | "We're working on it."
      | "[Ŵẽẽẽ'řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ìììṯ.]";
    "We can't show this data right now. We're doing our best to fix this.": () =>
      | "We can't show this data right now. We're doing our best to fix this."
      | "[Ŵẽẽẽ çăăăกี้'ṯ šḩöööŵ ṯḩìììš ƌăăăṯăăă řìììģḩṯ กี้öööŵ. Ŵẽẽẽ'řẽẽẽ ƌöööìììกี้ģ öööǚǚǚř ßẽẽẽšṯ ṯööö ƒìììꭕ ṯḩìììš.]";
    'Try refreshing the page or check back later.': () =>
      | 'Try refreshing the page or check back later.'
      | '[Ṯřýýý řẽẽẽƒřẽẽẽšḩìììกี้ģ ṯḩẽẽẽ ƥăăăģẽẽẽ öööř çḩẽẽẽçķ ßăăăçķ ƚăăăṯẽẽẽř.]';
  }
>({
  en: createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  th: createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  id: createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  zh: createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-AU': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-HK': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-ID': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-MY': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-NZ': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-PH': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-SG': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-TH': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'th-TH': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'id-ID': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'zh-HK': createLanguage({
    "We're working on it.": "We're working on it.",
    "We can't show this data right now. We're doing our best to fix this.":
      "We can't show this data right now. We're doing our best to fix this.",
    'Try refreshing the page or check back later.':
      'Try refreshing the page or check back later.',
  }),
  'en-PSEUDO': createLanguage({
    "We're working on it.": "[Ŵẽẽẽ'řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ìììṯ.]",
    "We can't show this data right now. We're doing our best to fix this.":
      "[Ŵẽẽẽ çăăăกี้'ṯ šḩöööŵ ṯḩìììš ƌăăăṯăăă řìììģḩṯ กี้öööŵ. Ŵẽẽẽ'řẽẽẽ ƌöööìììกี้ģ öööǚǚǚř ßẽẽẽšṯ ṯööö ƒìììꭕ ṯḩìììš.]",
    'Try refreshing the page or check back later.':
      '[Ṯřýýý řẽẽẽƒřẽẽẽšḩìììกี้ģ ṯḩẽẽẽ ƥăăăģẽẽẽ öööř çḩẽẽẽçķ ßăăăçķ ƚăăăṯẽẽẽř.]',
  }),
});

export default translations;
