export const displaySVG = (svgFile: string) =>
  `url('data:image/svg+xml;utf8,${svgFile.replace(/\#/g, '%23')}')`;

export const isProduction = () => process.env.NODE_ENV === 'production';

export const logHandler = (data: any, secondData?: any) => {
  if (!isProduction()) {
    console.log(data, secondData ? secondData : ''); // eslint-disable-line no-console
  }
};
