import { BoxShadows, type CardJobAdFragment } from '@seek/cmsu-cms-connect';
import { Box, Stack, Inline, Text } from 'braid-design-system';
import { Heading } from '../Heading/Heading';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { Paragraph } from './Paragraph';

import { featureCardStyle, featureBar, imageStyle } from './styles.css';
import { getBoxShadowProps } from '../PageComponent/helper';
import { vars } from 'braid-design-system/css';

type Props = Pick<
  CardJobAdFragment,
  'badge' | 'paragraph' | 'actionGroup' | 'border'
> & {
  language?: string;
  heading?: React.ComponentProps<typeof Heading> | null;
  subHeading?: string | null;
  image?: {
    url: string;
    alt?: string | null;
  } | null;
};

// Rough calculation of a minHeight value for the subHeading element
// based on how many characters are in the subHeading string
const calculateSubheadingMinHeight = (subHeadingLength: number) => {
  if (subHeadingLength < 50) {
    return vars.space.xlarge;
  }
  if (subHeadingLength < 100) {
    return vars.space.xxlarge;
  }
  if (subHeadingLength < 150) {
    return vars.space.xxxlarge;
  }

  return `calc(${vars.space.xxxlarge} + ${vars.space.medium})`;
};

const JobAd = ({
  heading,
  subHeading,
  paragraph,
  actionGroup,
  image,
  badge,
  border,
}: Props) => (
  <Box
    borderRadius="large"
    background="surface"
    padding="gutter"
    paddingY="large"
    height="full"
    width="full"
    className={`${badge?.text && featureCardStyle}`}
    position="relative"
    data={{ testid: 'JobAdTestId' }}
    {...getBoxShadowProps(border, BoxShadows.BorderNeutralLight)}
  >
    {badge?.text && (
      <Box background={badge?.tone || 'promote'} className={featureBar}>
        <Inline space="xsmall" alignY="center" align="center">
          <Text>{badge.text}</Text>
        </Inline>
      </Box>
    )}

    <Box style={{ height: '100%' }}>
      <Stack space={'large'}>
        {image ? (
          <Box
            width="full"
            className={imageStyle}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          />
        ) : null}
        {heading && (
          <Box textAlign="left">
            <Heading {...heading} />
          </Box>
        )}
        {subHeading ? (
          <Box
            style={{
              minHeight: calculateSubheadingMinHeight(subHeading.length),
            }}
          >
            <Text size="large" tone="secondary" align="left">
              {subHeading}
            </Text>
          </Box>
        ) : null}

        {actionGroup && (
          <Box textAlign={'center'}>
            <ActionGroup {...actionGroup} size="full" />
          </Box>
        )}
        {paragraph && (
          <Paragraph content={paragraph.Paragraph_text?.raw} {...paragraph} />
        )}
      </Stack>
    </Box>
  </Box>
);

export { JobAd };
