import type { AccordionGroupFragment } from '@seek/cmsu-cms-connect';
import { Accordion as BraidAccordianGroup, Stack } from 'braid-design-system';

import React from 'react';
import { Accordion } from './Accordion';

type Props = Pick<AccordionGroupFragment, 'accordions'>;

const AccordionGroup = ({ accordions }: Props) => (
  <Stack space="gutter">
    <BraidAccordianGroup>
      {accordions.map(({ content, label, id }) => (
        <Accordion
          label={label}
          content={content}
          key={id}
          uniqueTrackingId="accordion-label"
          trackingAttributes={{
            elementText: label,
            elementAction: 'expand',
          }}
        />
      ))}
    </BraidAccordianGroup>
  </Stack>
);

export { AccordionGroup };
