import { CTextSize, type BannerInlineFragment } from '@seek/cmsu-cms-connect';
import {
  Badge,
  Box,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
} from 'braid-design-system';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Asset, AssetType } from '../../Asset/Asset';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';
import type { DatalabSurvey } from '../../Talent-Attraction-Lab/datalabHelper';
import { BannerDivider } from '../BannerDivider';
import { InlineBannerCustomComponent } from './InlineBannerCustomComponent';

type Props = {
  datalabSurvey?: DatalabSurvey; // To set as DatalabSurvey Type
  bannerData?: Pick<
    BannerInlineFragment,
    | 'reverse'
    | 'image'
    | 'heading'
    | 'paragraph'
    | 'actionGroup'
    | 'inlineDivider'
    | 'badge'
    | 'imageHiddenBelow'
    | 'imageBorderRadius'
    | 'customComponentRenderer' // TODO: This value shouldnt be empty, we should render the custom component based on availability of this value
  >;
};

export const InlineBanner = ({ bannerData, datalabSurvey }: Props) => {
  /**
   * There are some styling issue when we directly use the <Hidden> component
   * if we wrap the image column with Hidden, it will cause the image size shrink
   * if we wrap the Asset component with Hidden in the Column, it will cause there is
   * unused Column in mobile view and content not vertical center.
   */
  const responsiveValue = useResponsiveValue();
  const isDeskTop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: true,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: true,
    wide: true,
  });
  const boxPaddingX =
    bannerData?.imageHiddenBelow === 'tablet' ? 'none' : 'medium';
  const hiddenBelow =
    bannerData?.imageHiddenBelow === 'none'
      ? 'mobile'
      : bannerData?.imageHiddenBelow ?? 'mobile';
  const shouldRenderImage = (hidden: string): boolean => {
    let shouldRender = false;
    switch (hidden) {
      case 'mobile':
        shouldRender = true;
        break;
      case 'tablet':
        shouldRender = (isDeskTop || isTablet) ?? false;
        break;
      case 'desktop':
        shouldRender = isDeskTop || false;
        break;
      default:
        break;
    }
    return shouldRender;
  };
  return (
    <Box data={{ testid: 'inlineBannerTestId' }} paddingX={boxPaddingX}>
      <Columns
        space={{ mobile: 'gutter', tablet: 'xlarge' }}
        alignY="center"
        collapseBelow="tablet"
        reverse={bannerData?.reverse || false}
      >
        {shouldRenderImage(hiddenBelow) ? (
          <Column width="1/2">
            {bannerData?.image && (
              <Box
                borderRadius={bannerData?.imageBorderRadius || 'none'}
                style={{ overflow: 'hidden' }}
              >
                <Asset
                  assetType={AssetType.INLINE_BANNER}
                  {...bannerData?.image}
                />
              </Box>
            )}
          </Column>
        ) : (
          <></>
        )}
        <Column>
          <Stack space="gutter">
            {bannerData?.badge && (
              <Badge {...bannerData?.badge} weight="strong">
                {bannerData?.badge.text}
              </Badge>
            )}
            {bannerData?.heading && <Heading {...bannerData?.heading} />}
            {bannerData?.paragraph && (
              <Paragraph
                content={bannerData?.paragraph.Paragraph_text?.raw}
                {...bannerData?.paragraph}
                size={bannerData?.paragraph.size || CTextSize.Large}
              />
            )}
            {bannerData?.actionGroup && (
              <Inline space="none">
                <ActionGroup
                  {...bannerData?.actionGroup}
                  size={bannerData?.actionGroup.actionGroupSize}
                />
              </Inline>
            )}
            {bannerData?.inlineDivider && (
              <BannerDivider
                image={bannerData?.inlineDivider.image}
                actions={bannerData?.inlineDivider}
              />
            )}
            {bannerData?.customComponentRenderer && (
              <InlineBannerCustomComponent
                componentType={bannerData?.customComponentRenderer}
                datalabSurvey={datalabSurvey}
              />
            )}
          </Stack>
        </Column>
      </Columns>
    </Box>
  );
};
