const HYPGRAPH_MEDIA_REGX = /graphassets.com/;

const MAX = 1280;

export const isHygraphMediaAsset = (imageUrl: string) =>
  HYPGRAPH_MEDIA_REGX.test(imageUrl);

export const resizeImageMaxSizeFromHygraph = (imageUrl: string) => {
  if (!isHygraphMediaAsset(imageUrl)) {
    return imageUrl;
  }

  const transform = `resize=fit:max,width:${MAX},height:${MAX}`;
  return applyImageTransformationToUrl(imageUrl, transform);
};

export const applyImageTransformationToUrl = (
  imageUrl: string,
  transform: string,
) => {
  if (imageUrl.indexOf('resize=') > -1) return imageUrl;
  const imageSource = imageUrl.substring(0, imageUrl.lastIndexOf('/'));
  const imageId = imageUrl.substring(
    imageUrl.lastIndexOf('/') + 1,
    imageUrl.length,
  );
  return `${imageSource}/${transform}/${imageId}`;
};
