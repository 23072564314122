import type { StepBlockFragment } from '@seek/cmsu-cms-connect';
import { Box, Stack } from 'braid-design-system';

import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';

type StepBlockGroupGeneratedProps = {
  index: number;
};
type StepBlockProps = Pick<StepBlockFragment, 'heading' | 'paragraph'> &
  StepBlockGroupGeneratedProps;

export const StepBlock = ({ index, heading, paragraph }: StepBlockProps) => (
  <Box
    height="full"
    padding="large"
    textAlign="center"
    borderRadius="xlarge"
    background="brandAccentSoft"
    data={{ testid: 'StepBlockTestId' }}
  >
    <Stack space="medium" align="center">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        background="brandAccent"
        style={{ width: 48, height: 48 }}
      >
        <Heading level={3} component="p" text={`${index + 1}`} />
      </Box>

      <Stack space="small">
        {Boolean(heading) && (
          <Heading {...heading} text={heading?.text || ''} />
        )}
        {paragraph?.Paragraph_text && (
          <Paragraph
            content={paragraph.Paragraph_text.raw}
            align={paragraph.align}
            tone={paragraph.tone}
            size={paragraph.size}
          />
        )}
      </Stack>
    </Stack>
  </Box>
);
