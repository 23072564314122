import React, { type CSSProperties } from 'react';
import { useTrackLink } from '@seek/cmsu-analytics';
import type { ImageLinkFragment } from '@seek/cmsu-cms-connect';
import { TextLink } from 'braid-design-system';
import { toUrl } from '../../utils/toUrl';
import { Asset, AssetType } from '../Asset/Asset';

type Props = Pick<ImageLinkFragment, 'image' | 'link'> & {
  style?: CSSProperties;
  uniqueTrackingId?: string;
  trackingAttributes?: Record<string, string>;
};

export const ImageLink = ({
  image,
  link,
  style,
  uniqueTrackingId,
  trackingAttributes,
}: Props) => {
  const onClickLink = useTrackLink(uniqueTrackingId || image.id, {
    ...trackingAttributes,
    elementDesign: 'image',
  });
  return (
    <TextLink
      onClick={() => (onClickLink ? onClickLink() : undefined)}
      href={toUrl(link?.to)}
      target={link?.openLinkInNewTab ? '_blank' : undefined}
    >
      <Asset
        assetType={AssetType.DEFAULT_IMAGE}
        url={image.url}
        alt={image?.alt}
        style={style}
      />
    </TextLink>
  );
};
