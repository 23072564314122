import React from 'react';
import { Text, TextLink } from 'braid-design-system';
import { IFrame } from '../elements';
import { isText } from '@graphcms/rich-text-types';
import escapeHtml from 'escape-html';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/;

export const getIsVideo = (text: string) => {
  const regex =
    /^(?:https:\/\/youtu\.be\/|https:\/\/www\.youtube\.com\/watch\?v=|https:\/\/players\.brightcove\.net\/\d+\/[\w]+\/index\.html\?videoId=)([^\s]+)$/;
  const match = text.match(regex);
  if (match && match[0] === match.input) return true;
  return false;
};

export const getEmbeddedUrl = (text: string) => {
  const urlObj = new URL(text);
  if (urlObj.hostname === 'youtu.be') {
    return `https://www.youtube-nocookie.com/embed/${urlObj.pathname.slice(1)}`;
  }
  if (
    urlObj.hostname === 'www.youtube.com' ||
    urlObj.hostname === 'youtube.com'
  ) {
    const params = new URLSearchParams(urlObj.search);
    return `https://www.youtube-nocookie.com/embed/${params.get('v')}`;
  }
  // https://players.brightcove.net/6040621522001/qx6UKDb3d_default/index.html?videoId=[VIDEO_ID]
  return text;
};

const convertEmailElement = (text: string) => {
  const refactorParts = convertEmailContent(text);
  const elements = refactorParts.map((part, index) => {
    if (emailRegex.test(part)) {
      // If the part matches an email address, render it as a TextLink
      const email = part.trim(); // Trim the email string
      return (
        <React.Fragment key={index}>
          <TextLink href={escapeHtml(`mailto:${email}`)}>{email}</TextLink>
        </React.Fragment>
      );
    }
    return <React.Fragment key={index}>{part}</React.Fragment>;
  });
  return <Text component="p">{elements}</Text>;
};

export const convertEmailContent = (text: string) => {
  const parts = text.split(/\s+/);
  const refactorParts: string[] = [];
  let nonEmailParts: string[] = [];
  for (const p of parts) {
    if (emailRegex.test(p)) {
      // Join non-email parts accumulated so far and push them
      if (nonEmailParts.length > 0) {
        refactorParts.push(`${nonEmailParts.join(' ')} `);
        nonEmailParts = [];
      }

      // Handle email with trailing special characters
      if (p.endsWith('.') || p.endsWith(',') || p.endsWith('?')) {
        const emailWithoutTrailingChar = p.slice(0, -1); // Remove the last character
        refactorParts.push(emailWithoutTrailingChar);
        // Push the trailing character separated by a space
        refactorParts.push(`${p.slice(-1)} `);
      } else {
        refactorParts.push(`${p} `);
      }
    } else {
      nonEmailParts.push(p);
    }
  }
  if (nonEmailParts.length > 0) {
    refactorParts.push(nonEmailParts.join(' '));
  }

  return refactorParts;
};

export const isContainEmail = (text: string) => {
  let flag = false;
  const parts = text.split(/\s+/);
  for (const p of parts) {
    if (emailRegex.test(p)) {
      flag = true;
      break;
    }
  }
  return flag;
};

// Handling children
// e.g. for embed, we only want the 'embed' child node and not other text node for simplicity
// IF we were to handle other node within 'embed', it will require complex handling as 'embed' is a custom component which can be a complex component
// With custom component (embed), it could have <Text> element or uses elements that could cause Hydration error or Braid error
const handleEmbed = (children: any[]) =>
  children.find((child) => child.props?.node?.type === 'embed')
    ? children.filter((child) => child.props?.node?.type === 'embed')
    : children;

export function Paragraph({ children }: { children: any }) {
  const handledChildren = handleEmbed(children);
  const node = handledChildren[0].props?.node;

  if (isText(node)) {
    const isVideo = getIsVideo(node.text);
    if (isVideo) {
      return <IFrame url={getEmbeddedUrl(node.text)} />;
    }
    return isContainEmail(node.text) ? (
      convertEmailElement(node.text)
    ) : (
      <Text component="p">{handledChildren}</Text>
    );
  }

  // For embed type, we don't want to wrap in a <Text> element as it would cause Braid error when the component contains another <Text> element
  if (node.type === 'embed') {
    return <>{handledChildren}</>;
  }
  return <Text component="p">{handledChildren}</Text>;
}
