import React from 'react';
import type { TextLinkFragment } from '@seek/cmsu-cms-connect';
import { Text, TextLink as BraidTextLink } from 'braid-design-system';
import { toUrl } from '../../utils/toUrl';
import { useTrackLink } from '@seek/cmsu-analytics';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { renderIcon } from '../IconBlockGroup/renderer';

type Props = Pick<
  TextLinkFragment,
  'id' | 'weight' | 'text' | 'link' | 'icon' | 'iconPosition'
> & {
  uniqueTrackingId?: string;
  trackingAttributes?: Record<string, string>;
};

export const TextLink = ({
  id,
  weight,
  text,
  link,
  uniqueTrackingId,
  trackingAttributes,
  icon,
  iconPosition,
}: Props) => {
  const onClickLink = useTrackLink(uniqueTrackingId || id, {
    ...trackingAttributes,
    elementDesign: 'link',
  });
  const { language } = useCMSUContext();

  return (
    <Text>
      <BraidTextLink
        href={toUrl(link?.to, language)}
        weight={weight || 'regular'}
        onClick={() => (onClickLink ? onClickLink() : undefined)}
        icon={(icon && renderIcon(icon)) || undefined}
        iconPosition={iconPosition || undefined}
        target={link?.openLinkInNewTab ? '_blank' : undefined}
      >
        {text}
      </BraidTextLink>
    </Text>
  );
};
