import {
  type BannerAwardFragment,
  BorderRadius,
  CParagraphMarginBottom,
  CTextTone,
  CTextWeight,
  Theme,
} from '@seek/cmsu-cms-connect';
import { Box, ContentBlock, Stack, Column, Columns } from 'braid-design-system';
import { ActionGroup } from '../../ActionGroup/ActionGroup';
import { Heading } from '../../Heading/Heading';
import { Paragraph } from '../../Paragraph/Paragraph';

import SaraTextBackgroundImage from './sara-banner-text-background.png';
import { vars } from 'braid-design-system/css';
import { Video, VideoSrcType } from '../../Video/Video';

type Props = Omit<BannerAwardFragment, '__typename' | 'id'> & {
  theme: Theme;
};

// TODO: Implement something like below to better handle getting theme options
const getThemeTone = (theme: Props['theme']): CTextTone | undefined => {
  switch (theme) {
    case Theme.Sara:
      return CTextTone.BrandAccent;
    case Theme.Star:
      return CTextTone.Neutral;
    default:
      return undefined;
  }
};

const getThemeBorder = (theme: Props['theme']) => {
  switch (theme) {
    case Theme.Sara:
      return 'borderNeutralInverted';
    default:
      return undefined;
  }
};

const getThemeCustomBackgroundProp = (theme: Props['theme']) => {
  switch (theme) {
    case Theme.Sara:
      return 'customLight';
    case Theme.Star:
      return 'customDark';
    default:
      return undefined;
  }
};

const getThemeDefaultBackgroundColor = (theme: Props['theme']) => {
  switch (theme) {
    case Theme.Sara:
      return vars.backgroundColor.brand;
    default:
      return undefined;
  }
};

const getVideoUrl = (video: Props['video']) => {
  if (!video) {
    return undefined;
  }

  if (video.source?.__typename === VideoSrcType.Brightcove) {
    return video.source.videoUrl;
  }

  if (video.source?.__typename === VideoSrcType.YouTube) {
    return `https://www.youtube.com/embed/${video?.source?.videoId}`;
  }
};

export const AwardsBanner = ({
  paragraph,
  heading,
  actionGroup,
  backgroundImage,
  video,
  theme,
}: Props) => {
  const minHeight = '500px';
  const videoUrl = getVideoUrl(video);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box
        padding={{ mobile: 'small', tablet: 'gutter' }}
        position="relative"
        data={{ testid: 'AwardsBannerTestId' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        background={theme === Theme.Sara ? 'brand' : undefined}
        style={{
          minHeight,
          maxWidth: '2000px',
          ...(backgroundImage && {
            backgroundImage: `url(${backgroundImage.url})`,
          }),
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        width="full"
      >
        <Box
          boxShadow={getThemeBorder(theme)}
          width="full"
          height="full"
          padding={{ mobile: 'small', tablet: 'gutter' }}
          style={{
            minHeight: `calc(${minHeight} - ${vars.space.gutter})`,
          }}
          display="flex"
          flexDirection="column"
          justifyContent={{ mobile: 'flexStart', tablet: 'center' }}
          alignItems="center"
        >
          <ContentBlock width="large">
            <Columns
              space="xlarge"
              collapseBelow="tablet"
              alignY={{ mobile: 'top', tablet: 'center' }}
            >
              <Column width="2/5">
                <Box
                  borderRadius="large"
                  background={getThemeCustomBackgroundProp(theme)}
                  padding="large"
                  style={{
                    ...(!video && {
                      backgroundImage:
                        theme === Theme.Sara
                          ? `url(${SaraTextBackgroundImage})`
                          : undefined,
                    }),
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: getThemeDefaultBackgroundColor(theme),
                  }}
                >
                  <Stack space="medium">
                    {heading?.text ? (
                      <Heading
                        {...heading}
                        weight={heading.weight || CTextWeight.Regular}
                      />
                    ) : null}
                    {paragraph && (
                      <Box paddingY="small">
                        <Paragraph
                          content={paragraph.Paragraph_text?.raw}
                          marginBottom={CParagraphMarginBottom.None}
                          align={paragraph.align}
                          tone={paragraph.tone || getThemeTone(theme)}
                          size={paragraph.size}
                        />
                      </Box>
                    )}
                    {actionGroup && <ActionGroup {...actionGroup} />}
                  </Stack>
                </Box>
              </Column>
              <Column width="3/5">
                {videoUrl && (
                  <Box data={{ testid: 'video' }}>
                    <Video
                      size="small"
                      borderRadius={BorderRadius.Xlarge}
                      url={videoUrl}
                    />
                  </Box>
                )}
              </Column>
            </Columns>
          </ContentBlock>
        </Box>
      </Box>
    </Box>
  );
};
