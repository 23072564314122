import type { RichText } from '@seek/cmsu-cms-connect';
export type Maybe<T> = T | null;

const removeFormattingFromText = (text: string): string =>
  text.replace(/\\n|\\r/g, ' ');

const getTextWithoutFormatting = (
  richText?: Partial<RichText> | Maybe<RichText> | null | undefined,
): string | undefined =>
  richText && richText !== null && richText.text
    ? richTextHelper.removeFormattingFromText(richText.text)
    : undefined;

export const richTextHelper = {
  removeFormattingFromText,
  getTextWithoutFormatting,
};
