import { Box } from 'braid-design-system';
import React, { useEffect, useRef, useState } from 'react';

const mapTextToUrl: { [key: string]: string } = {
  'About the Research': '/market-insights/loa-about-the-research',
  'Tentang Riset Laws of Attraction':
    '/id/market-insights/loa-about-the-research',
  เกี่ยวกับแบบสำรวจ: '/th/market-insights/loa-about-the-research',
};

export const LOAIFrame = ({ url }: { url: string }) => {
  const [height, setHeight] = useState('0px');
  const [iframeSrcUrl, setIframeSrcUrl] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (window) {
      // make sure the source url string ended with .html
      const modifiedUrl = url.substring(0, url.indexOf('html') + 4);

      // page param query starts with ?page=/explore? and the "?page=" is omitted to construct the iframe url
      const queryParam = window.location.search.substring(6);

      setIframeSrcUrl(`${modifiedUrl}#${queryParam}`);
    }
  }, [url]);

  const adjustIframeHeight = () => {
    if (iframeRef && iframeRef.current?.contentWindow) {
      setHeight(
        () =>
          `${iframeRef.current!.contentWindow!.document.body.scrollHeight}px`,
      );
    }
  };

  const updateUrlInAddressBar = () => {
    if (iframeRef && iframeRef.current?.contentWindow) {
      if (iframeRef.current.contentWindow.location.hash.substring(2) !== '') {
        history.pushState(
          '',
          '',
          `${
            window.location.pathname
          }?page=${iframeRef.current.contentWindow.location.hash.substring(1)}`,
        );
      }
    }
  };

  const scrollToTop = () => {
    if (iframeRef && iframeRef.current) {
      const offset = window.innerWidth <= 992 ? 20 : 150;
      const scrollAmount =
        iframeRef.current?.getBoundingClientRect().top - offset;
      window.scrollTo({ top: scrollAmount, behavior: 'smooth' });
    }
  };

  const onLoad = () => {
    if (iframeRef.current) {
      // adjust iframe height on first load
      // set timeout to 1000 milliseconds as it takes some time to render content in iframe window before getting the correct height
      setTimeout(() => {
        const initialHeightOffset = 20;
        setHeight(
          () =>
            `${
              iframeRef.current!.contentWindow!.document.body.clientHeight -
              initialHeightOffset
            }px`,
        );
      }, 1000);

      iframeRef.current?.contentWindow?.document.addEventListener(
        'click',
        (e) => {
          // scroll to top if targeted button clicked has className 'dl-toTop'
          const target = e.target as HTMLElement;
          if (target.classList?.contains('dl-toTop')) {
            scrollToTop();
            return;
          }

          if (
            target.textContent === 'How to Use' ||
            target.textContent === 'Pedoman Penggunaan'
          ) {
            e.preventDefault();

            // href link contains "id=2" will show "how to use" section
            // href that doesn't contains will be set manually to contain "id=2"
            if (target.getAttribute('href')?.indexOf('id=2') === -1) {
              iframeRef.current?.setAttribute(
                'src',
                `${iframeSrcUrl?.substring(
                  0,
                  iframeSrcUrl.indexOf('html#') + 5,
                )}/?id=2`,
              );
            }
          }

          const targetUrl =
            target.textContent && mapTextToUrl[target.textContent];

          if (targetUrl) {
            e.preventDefault();
            window.open(targetUrl, '_blank');
          }

          setTimeout(() => {
            updateUrlInAddressBar();
          }, 200);

          setTimeout(() => {
            adjustIframeHeight();
          }, 1000);
        },
      );

      // stick iframe window navbar to the top
      window.addEventListener('scroll', () => {
        const targetDomain = url.substring(0, url.indexOf('.com') + 4);
        iframeRef.current?.contentWindow?.postMessage(
          {
            top: iframeRef.current.getBoundingClientRect().top,
            bottom: iframeRef.current.getBoundingClientRect().bottom,
          },
          targetDomain,
        );
      });
    }
  };

  return (
    <Box data={{ testid: 'LOAIFrameTestId' }}>
      {iframeSrcUrl && (
        <iframe
          scrolling="no"
          style={{ border: 'none' }}
          width="100%"
          onLoad={onLoad}
          height={height}
          ref={iframeRef}
          src={iframeSrcUrl}
          title="LOA-IFRAME"
        />
      )}
    </Box>
  );
};
