import React from 'react';

export const BgTop = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 50L-4.37114e-06 4.37114e-06L50 0C50 27.6143 27.6143 50 0 50Z"
      fill="#072462"
    />
  </svg>
);
