import {
  isEmail,
  maxLength,
  minLength,
  minValue,
  maxValue,
} from '@seek/validators-js';
import type { ValidatorOptions } from './types';

type FormValidator =
  | {
      getErrorMessage: () => Promise<string>;
      test: ({ value }: { value: string }) => boolean;
    }
  | {
      getErrorMessage: () => Promise<string>;
      test: ({ value }: { value: number }) => boolean;
    }
  | {
      getErrorMessage: () => Promise<string>;
      test: ({ value }: { value: object }) => boolean;
    };

export const transformValueToValidator = (
  validator: ValidatorOptions,
  t: any,
): FormValidator => {
  switch (validator.__typename) {
    case 'MinLengthValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Min length', { value: validator.value }),
          ),
        test: ({ value }: { value: string }) =>
          minLength.test({
            value,
            validatorProps: { min: validator.value },
            formValues: undefined,
          }),
      };
    case 'MaxLengthValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Max length', { value: validator.value }),
          ),
        test: ({ value }: { value: string }) =>
          maxLength.test({
            value,
            validatorProps: { max: validator.value },
            formValues: undefined,
          }),
      };
    case 'MinNumberValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Min number', { value: validator.value }),
          ),
        test: ({ value }: { value: number }) =>
          minValue.test({
            value,
            validatorProps: {
              min: validator.value,
              minValueFieldLabel: '',
            },
            formValues: undefined,
          }),
      };
    case 'MaxNumberValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Max number', { value: validator.value }),
          ),
        test: ({ value }: { value: number }) =>
          maxValue.test({
            value,
            validatorProps: {
              max: validator.value,
              maxValueFieldLabel: '',
            },
            formValues: undefined,
          }),
      };
    case 'EmailValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(validator.errorMessage || t('Invalid email')),
        test: ({ value }: { value: string }) =>
          isEmail.test({
            value,
            formValues: undefined,
            validatorProps: undefined,
          }),
      };
    case 'MinCheckedItemValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Min checked item', { value: validator.value }),
          ),
        test: ({ value: obj }: { value: object }) => {
          const checkedCount = Object.values(obj).filter(
            (value) => value === true,
          ).length;
          return checkedCount >= validator.value;
        },
      };
    case 'MaxCheckedItemValidator':
      return {
        getErrorMessage: () =>
          Promise.resolve(
            validator.errorMessage ||
              t('Max checked item', { value: validator.value }),
          ),
        test: ({ value: obj }: { value: object }) => {
          const checkedCount = Object.values(obj).filter(
            (value) => value === true,
          ).length;
          return checkedCount <= validator.value;
        },
      };
  }
};
