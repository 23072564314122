import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  getPageWithEntities,
  Locale,
  Stage,
  type GetPagesWithEntitiesQueryVariables,
  type PageWhereInput,
} from '@seek/cmsu-cms-connect';
import { json, type LoaderFunction } from 'react-router-dom';

import { internalError, notFound } from './responses';
import type { RouteProps } from '../Routes';
import { getRouteInfo } from './loadersHelper';

type loadPageDataProps = {
  client: ApolloClient<NormalizedCacheObject>;
  variables: GetPagesWithEntitiesQueryVariables;
  slug: string | undefined;
};

const loadPageData = async ({ client, variables, slug }: loadPageDataProps) => {
  let payload;
  try {
    // Query hygraph
    payload = await getPageWithEntities({
      client,
      variables,
      // formatBlocksForRendering,
    });
  } catch (e: any) {
    if (e.code === '404') {
      throw notFound(slug);
    } else {
      throw internalError();
    }
  }
  // Load External Data (e.g. WebLinks)
  // await loadExternalData({ pageData: payload, pageNumber });
  // await loadLinkParentSectionData({ client, pageData: payload });

  return json(payload);
};

export const getHygraphLocales = (hygraphLocale: Locale): Locale[] => [
  hygraphLocale,
  Locale.Default,
];

const getPageQueryVariables = (
  where: PageWhereInput,
  isDraft: boolean,
  hygraphLocale: Locale,
) => {
  const stage = isDraft ? Stage.Draft : Stage.Published;
  const locale = getHygraphLocales(hygraphLocale);
  return {
    where,
    locale,
    stage,
  };
};

export const pageLoader =
  ({ config, client }: RouteProps): LoaderFunction =>
  async () => {
    const {
      site: hygraphSite,
      hygraphLocale,
      isDraft,
      routeArray,
      country,
      queryParams,
    } = config;

    let id;
    if (isDraft) {
      id = queryParams.get('draft');
    }

    const { slug } = getRouteInfo(routeArray);
    let localisedSlug;

    if (slug === 'sara') {
      localisedSlug = `sara-home${country === 'nz' ? '-nz' : ''}`;
    } else {
      localisedSlug = `sara-${slug}${country === 'nz' ? '-nz' : ''}`;
    }

    const where = {
      ...{ id },
      slug: localisedSlug,
      sites_some: { name: hygraphSite },
    };

    const variables = getPageQueryVariables(where, isDraft, hygraphLocale);

    return await loadPageData({
      client,
      variables,
      slug: localisedSlug,
    });
  };
