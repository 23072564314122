import type { GetSurveyQuery } from '../../../../../apps/unified-apac/src/gql/tal/generated';
import {
  datalabHelper,
  type CategoryDriverOption,
  type DatalabDriverData as DatalabDriverData,
  type DriverCategory,
  type DriverSubCategory,
  type SecondaryDriverOption,
  type SubCategoryDriverOption,
  type SurveyDrilldownMaxDiffBreakdownResultType,
  type SurveyDrilldownMaxDiffBreakdownType,
  type SurveyDrilldownType,
} from './datalabHelper';

export interface DriverCategoryDriverOptionUngrouped {
  name: DriverCategory['name'];
  driverOption: CategoryDriverOption;
}

export interface DriverCategorySubcategoryUngrouped {
  name: DriverCategory['name'];
  subCategories: DriverCategory['subCategories'];
}

const getMatchingSubCategoriesFromCategoryBy = (
  categories: DriverCategorySubcategoryUngrouped[],
  categoryName: string,
) =>
  categories
    .filter((item) => item.name === categoryName)
    .flatMap((item) => item!.subCategories);

const groupAndSortCategoryDriverOptions = (
  categories: DriverCategoryDriverOptionUngrouped[],
  categoryName: string,
) =>
  categories
    .filter((item) => item.name === categoryName)
    .map((item) => item.driverOption)
    .sort((a, b) => (a.name < b.name ? -1 : 1));

// No sorting needed, sorting is done from Back-End
const groupSubCategories = (subCategories: DriverSubCategory[]) => {
  if (!subCategories || subCategories.length === 0) return [];
  const uniqueSubCategories = Array.from(
    new Set(
      subCategories
        ?.filter((item) => item && item.name)
        .map((category) => category && category.name),
    ),
  );

  return uniqueSubCategories.map(
    (subCategory) =>
      ({
        name: subCategory,
        driverOptions: subCategories
          .filter((item) => item.name === subCategory && item.driverOptions)
          .flatMap((item) => item.driverOptions)
          .sort((a, b) => (a.name < b.name ? -1 : 1)),
      } as DriverSubCategory),
  );
};

// No Sorting, will follow sorting from Back-End
const mapToSubCategoryDriverOption = (
  drilldownDriverName: string,
  results: SurveyDrilldownMaxDiffBreakdownResultType,
): SubCategoryDriverOption | undefined => {
  if (!results || results.__typename !== 'BreakdownNormalResults')
    return undefined;
  const mappedResults: { name: string; value: number }[] = Object.keys(results)
    .filter((result) => result !== '__typename')
    .map((result) => ({
      name: result,
      value: (results as any)[result],
    }));

  const total = mappedResults
    .map((result) => result.value)
    .reduce((a, b) => a + b);

  return {
    name: drilldownDriverName,
    total,
    results: mappedResults.map((result) => ({
      ...result,
      percentage: (result.value / total) * 100,
    })),
  };
};

const mapToSubCategoriesUngroupedForDriverOption = (
  breakdowns: SurveyDrilldownMaxDiffBreakdownType[],
  drilldownName: string,
): DriverSubCategory[] =>
  breakdowns
    ?.filter((breakdown) => breakdown && breakdown.results)
    ?.flatMap((breakdown) => {
      const driverOptions: SubCategoryDriverOption | undefined =
        datalabMapper.mapToSubCategoryDriverOption(
          drilldownName,
          breakdown.results,
        );
      return {
        name: breakdown.name,
        driverOptions: [driverOptions],
      } as DriverSubCategory;
    });

const mapToDriverCategories = (drilldowns: SurveyDrilldownType[]) => {
  const categories = drilldowns
    .flatMap((drilldown) =>
      drilldown.maxDiffs?.flatMap((maxDiff) => ({
        name: maxDiff.name,
        driverOption: {
          name: drilldown.drilldownName,
          totalRespondents: drilldown.respondents,
          value: maxDiff.result,
          percentage: (maxDiff.result / drilldown.respondents) * 100,
        } as CategoryDriverOption,
        subCategories: datalabMapper.mapToSubCategoriesUngroupedForDriverOption(
          maxDiff.breakdowns,
          drilldown.drilldownName,
        ),
      })),
    )
    .filter((item) => item !== undefined);

  const uniqueCategories = Array.from(
    new Set(categories.map((category) => category?.name)),
  );

  return uniqueCategories.map((category) => {
    const groupedDriverOptions =
      datalabMapper.groupAndSortCategoryDriverOptions(categories, category);
    const subCategories = datalabMapper.getMatchingSubCategoriesFromCategoryBy(
      categories,
      category,
    );

    return {
      name: category,
      driverOptions: groupedDriverOptions,
      subCategories: datalabMapper.groupSubCategories(subCategories),
    };
  });
};

// ToDo: Add Test Coverage
const mapToDatalabDriverData = (
  surveyResult: GetSurveyQuery,
): DatalabDriverData => ({
  driverOptions: surveyResult.survey.map((survey) => ({
    name: survey.driverName,
    categories: datalabMapper.mapToDriverCategories(survey.drilldowns),
    secondaryDriverOptions: survey.drilldowns
      .map(
        (drilldown) =>
          ({
            name: drilldown.drilldownName,
            hasLackOfSampleSizeData:
              datalabHelper.isSurveyResultLackOfSampleDataSize(drilldown),
            hasMissingData:
              datalabHelper.isSurveyResultMissingSecondaryDriverOptionDiffData(
                drilldown,
              ),
          } as SecondaryDriverOption),
      )
      .sort((a, b) => (a.name < b.name ? -1 : 1)),
  })),
});

export const datalabMapper = {
  getMatchingSubCategoriesFromCategoryBy,
  groupAndSortCategoryDriverOptions,
  groupSubCategories,
  mapToSubCategoryDriverOption,
  mapToSubCategoriesUngroupedForDriverOption,
  mapToDriverCategories,
  mapToDatalabDriverData,
};
