// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import type { FormatXMLElementFn } from '@vocab/core';
import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'First Name': () =>
      | 'First name'
      | 'ชื่อ'
      | 'Nama Depan'
      | '[Ƒìììřšṯ กี้ăăăm̂ẽẽẽ]';
    'Last Name': () =>
      | 'Last name'
      | 'นามสกุล'
      | 'Nama Belakang'
      | '[Łăăăšṯ กี้ăăăm̂ẽẽẽ]';
    'Company Email': () =>
      | 'Company email'
      | 'อีเมลบริษัท'
      | 'Alamat Email'
      | '[Çööm̂ƥăăกี้ýý ẽẽm̂ăăììƚ]';
    'Phone Number': () =>
      | 'Company/mobile number'
      | 'เบอร์โทรบริษัท/เบอร์มือถือ'
      | 'Nomor Telepon Perusahaan / Nomor HP'
      | '[Çöööm̂ƥăăăกี้ýýý/m̂ööößìììƚẽẽẽ กี้ǚǚǚm̂ßẽẽẽř]';
    'Company Name': () =>
      | 'Registered company name'
      | 'ชื่อบริษัทที่จดทะเบียน'
      | 'Nama Perusahaan Terdaftar'
      | '[Řẽẽẽģìììšṯẽẽẽřẽẽẽƌ çöööm̂ƥăăăกี้ýýý กี้ăăăm̂ẽẽẽ]';
    'Job Title': () =>
      | 'Job title/designation'
      | 'ตำแหน่ง'
      | 'Posisi Pekerjaan'
      | '[Ĵöööß ṯìììṯƚẽẽẽ/ƌẽẽẽšìììģกี้ăăăṯìììöööกี้]';
    Industry: () =>
      | 'My primary industry is'
      | 'ประเภทธุรกิจ'
      | 'Bidang usaha utama saya adalah'
      | '[Ṃýýý ƥřìììm̂ăăăřýýý ìììกี้ƌǚǚǚšṯřýýý ìììš]';
    'User Identity': () =>
      | 'Are you an employer or a job seeker?'
      | 'คุณเป็นผู้ประกอบการหรือผู้หางาน?'
      | 'Apakah Anda mewakili perusahaan atau seorang pencari kerja?'
      | '[Ậřẽẽ ýýööǚǚ ăăกี้ ẽẽm̂ƥƚööýýẽẽř ööř ăă ĵööß šẽẽẽẽķẽẽř?]';
    'Hiring need': () =>
      | 'I have hiring needs in the next 3 months'
      | 'บริษัทของคุณมีความต้องการจ้างงานใน 3 เดือนข้างหน้าหรือไม่'
      | 'Saya perlu melakukan rekrutmen dalam 3 bulan ke depan'
      | '[Ï ḩăăăṽẽẽẽ ḩìììřìììกี้ģ กี้ẽẽẽẽẽẽƌš ìììกี้ ṯḩẽẽẽ กี้ẽẽẽꭕṯ 3 m̂öööกี้ṯḩš]';
    'Marketing required': () =>
      | 'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)'
      | 'ฉันต้องการรับข้อมูลข่าวสาร โปรโมชัน กิจกรรมทางการตลาด (ข้อมูลที่เกี่ยวข้องกับการสรรหาบุคลากร ข้อเสนอแนะ ข้อมูลเชิงลึกเกี่ยวกับตลาดงาน และอื่น ๆ )'
      | 'Saya ingin menerima promosi & komunikasi pemasaran (berita, tips rekrutmen, wawasan pasar)'
      | '[Ï ŵöööǚǚǚƚƌ ƚìììķẽẽẽ ṯööö řẽẽẽçẽẽẽìììṽẽẽẽ ƥřöööm̂öööṯìììöööกี้ & m̂ăăăřķẽẽẽṯìììกี้ģ çöööm̂m̂ǚǚǚกี้ìììçăăăṯìììöööกี้š (กี้ẽẽẽŵš, ḩìììřìììกี้ģ ăăăƌṽìììšẽẽẽ, m̂ăăăřķẽẽẽṯ ìììกี้šìììģḩṯš, ẽẽẽṯç)]';
    'Organization advertise': (values: { site: string }) => string;
    Employer: () =>
      | 'Employer'
      | 'ผู้ประกอบการ'
      | 'Perusahaan'
      | '[Ëm̂ƥƚöööýýýẽẽẽř]';
    'Job Seeker': () =>
      | 'Job Seeker'
      | 'ผู้หางาน'
      | 'Pencari kerja'
      | '[Ĵööß Šẽẽẽẽķẽẽř]';
    Yes: () => 'Yes' | 'ต้องการ' | 'lya' | '[Ýẽẽẽš]';
    No: () => 'No' | 'ไม่ต้องการ' | 'Tidak' | '[Ñöö]';
    'Not sure': () =>
      | 'I’m not sure'
      | 'ไม่แน่ใจ'
      | 'Saya tidak yakin'
      | '[Ï’m̂ กี้öööṯ šǚǚǚřẽẽẽ]';
    'Industry placeholder': () =>
      | 'Please select'
      | 'โปรดเลือก'
      | 'Silahkan pilih'
      | '[Ƥƚẽẽẽăăăšẽẽẽ šẽẽẽƚẽẽẽçṯ]';
    Submit: () => 'Submit' | 'ส่ง' | 'Kirim' | '[Šǚǚǚßm̂ìììṯ]';
    'Something Wrong': () =>
      | 'Something went wrong, please try again.'
      | 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.'
      | 'Terjadi kesalahan, harap coba lagi.'
      | '[Šöööm̂ẽẽẽṯḩìììกี้ģ ŵẽẽẽกี้ṯ ŵřöööกี้ģ, ƥƚẽẽẽăăăšẽẽẽ ṯřýýý ăăăģăăăìììกี้.]';
    'Submit Download': () =>
      | 'Submit & Download'
      | 'ส่ง & ดาวน์โหลด'
      | 'Kirim & Unduh'
      | '[Šǚǚǚßm̂ìììṯ & Ḋöööŵกี้ƚöööăăăƌ]';
    Confirmation: () =>
      | 'Thank you for your submission'
      | 'ขอบคุณสําหรับการส่งข้อมูล'
      | 'Terima kasih'
      | '[Ṯḩăăกี้ķ ýýööǚǚ ƒööř ýýööǚǚř šǚǚßm̂ììššììööกี้]';
    'Download Report': () =>
      | 'Download Report'
      | 'ดาวน์โหลดรายงาน'
      | 'Unduh Laporan'
      | '[Ḋöööŵกี้ƚöööăăăƌ Řẽẽẽƥöööřṯ]';
    Accounting: () =>
      | 'Accounting / Audit / Tax Services'
      | 'Akunting / Audit / Jasa Perpajakan'
      | '[Ậççöööǚǚǚกี้ṯìììกี้ģ / Ậǚǚǚƌìììṯ / Ṯăăăꭕ Šẽẽẽřṽìììçẽẽẽš]';
    Advertising: () =>
      | 'Advertising / Marketing / Promotion / PR'
      | 'Periklanan / Pemasaran / Promosi / Humas'
      | '[Ậƌṽẽẽẽřṯìììšìììกี้ģ / Ṃăăăřķẽẽẽṯìììกี้ģ / Ƥřöööm̂öööṯìììöööกี้ / ƤŘ]';
    Automobile: () =>
      | 'Automobile / Automotive Ancillary / Vehicle'
      | 'Otomotif / Suku Cadang / Kendaraan'
      | '[Ậǚǚǚṯöööm̂ööößìììƚẽẽẽ / Ậǚǚǚṯöööm̂öööṯìììṽẽẽẽ Ậกี้çìììƚƚăăăřýýý / Ṽẽẽẽḩìììçƚẽẽẽ]';
    Banking: () =>
      | 'Banking / Financial Services'
      | 'Jasa Keuangan'
      | '[ẞăăăกี้ķìììกี้ģ / Ƒìììกี้ăăăกี้çìììăăăƚ Šẽẽẽřṽìììçẽẽẽš]';
    'Call Center': () =>
      | 'Call Center / BPO / Shared Service'
      | 'Pusat Layanan Informasi  / BPO / Layanan Bersama'
      | '[Çăăăƚƚ Çẽẽẽกี้ṯẽẽẽř / ẞƤÖ / Šḩăăăřẽẽẽƌ Šẽẽẽřṽìììçẽẽẽ]';
    Computer: () =>
      | 'Computer / Information Technology'
      | 'Komputer / Teknologi Informasi / Konsultan IT'
      | '[Çöööm̂ƥǚǚǚṯẽẽẽř / Ïกี้ƒöööřm̂ăăăṯìììöööกี้ Ṯẽẽẽçḩกี้öööƚöööģýýý]';
    Construction: () =>
      | 'Construction / Building / Engineering'
      | 'Industri Berat / Permesinan / Peralatan Industri'
      | '[Çöööกี้šṯřǚǚǚçṯìììöööกี้ / ẞǚǚǚìììƚƌìììกี้ģ / Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ]';
    'Consulting Business': () =>
      | 'Consulting (Business & Management)'
      | 'Konsultan (Bisnis dan Manajemen)'
      | '[Çöööกี้šǚǚǚƚṯìììกี้ģ (ẞǚǚǚšìììกี้ẽẽẽšš & Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ)]';
    'Consulting IT': () =>
      | 'Consulting (IT Science  Engineering & Technical)'
      | 'Konstruksi / Bangunan / Teknik / Konsultan Teknik dan Mesin'
      | '[Çöööกี้šǚǚǚƚṯìììกี้ģ (ÏṮ Šçìììẽẽẽกี้çẽẽẽ  Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ & Ṯẽẽẽçḩกี้ìììçăăăƚ)]';
    Consumer: () =>
      | 'Consumer Products / FMCG'
      | 'Produk Konsumen / FMCG'
      | '[Çöööกี้šǚǚǚm̂ẽẽẽř Ƥřöööƌǚǚǚçṯš / ƑṂÇǦ]';
    eCommerce: () =>
      | 'eCommerce'
      | 'E-Commerce / Fintech / Tech Company'
      | '[ẽẽÇööm̂m̂ẽẽřçẽẽ]';
    Education: () => 'Education' | 'Pendidikan' | '[Ëƌǚǚçăăṯììööกี้]';
    Electrical: () =>
      | 'Electrical & Electronics'
      | 'Elektrik & Elektronik'
      | '[Ëƚẽẽẽçṯřìììçăăăƚ & Ëƚẽẽẽçṯřöööกี้ìììçš]';
    Entertainment: () =>
      | 'Entertainment Media'
      | 'Media Hiburan'
      | '[Ëกี้ṯẽẽẽřṯăăăìììกี้m̂ẽẽẽกี้ṯ Ṃẽẽẽƌìììăăă]';
    Food: () =>
      | 'Food & Beverage / Catering / Restaurant'
      | 'Makanan dan Minuman / Katering / Restoran'
      | '[Ƒööööööƌ & ẞẽẽẽṽẽẽẽřăăăģẽẽẽ / Çăăăṯẽẽẽřìììกี้ģ / Řẽẽẽšṯăăăǚǚǚřăăăกี้ṯ]';
    General: () =>
      | 'General & Wholesale Trading'
      | 'Perdagangan Umum dan Grosir'
      | '[Ǧẽẽẽกี้ẽẽẽřăăăƚ & Ŵḩöööƚẽẽẽšăăăƚẽẽẽ Ṯřăăăƌìììกี้ģ]';
    Healthcare: () =>
      | 'Healthcare / Medical / BioTechnology / Pharmaceutical'
      | 'Perawatan Kesehatan / Medis'
      | '[Ḩẽẽẽăăăƚṯḩçăăăřẽẽẽ / Ṃẽẽẽƌìììçăăăƚ / ẞìììöööṮẽẽẽçḩกี้öööƚöööģýýý / Ƥḩăăăřm̂ăăăçẽẽẽǚǚǚṯìììçăăăƚ]';
    Hotel: () =>
      | 'Hotel / Hospitality'
      | 'Hospitality'
      | '[Ḩöööṯẽẽẽƚ / Ḩöööšƥìììṯăăăƚìììṯýýý]';
    Insurance: () =>
      | 'Insurance'
      | 'Perbankan dan Asuransi'
      | '[Ïกี้šǚǚǚřăăăกี้çẽẽẽ]';
    Manufacturing: () =>
      | 'Manufacturing / Production'
      | 'Manufaktur / Produksi'
      | '[Ṃăăăกี้ǚǚǚƒăăăçṯǚǚǚřìììกี้ģ / Ƥřöööƌǚǚǚçṯìììöööกี้]';
    Oil: () =>
      | 'Oil / Gas / Petroleum'
      | 'Minyak dan Gas'
      | '[Öìììƚ / Ǧăăăš / Ƥẽẽẽṯřöööƚẽẽẽǚǚǚm̂]';
    Property: () =>
      | 'Property / Real Estate'
      | 'Properti / Penyewaan / Real Estate'
      | '[Ƥřöööƥẽẽẽřṯýýý / Řẽẽẽăăăƚ Ëšṯăăăṯẽẽẽ]';
    Retail: () =>
      | 'Retail / Merchandise'
      | 'Ritel'
      | '[Řẽẽẽṯăăăìììƚ / Ṃẽẽẽřçḩăăăกี้ƌìììšẽẽẽ]';
    Telecommunication: () =>
      | 'Telecommunication'
      | 'Telekomunikasi'
      | '[Ṯẽẽƚẽẽçööm̂m̂ǚǚกี้ììçăăṯììööกี้]';
    Transportation: () =>
      | 'Transportation / Logistics'
      | 'Logistik / Ekspedisi'
      | '[Ṯřăăăกี้šƥöööřṯăăăṯìììöööกี้ / Łöööģìììšṯìììçš]';
    Others: () => 'Others' | 'Lainnya' | '[Öṯḩẽẽẽẽřš]';
    'Accounting-TH': () =>
      | 'Accounting / Audit / Tax'
      | 'ธุรกิจบริการบัญชี / ตรวจสอบบัญชี / ภาษี'
      | '[Ậççöööǚǚǚกี้ṯìììกี้ģ / Ậǚǚǚƌìììṯ / Ṯăăăꭕ]';
    'Advertising-TH': () =>
      | 'Advertising, Media & Publishing'
      | 'ธุรกิจโฆษณา สื่อ และสิงพิมพ์'
      | '[Ậƌṽẽẽẽřṯìììšìììกี้ģ, Ṃẽẽẽƌìììăăă & Ƥǚǚǚßƚìììšḩìììกี้ģ]';
    'Banking-TH': () =>
      | 'Banking & Financial Services'
      | 'ธุรกิจธนาคารและบริการทางการเงิน'
      | '[ẞăăăกี้ķìììกี้ģ & Ƒìììกี้ăăăกี้çìììăăăƚ Šẽẽẽřṽìììçẽẽẽš]';
    'Beauty-TH': () =>
      | 'Beauty & Health'
      | 'ธุรกิจความงามและสุขภาพ'
      | '[ẞẽẽăăǚǚṯýý & Ḩẽẽăăƚṯḩ]';
    'Building-TH': () =>
      | 'Building & Construction'
      | 'ธุรกิจก่อสร้าง'
      | '[ẞǚǚǚìììƚƌìììกี้ģ & Çöööกี้šṯřǚǚǚçṯìììöööกี้]';
    'Education-TH': () =>
      | 'Education & Training'
      | 'ธุรกิจการศึกษาและการฝึกอบรม'
      | '[Ëƌǚǚǚçăăăṯìììöööกี้ & Ṯřăăăìììกี้ìììกี้ģ]';
    'Engineering-TH': () =>
      | 'Engineering'
      | 'ธุรกิจวิศวกรรม'
      | '[Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ]';
    'Food-TH': () =>
      | 'Food & Beverage'
      | 'ธุรกิจอาหารและเครื่องดื่ม'
      | '[Ƒööööƌ & ẞẽẽṽẽẽřăăģẽẽ]';
    'Government-TH': () =>
      | 'Government & Public Organization'
      | 'รัฐบาลและองค์การมหาชน'
      | '[Ǧöööṽẽẽẽřกี้m̂ẽẽẽกี้ṯ & Ƥǚǚǚßƚìììç Öřģăăăกี้ìììƶăăăṯìììöööกี้]';
    'Hospitality-TH': () =>
      | 'Hospitality'
      | 'ธุรกิจโรงแรมและการบริการ'
      | '[Ḩööšƥììṯăăƚììṯýý]';
    'IT-TH': () =>
      | 'Information & Communications Technology'
      | 'ธุรกิจไอที'
      | '[Ïกี้ƒöööřm̂ăăăṯìììöööกี้ & Çöööm̂m̂ǚǚǚกี้ìììçăăăṯìììöööกี้š Ṯẽẽẽçḩกี้öööƚöööģýýý]';
    'Insurance-TH': () =>
      | 'Insurance'
      | 'ธุรกิจประกันภัย'
      | '[Ïกี้šǚǚǚřăăăกี้çẽẽẽ]';
    'Internet-TH': () =>
      | 'Internet & Start-up'
      | 'ธุรกิจสตาร์ทอัพ/อีคอมเมิร์ซ'
      | '[Ïกี้ṯẽẽẽřกี้ẽẽẽṯ & Šṯăăăřṯ-ǚǚǚƥ]';
    'Medical-TH': () =>
      | 'Medical & Pharmaceutical'
      | 'ธุรกิจการแพทย์และเภสัชกรรม'
      | '[Ṃẽẽẽƌìììçăăăƚ & Ƥḩăăăřm̂ăăăçẽẽẽǚǚǚṯìììçăăăƚ]';
    'Property-TH': () =>
      | 'Property Development & Management'
      | 'ธุรกิจอสังหาริมทรัพย์'
      | '[Ƥřöööƥẽẽẽřṯýýý Ḋẽẽẽṽẽẽẽƚöööƥm̂ẽẽẽกี้ṯ & Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ]';
    'Recruitment-TH': () =>
      | 'Recruitment'
      | 'ธุรกิจการจัดการทรัพยากรบุคคล'
      | '[Řẽẽẽçřǚǚǚìììṯm̂ẽẽẽกี้ṯ]';
    'Retail-TH': () => 'Retail' | 'ธุรกิจขายปลีก' | '[Řẽẽṯăăììƚ]';
    'Trading-TH': () =>
      | 'Trading & Distribution'
      | 'ธุรกิจจัดจำหน่าย'
      | '[Ṯřăăăƌìììกี้ģ & Ḋìììšṯřìììßǚǚǚṯìììöööกี้]';
    'Transportation-TH': () =>
      | 'Transportation & Logistics'
      | 'ธุรกิจการขนส่งและโลจิสติกส์ '
      | '[Ṯřăăăกี้šƥöööřṯăăăṯìììöööกี้ & Łöööģìììšṯìììçš]';
    'Travel-TH': () =>
      | 'Travel & Tourism'
      | 'ธุรกิจการท่องเที่ยว'
      | '[Ṯřăăăṽẽẽẽƚ & Ṯöööǚǚǚřìììšm̂]';
    'Others-TH': () => 'Others' | 'ธุรกิจอื่น ๆ' | '[Öṯḩẽẽẽẽřš]';
    'Electronics-TH': () =>
      | 'Electronics/Electrical Equipment'
      | 'ธุรกิจอิเล็กทรอนิกส์/อุปกรณ์อิเล็กทรอนิกส์'
      | '[Ëƚẽẽẽçṯřöööกี้ìììçš/Ëƚẽẽẽçṯřìììçăăăƚ Ëq̇ǚǚǚìììƥm̂ẽẽẽกี้ṯ]';
    'Energy-TH': () =>
      | 'Energy/Power/Water/Oil & Gas/Waste Management'
      | 'ธุรกิจพลังงาน/ไฟฟ้า/น้ำ/น้ำมันและก๊าซ/การจัดการสิ่งปฏิกูล'
      | '[Ëกี้ẽẽẽřģýýý/Ƥöööŵẽẽẽř/Ŵăăăṯẽẽẽř/Öìììƚ & Ǧăăăš/Ŵăăăšṯẽẽẽ Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ]';
    'Manufacturing-TH': () =>
      | 'Manufacturing'
      | 'ธุรกิจการผลิต'
      | '[Ṃăăăกี้ǚǚǚƒăăăçṯǚǚǚřìììกี้ģ]';
    'Motor-TH': () =>
      | 'Motor Vehicles'
      | 'ธุรกิจยานยนต์'
      | '[Ṃöööṯöööř Ṽẽẽẽḩìììçƚẽẽẽš]';
    'Telecommunication-TH': () =>
      | 'Telecommunication'
      | 'ธุรกิจโทรคมนาคม'
      | '[Ṯẽẽƚẽẽçööm̂m̂ǚǚกี้ììçăăṯììööกี้]';
    'Privacy Statement': <T = string>(values: {
      Link: FormatXMLElementFn<T>;
      privacyAgreement: string;
    }) => ReturnType<FormatXMLElementFn<T>>;
    'recaptcha verify': () =>
      | 'Please verify that you are not a robot.'
      | '[Ƥƚẽẽăăšẽẽ ṽẽẽřììƒýý ṯḩăăṯ ýýööǚǚ ăăřẽẽ กี้ööṯ ăă řöößööṯ.]';
  }
>({
  en: createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  th: createLanguage({
    'First Name': 'ชื่อ',
    'Last Name': 'นามสกุล',
    'Company Email': 'อีเมลบริษัท',
    'Phone Number': 'เบอร์โทรบริษัท/เบอร์มือถือ',
    'Company Name': 'ชื่อบริษัทที่จดทะเบียน',
    'Job Title': 'ตำแหน่ง',
    Industry: 'ประเภทธุรกิจ',
    'User Identity': 'คุณเป็นผู้ประกอบการหรือผู้หางาน?',
    'Hiring need': 'บริษัทของคุณมีความต้องการจ้างงานใน 3 เดือนข้างหน้าหรือไม่',
    'Marketing required':
      'ฉันต้องการรับข้อมูลข่าวสาร โปรโมชัน กิจกรรมทางการตลาด (ข้อมูลที่เกี่ยวข้องกับการสรรหาบุคลากร ข้อเสนอแนะ ข้อมูลเชิงลึกเกี่ยวกับตลาดงาน และอื่น ๆ )',
    'Organization advertise': 'องค์กรของคุณลงประกาศงานกับ {site} หรือไม่?',
    Employer: 'ผู้ประกอบการ',
    'Job Seeker': 'ผู้หางาน',
    Yes: 'ต้องการ',
    No: 'ไม่ต้องการ',
    'Not sure': 'ไม่แน่ใจ',
    'Industry placeholder': 'โปรดเลือก',
    Submit: 'ส่ง',
    'Something Wrong': 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.',
    'Submit Download': 'ส่ง & ดาวน์โหลด',
    Confirmation: 'ขอบคุณสําหรับการส่งข้อมูล',
    'Download Report': 'ดาวน์โหลดรายงาน',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'ธุรกิจบริการบัญชี / ตรวจสอบบัญชี / ภาษี',
    'Advertising-TH': 'ธุรกิจโฆษณา สื่อ และสิงพิมพ์',
    'Banking-TH': 'ธุรกิจธนาคารและบริการทางการเงิน',
    'Beauty-TH': 'ธุรกิจความงามและสุขภาพ',
    'Building-TH': 'ธุรกิจก่อสร้าง',
    'Education-TH': 'ธุรกิจการศึกษาและการฝึกอบรม',
    'Engineering-TH': 'ธุรกิจวิศวกรรม',
    'Food-TH': 'ธุรกิจอาหารและเครื่องดื่ม',
    'Government-TH': 'รัฐบาลและองค์การมหาชน',
    'Hospitality-TH': 'ธุรกิจโรงแรมและการบริการ',
    'IT-TH': 'ธุรกิจไอที',
    'Insurance-TH': 'ธุรกิจประกันภัย',
    'Internet-TH': 'ธุรกิจสตาร์ทอัพ/อีคอมเมิร์ซ',
    'Medical-TH': 'ธุรกิจการแพทย์และเภสัชกรรม',
    'Property-TH': 'ธุรกิจอสังหาริมทรัพย์',
    'Recruitment-TH': 'ธุรกิจการจัดการทรัพยากรบุคคล',
    'Retail-TH': 'ธุรกิจขายปลีก',
    'Trading-TH': 'ธุรกิจจัดจำหน่าย',
    'Transportation-TH': 'ธุรกิจการขนส่งและโลจิสติกส์ ',
    'Travel-TH': 'ธุรกิจการท่องเที่ยว',
    'Others-TH': 'ธุรกิจอื่น ๆ',
    'Electronics-TH': 'ธุรกิจอิเล็กทรอนิกส์/อุปกรณ์อิเล็กทรอนิกส์',
    'Energy-TH': 'ธุรกิจพลังงาน/ไฟฟ้า/น้ำ/น้ำมันและก๊าซ/การจัดการสิ่งปฏิกูล',
    'Manufacturing-TH': 'ธุรกิจการผลิต',
    'Motor-TH': 'ธุรกิจยานยนต์',
    'Telecommunication-TH': 'ธุรกิจโทรคมนาคม',
    'Privacy Statement':
      'เมื่อกดส่งข้อมูลผ่านแบบฟอร์มนี้ คุณได้รับรู้และยอมรับ<Link>{privacyAgreement}</Link>',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  id: createLanguage({
    'First Name': 'Nama Depan',
    'Last Name': 'Nama Belakang',
    'Company Email': 'Alamat Email',
    'Phone Number': 'Nomor Telepon Perusahaan / Nomor HP',
    'Company Name': 'Nama Perusahaan Terdaftar',
    'Job Title': 'Posisi Pekerjaan',
    Industry: 'Bidang usaha utama saya adalah',
    'User Identity':
      'Apakah Anda mewakili perusahaan atau seorang pencari kerja?',
    'Hiring need': 'Saya perlu melakukan rekrutmen dalam 3 bulan ke depan',
    'Marketing required':
      'Saya ingin menerima promosi & komunikasi pemasaran (berita, tips rekrutmen, wawasan pasar)',
    'Organization advertise':
      'Apakah perusahaan sedang memasang iklan di Jobstreet?',
    Employer: 'Perusahaan',
    'Job Seeker': 'Pencari kerja',
    Yes: 'lya',
    No: 'Tidak',
    'Not sure': 'Saya tidak yakin',
    'Industry placeholder': 'Silahkan pilih',
    Submit: 'Kirim',
    'Something Wrong': 'Terjadi kesalahan, harap coba lagi.',
    'Submit Download': 'Kirim & Unduh',
    Confirmation: 'Terima kasih',
    'Download Report': 'Unduh Laporan',
    Accounting: 'Akunting / Audit / Jasa Perpajakan',
    Advertising: 'Periklanan / Pemasaran / Promosi / Humas',
    Automobile: 'Otomotif / Suku Cadang / Kendaraan',
    Banking: 'Jasa Keuangan',
    'Call Center': 'Pusat Layanan Informasi  / BPO / Layanan Bersama',
    Computer: 'Komputer / Teknologi Informasi / Konsultan IT',
    Construction: 'Industri Berat / Permesinan / Peralatan Industri',
    'Consulting Business': 'Konsultan (Bisnis dan Manajemen)',
    'Consulting IT':
      'Konstruksi / Bangunan / Teknik / Konsultan Teknik dan Mesin',
    Consumer: 'Produk Konsumen / FMCG',
    eCommerce: 'E-Commerce / Fintech / Tech Company',
    Education: 'Pendidikan',
    Electrical: 'Elektrik & Elektronik',
    Entertainment: 'Media Hiburan',
    Food: 'Makanan dan Minuman / Katering / Restoran',
    General: 'Perdagangan Umum dan Grosir',
    Healthcare: 'Perawatan Kesehatan / Medis',
    Hotel: 'Hospitality',
    Insurance: 'Perbankan dan Asuransi',
    Manufacturing: 'Manufaktur / Produksi',
    Oil: 'Minyak dan Gas',
    Property: 'Properti / Penyewaan / Real Estate',
    Retail: 'Ritel',
    Telecommunication: 'Telekomunikasi',
    Transportation: 'Logistik / Ekspedisi',
    Others: 'Lainnya',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'Dengan unggah formulir ini, Anda mengetahui dan setuju dengan <Link>{privacyAgreement}</Link> kami.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  zh: createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-AU': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-HK': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-ID': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-MY': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-NZ': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-PH': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-SG': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-TH': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'th-TH': createLanguage({
    'First Name': 'ชื่อ',
    'Last Name': 'นามสกุล',
    'Company Email': 'อีเมลบริษัท',
    'Phone Number': 'เบอร์โทรบริษัท/เบอร์มือถือ',
    'Company Name': 'ชื่อบริษัทที่จดทะเบียน',
    'Job Title': 'ตำแหน่ง',
    Industry: 'ประเภทธุรกิจ',
    'User Identity': 'คุณเป็นผู้ประกอบการหรือผู้หางาน?',
    'Hiring need': 'บริษัทของคุณมีความต้องการจ้างงานใน 3 เดือนข้างหน้าหรือไม่',
    'Marketing required':
      'ฉันต้องการรับข้อมูลข่าวสาร โปรโมชัน กิจกรรมทางการตลาด (ข้อมูลที่เกี่ยวข้องกับการสรรหาบุคลากร ข้อเสนอแนะ ข้อมูลเชิงลึกเกี่ยวกับตลาดงาน และอื่น ๆ )',
    'Organization advertise': 'องค์กรของคุณลงประกาศงานกับ {site} หรือไม่?',
    Employer: 'ผู้ประกอบการ',
    'Job Seeker': 'ผู้หางาน',
    Yes: 'ต้องการ',
    No: 'ไม่ต้องการ',
    'Not sure': 'ไม่แน่ใจ',
    'Industry placeholder': 'โปรดเลือก',
    Submit: 'ส่ง',
    'Something Wrong': 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง.',
    'Submit Download': 'ส่ง & ดาวน์โหลด',
    Confirmation: 'ขอบคุณสําหรับการส่งข้อมูล',
    'Download Report': 'ดาวน์โหลดรายงาน',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'ธุรกิจบริการบัญชี / ตรวจสอบบัญชี / ภาษี',
    'Advertising-TH': 'ธุรกิจโฆษณา สื่อ และสิงพิมพ์',
    'Banking-TH': 'ธุรกิจธนาคารและบริการทางการเงิน',
    'Beauty-TH': 'ธุรกิจความงามและสุขภาพ',
    'Building-TH': 'ธุรกิจก่อสร้าง',
    'Education-TH': 'ธุรกิจการศึกษาและการฝึกอบรม',
    'Engineering-TH': 'ธุรกิจวิศวกรรม',
    'Food-TH': 'ธุรกิจอาหารและเครื่องดื่ม',
    'Government-TH': 'รัฐบาลและองค์การมหาชน',
    'Hospitality-TH': 'ธุรกิจโรงแรมและการบริการ',
    'IT-TH': 'ธุรกิจไอที',
    'Insurance-TH': 'ธุรกิจประกันภัย',
    'Internet-TH': 'ธุรกิจสตาร์ทอัพ/อีคอมเมิร์ซ',
    'Medical-TH': 'ธุรกิจการแพทย์และเภสัชกรรม',
    'Property-TH': 'ธุรกิจอสังหาริมทรัพย์',
    'Recruitment-TH': 'ธุรกิจการจัดการทรัพยากรบุคคล',
    'Retail-TH': 'ธุรกิจขายปลีก',
    'Trading-TH': 'ธุรกิจจัดจำหน่าย',
    'Transportation-TH': 'ธุรกิจการขนส่งและโลจิสติกส์ ',
    'Travel-TH': 'ธุรกิจการท่องเที่ยว',
    'Others-TH': 'ธุรกิจอื่น ๆ',
    'Electronics-TH': 'ธุรกิจอิเล็กทรอนิกส์/อุปกรณ์อิเล็กทรอนิกส์',
    'Energy-TH': 'ธุรกิจพลังงาน/ไฟฟ้า/น้ำ/น้ำมันและก๊าซ/การจัดการสิ่งปฏิกูล',
    'Manufacturing-TH': 'ธุรกิจการผลิต',
    'Motor-TH': 'ธุรกิจยานยนต์',
    'Telecommunication-TH': 'ธุรกิจโทรคมนาคม',
    'Privacy Statement':
      'เมื่อกดส่งข้อมูลผ่านแบบฟอร์มนี้ คุณได้รับรู้และยอมรับ<Link>{privacyAgreement}</Link>',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'id-ID': createLanguage({
    'First Name': 'Nama Depan',
    'Last Name': 'Nama Belakang',
    'Company Email': 'Alamat Email',
    'Phone Number': 'Nomor Telepon Perusahaan / Nomor HP',
    'Company Name': 'Nama Perusahaan Terdaftar',
    'Job Title': 'Posisi Pekerjaan',
    Industry: 'Bidang usaha utama saya adalah',
    'User Identity':
      'Apakah Anda mewakili perusahaan atau seorang pencari kerja?',
    'Hiring need': 'Saya perlu melakukan rekrutmen dalam 3 bulan ke depan',
    'Marketing required':
      'Saya ingin menerima promosi & komunikasi pemasaran (berita, tips rekrutmen, wawasan pasar)',
    'Organization advertise':
      'Apakah perusahaan sedang memasang iklan di Jobstreet?',
    Employer: 'Perusahaan',
    'Job Seeker': 'Pencari kerja',
    Yes: 'lya',
    No: 'Tidak',
    'Not sure': 'Saya tidak yakin',
    'Industry placeholder': 'Silahkan pilih',
    Submit: 'Kirim',
    'Something Wrong': 'Terjadi kesalahan, harap coba lagi.',
    'Submit Download': 'Kirim & Unduh',
    Confirmation: 'Terima kasih',
    'Download Report': 'Unduh Laporan',
    Accounting: 'Akunting / Audit / Jasa Perpajakan',
    Advertising: 'Periklanan / Pemasaran / Promosi / Humas',
    Automobile: 'Otomotif / Suku Cadang / Kendaraan',
    Banking: 'Jasa Keuangan',
    'Call Center': 'Pusat Layanan Informasi  / BPO / Layanan Bersama',
    Computer: 'Komputer / Teknologi Informasi / Konsultan IT',
    Construction: 'Industri Berat / Permesinan / Peralatan Industri',
    'Consulting Business': 'Konsultan (Bisnis dan Manajemen)',
    'Consulting IT':
      'Konstruksi / Bangunan / Teknik / Konsultan Teknik dan Mesin',
    Consumer: 'Produk Konsumen / FMCG',
    eCommerce: 'E-Commerce / Fintech / Tech Company',
    Education: 'Pendidikan',
    Electrical: 'Elektrik & Elektronik',
    Entertainment: 'Media Hiburan',
    Food: 'Makanan dan Minuman / Katering / Restoran',
    General: 'Perdagangan Umum dan Grosir',
    Healthcare: 'Perawatan Kesehatan / Medis',
    Hotel: 'Hospitality',
    Insurance: 'Perbankan dan Asuransi',
    Manufacturing: 'Manufaktur / Produksi',
    Oil: 'Minyak dan Gas',
    Property: 'Properti / Penyewaan / Real Estate',
    Retail: 'Ritel',
    Telecommunication: 'Telekomunikasi',
    Transportation: 'Logistik / Ekspedisi',
    Others: 'Lainnya',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'Dengan unggah formulir ini, Anda mengetahui dan setuju dengan <Link>{privacyAgreement}</Link> kami.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'zh-HK': createLanguage({
    'First Name': 'First name',
    'Last Name': 'Last name',
    'Company Email': 'Company email',
    'Phone Number': 'Company/mobile number',
    'Company Name': 'Registered company name',
    'Job Title': 'Job title/designation',
    Industry: 'My primary industry is',
    'User Identity': 'Are you an employer or a job seeker?',
    'Hiring need': 'I have hiring needs in the next 3 months',
    'Marketing required':
      'I would like to receive promotion & marketing communications (news, hiring advise, market insights, etc)',
    'Organization advertise': 'Does your organization advertise with {site}?',
    Employer: 'Employer',
    'Job Seeker': 'Job Seeker',
    Yes: 'Yes',
    No: 'No',
    'Not sure': 'I’m not sure',
    'Industry placeholder': 'Please select',
    Submit: 'Submit',
    'Something Wrong': 'Something went wrong, please try again.',
    'Submit Download': 'Submit & Download',
    Confirmation: 'Thank you for your submission',
    'Download Report': 'Download Report',
    Accounting: 'Accounting / Audit / Tax Services',
    Advertising: 'Advertising / Marketing / Promotion / PR',
    Automobile: 'Automobile / Automotive Ancillary / Vehicle',
    Banking: 'Banking / Financial Services',
    'Call Center': 'Call Center / BPO / Shared Service',
    Computer: 'Computer / Information Technology',
    Construction: 'Construction / Building / Engineering',
    'Consulting Business': 'Consulting (Business & Management)',
    'Consulting IT': 'Consulting (IT Science  Engineering & Technical)',
    Consumer: 'Consumer Products / FMCG',
    eCommerce: 'eCommerce',
    Education: 'Education',
    Electrical: 'Electrical & Electronics',
    Entertainment: 'Entertainment Media',
    Food: 'Food & Beverage / Catering / Restaurant',
    General: 'General & Wholesale Trading',
    Healthcare: 'Healthcare / Medical / BioTechnology / Pharmaceutical',
    Hotel: 'Hotel / Hospitality',
    Insurance: 'Insurance',
    Manufacturing: 'Manufacturing / Production',
    Oil: 'Oil / Gas / Petroleum',
    Property: 'Property / Real Estate',
    Retail: 'Retail / Merchandise',
    Telecommunication: 'Telecommunication',
    Transportation: 'Transportation / Logistics',
    Others: 'Others',
    'Accounting-TH': 'Accounting / Audit / Tax',
    'Advertising-TH': 'Advertising, Media & Publishing',
    'Banking-TH': 'Banking & Financial Services',
    'Beauty-TH': 'Beauty & Health',
    'Building-TH': 'Building & Construction',
    'Education-TH': 'Education & Training',
    'Engineering-TH': 'Engineering',
    'Food-TH': 'Food & Beverage',
    'Government-TH': 'Government & Public Organization',
    'Hospitality-TH': 'Hospitality',
    'IT-TH': 'Information & Communications Technology',
    'Insurance-TH': 'Insurance',
    'Internet-TH': 'Internet & Start-up',
    'Medical-TH': 'Medical & Pharmaceutical',
    'Property-TH': 'Property Development & Management',
    'Recruitment-TH': 'Recruitment',
    'Retail-TH': 'Retail',
    'Trading-TH': 'Trading & Distribution',
    'Transportation-TH': 'Transportation & Logistics',
    'Travel-TH': 'Travel & Tourism',
    'Others-TH': 'Others',
    'Electronics-TH': 'Electronics/Electrical Equipment',
    'Energy-TH': 'Energy/Power/Water/Oil & Gas/Waste Management',
    'Manufacturing-TH': 'Manufacturing',
    'Motor-TH': 'Motor Vehicles',
    'Telecommunication-TH': 'Telecommunication',
    'Privacy Statement':
      'By submitting this form, you acknowledge and agree to our <Link>{privacyAgreement}</Link>.',
    'recaptcha verify': 'Please verify that you are not a robot.',
  }),
  'en-PSEUDO': createLanguage({
    'First Name': '[Ƒìììřšṯ กี้ăăăm̂ẽẽẽ]',
    'Last Name': '[Łăăăšṯ กี้ăăăm̂ẽẽẽ]',
    'Company Email': '[Çööm̂ƥăăกี้ýý ẽẽm̂ăăììƚ]',
    'Phone Number': '[Çöööm̂ƥăăăกี้ýýý/m̂ööößìììƚẽẽẽ กี้ǚǚǚm̂ßẽẽẽř]',
    'Company Name': '[Řẽẽẽģìììšṯẽẽẽřẽẽẽƌ çöööm̂ƥăăăกี้ýýý กี้ăăăm̂ẽẽẽ]',
    'Job Title': '[Ĵöööß ṯìììṯƚẽẽẽ/ƌẽẽẽšìììģกี้ăăăṯìììöööกี้]',
    Industry: '[Ṃýýý ƥřìììm̂ăăăřýýý ìììกี้ƌǚǚǚšṯřýýý ìììš]',
    'User Identity': '[Ậřẽẽ ýýööǚǚ ăăกี้ ẽẽm̂ƥƚööýýẽẽř ööř ăă ĵööß šẽẽẽẽķẽẽř?]',
    'Hiring need':
      '[Ï ḩăăăṽẽẽẽ ḩìììřìììกี้ģ กี้ẽẽẽẽẽẽƌš ìììกี้ ṯḩẽẽẽ กี้ẽẽẽꭕṯ 3 m̂öööกี้ṯḩš]',
    'Marketing required':
      '[Ï ŵöööǚǚǚƚƌ ƚìììķẽẽẽ ṯööö řẽẽẽçẽẽẽìììṽẽẽẽ ƥřöööm̂öööṯìììöööกี้ & m̂ăăăřķẽẽẽṯìììกี้ģ çöööm̂m̂ǚǚǚกี้ìììçăăăṯìììöööกี้š (กี้ẽẽẽŵš, ḩìììřìììกี้ģ ăăăƌṽìììšẽẽẽ, m̂ăăăřķẽẽẽṯ ìììกี้šìììģḩṯš, ẽẽẽṯç)]',
    'Organization advertise':
      '[Ḋööẽẽš ýýööǚǚř ööřģăăกี้ììƶăăṯììööกี้ ăăƌṽẽẽřṯììšẽẽ ŵììṯḩ {site}?]',
    Employer: '[Ëm̂ƥƚöööýýýẽẽẽř]',
    'Job Seeker': '[Ĵööß Šẽẽẽẽķẽẽř]',
    Yes: '[Ýẽẽẽš]',
    No: '[Ñöö]',
    'Not sure': '[Ï’m̂ กี้öööṯ šǚǚǚřẽẽẽ]',
    'Industry placeholder': '[Ƥƚẽẽẽăăăšẽẽẽ šẽẽẽƚẽẽẽçṯ]',
    Submit: '[Šǚǚǚßm̂ìììṯ]',
    'Something Wrong':
      '[Šöööm̂ẽẽẽṯḩìììกี้ģ ŵẽẽẽกี้ṯ ŵřöööกี้ģ, ƥƚẽẽẽăăăšẽẽẽ ṯřýýý ăăăģăăăìììกี้.]',
    'Submit Download': '[Šǚǚǚßm̂ìììṯ & Ḋöööŵกี้ƚöööăăăƌ]',
    Confirmation: '[Ṯḩăăกี้ķ ýýööǚǚ ƒööř ýýööǚǚř šǚǚßm̂ììššììööกี้]',
    'Download Report': '[Ḋöööŵกี้ƚöööăăăƌ Řẽẽẽƥöööřṯ]',
    Accounting: '[Ậççöööǚǚǚกี้ṯìììกี้ģ / Ậǚǚǚƌìììṯ / Ṯăăăꭕ Šẽẽẽřṽìììçẽẽẽš]',
    Advertising:
      '[Ậƌṽẽẽẽřṯìììšìììกี้ģ / Ṃăăăřķẽẽẽṯìììกี้ģ / Ƥřöööm̂öööṯìììöööกี้ / ƤŘ]',
    Automobile:
      '[Ậǚǚǚṯöööm̂ööößìììƚẽẽẽ / Ậǚǚǚṯöööm̂öööṯìììṽẽẽẽ Ậกี้çìììƚƚăăăřýýý / Ṽẽẽẽḩìììçƚẽẽẽ]',
    Banking: '[ẞăăăกี้ķìììกี้ģ / Ƒìììกี้ăăăกี้çìììăăăƚ Šẽẽẽřṽìììçẽẽẽš]',
    'Call Center': '[Çăăăƚƚ Çẽẽẽกี้ṯẽẽẽř / ẞƤÖ / Šḩăăăřẽẽẽƌ Šẽẽẽřṽìììçẽẽẽ]',
    Computer: '[Çöööm̂ƥǚǚǚṯẽẽẽř / Ïกี้ƒöööřm̂ăăăṯìììöööกี้ Ṯẽẽẽçḩกี้öööƚöööģýýý]',
    Construction:
      '[Çöööกี้šṯřǚǚǚçṯìììöööกี้ / ẞǚǚǚìììƚƌìììกี้ģ / Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ]',
    'Consulting Business':
      '[Çöööกี้šǚǚǚƚṯìììกี้ģ (ẞǚǚǚšìììกี้ẽẽẽšš & Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ)]',
    'Consulting IT':
      '[Çöööกี้šǚǚǚƚṯìììกี้ģ (ÏṮ Šçìììẽẽẽกี้çẽẽẽ  Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ & Ṯẽẽẽçḩกี้ìììçăăăƚ)]',
    Consumer: '[Çöööกี้šǚǚǚm̂ẽẽẽř Ƥřöööƌǚǚǚçṯš / ƑṂÇǦ]',
    eCommerce: '[ẽẽÇööm̂m̂ẽẽřçẽẽ]',
    Education: '[Ëƌǚǚçăăṯììööกี้]',
    Electrical: '[Ëƚẽẽẽçṯřìììçăăăƚ & Ëƚẽẽẽçṯřöööกี้ìììçš]',
    Entertainment: '[Ëกี้ṯẽẽẽřṯăăăìììกี้m̂ẽẽẽกี้ṯ Ṃẽẽẽƌìììăăă]',
    Food: '[Ƒööööööƌ & ẞẽẽẽṽẽẽẽřăăăģẽẽẽ / Çăăăṯẽẽẽřìììกี้ģ / Řẽẽẽšṯăăăǚǚǚřăăăกี้ṯ]',
    General: '[Ǧẽẽẽกี้ẽẽẽřăăăƚ & Ŵḩöööƚẽẽẽšăăăƚẽẽẽ Ṯřăăăƌìììกี้ģ]',
    Healthcare:
      '[Ḩẽẽẽăăăƚṯḩçăăăřẽẽẽ / Ṃẽẽẽƌìììçăăăƚ / ẞìììöööṮẽẽẽçḩกี้öööƚöööģýýý / Ƥḩăăăřm̂ăăăçẽẽẽǚǚǚṯìììçăăăƚ]',
    Hotel: '[Ḩöööṯẽẽẽƚ / Ḩöööšƥìììṯăăăƚìììṯýýý]',
    Insurance: '[Ïกี้šǚǚǚřăăăกี้çẽẽẽ]',
    Manufacturing: '[Ṃăăăกี้ǚǚǚƒăăăçṯǚǚǚřìììกี้ģ / Ƥřöööƌǚǚǚçṯìììöööกี้]',
    Oil: '[Öìììƚ / Ǧăăăš / Ƥẽẽẽṯřöööƚẽẽẽǚǚǚm̂]',
    Property: '[Ƥřöööƥẽẽẽřṯýýý / Řẽẽẽăăăƚ Ëšṯăăăṯẽẽẽ]',
    Retail: '[Řẽẽẽṯăăăìììƚ / Ṃẽẽẽřçḩăăăกี้ƌìììšẽẽẽ]',
    Telecommunication: '[Ṯẽẽƚẽẽçööm̂m̂ǚǚกี้ììçăăṯììööกี้]',
    Transportation: '[Ṯřăăăกี้šƥöööřṯăăăṯìììöööกี้ / Łöööģìììšṯìììçš]',
    Others: '[Öṯḩẽẽẽẽřš]',
    'Accounting-TH': '[Ậççöööǚǚǚกี้ṯìììกี้ģ / Ậǚǚǚƌìììṯ / Ṯăăăꭕ]',
    'Advertising-TH': '[Ậƌṽẽẽẽřṯìììšìììกี้ģ, Ṃẽẽẽƌìììăăă & Ƥǚǚǚßƚìììšḩìììกี้ģ]',
    'Banking-TH': '[ẞăăăกี้ķìììกี้ģ & Ƒìììกี้ăăăกี้çìììăăăƚ Šẽẽẽřṽìììçẽẽẽš]',
    'Beauty-TH': '[ẞẽẽăăǚǚṯýý & Ḩẽẽăăƚṯḩ]',
    'Building-TH': '[ẞǚǚǚìììƚƌìììกี้ģ & Çöööกี้šṯřǚǚǚçṯìììöööกี้]',
    'Education-TH': '[Ëƌǚǚǚçăăăṯìììöööกี้ & Ṯřăăăìììกี้ìììกี้ģ]',
    'Engineering-TH': '[Ëกี้ģìììกี้ẽẽẽẽẽẽřìììกี้ģ]',
    'Food-TH': '[Ƒööööƌ & ẞẽẽṽẽẽřăăģẽẽ]',
    'Government-TH':
      '[Ǧöööṽẽẽẽřกี้m̂ẽẽẽกี้ṯ & Ƥǚǚǚßƚìììç Öřģăăăกี้ìììƶăăăṯìììöööกี้]',
    'Hospitality-TH': '[Ḩööšƥììṯăăƚììṯýý]',
    'IT-TH':
      '[Ïกี้ƒöööřm̂ăăăṯìììöööกี้ & Çöööm̂m̂ǚǚǚกี้ìììçăăăṯìììöööกี้š Ṯẽẽẽçḩกี้öööƚöööģýýý]',
    'Insurance-TH': '[Ïกี้šǚǚǚřăăăกี้çẽẽẽ]',
    'Internet-TH': '[Ïกี้ṯẽẽẽřกี้ẽẽẽṯ & Šṯăăăřṯ-ǚǚǚƥ]',
    'Medical-TH': '[Ṃẽẽẽƌìììçăăăƚ & Ƥḩăăăřm̂ăăăçẽẽẽǚǚǚṯìììçăăăƚ]',
    'Property-TH':
      '[Ƥřöööƥẽẽẽřṯýýý Ḋẽẽẽṽẽẽẽƚöööƥm̂ẽẽẽกี้ṯ & Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ]',
    'Recruitment-TH': '[Řẽẽẽçřǚǚǚìììṯm̂ẽẽẽกี้ṯ]',
    'Retail-TH': '[Řẽẽṯăăììƚ]',
    'Trading-TH': '[Ṯřăăăƌìììกี้ģ & Ḋìììšṯřìììßǚǚǚṯìììöööกี้]',
    'Transportation-TH': '[Ṯřăăăกี้šƥöööřṯăăăṯìììöööกี้ & Łöööģìììšṯìììçš]',
    'Travel-TH': '[Ṯřăăăṽẽẽẽƚ & Ṯöööǚǚǚřìììšm̂]',
    'Others-TH': '[Öṯḩẽẽẽẽřš]',
    'Electronics-TH':
      '[Ëƚẽẽẽçṯřöööกี้ìììçš/Ëƚẽẽẽçṯřìììçăăăƚ Ëq̇ǚǚǚìììƥm̂ẽẽẽกี้ṯ]',
    'Energy-TH':
      '[Ëกี้ẽẽẽřģýýý/Ƥöööŵẽẽẽř/Ŵăăăṯẽẽẽř/Öìììƚ & Ǧăăăš/Ŵăăăšṯẽẽẽ Ṃăăăกี้ăăăģẽẽẽm̂ẽẽẽกี้ṯ]',
    'Manufacturing-TH': '[Ṃăăăกี้ǚǚǚƒăăăçṯǚǚǚřìììกี้ģ]',
    'Motor-TH': '[Ṃöööṯöööř Ṽẽẽẽḩìììçƚẽẽẽš]',
    'Telecommunication-TH': '[Ṯẽẽƚẽẽçööm̂m̂ǚǚกี้ììçăăṯììööกี้]',
    'Privacy Statement':
      '[ẞýýý šǚǚǚßm̂ìììṯṯìììกี้ģ ṯḩìììš ƒöööřm̂, ýýýöööǚǚǚ ăăăçķกี้öööŵƚẽẽẽƌģẽẽẽ ăăăกี้ƌ ăăăģřẽẽẽẽẽẽ ṯööö öööǚǚǚř <Link>{privacyAgreement}</Link>.]',
    'recaptcha verify':
      '[Ƥƚẽẽăăšẽẽ ṽẽẽřììƒýý ṯḩăăṯ ýýööǚǚ ăăřẽẽ กี้ööṯ ăă řöößööṯ.]',
  }),
});

export default translations;
