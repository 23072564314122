import React from 'react';
import {
  Box,
  IconChevron,
  Inline,
  Link,
  MenuItemLink,
  MenuRenderer,
  Text,
} from 'braid-design-system';

import * as styles from '../styles.css';
import type { NestedLink } from '../types';
import { getUrlPath, trackLink } from '../helpers';
import { useCustomHeaderContext } from '../CustomHeaderProvider';
import { vars } from 'braid-design-system/css';

interface Props {
  url: string;
  text: string;
  active: boolean;
}

export const NavLink = ({ url, text, active }: Props) => {
  const { theme } = useCustomHeaderContext();
  const activeTone = theme === 'sara' ? 'brandAccent' : 'neutral';

  return (
    <Box
      paddingY="small"
      className={[styles.linkContainer, active && styles.linkActive]}
    >
      <Text
        size="small"
        weight={active ? 'strong' : 'regular'}
        tone={active ? 'neutral' : 'secondary'}
      >
        <Link
          href={url}
          onClick={() =>
            trackLink('navigation_pressed', {
              linkName: 'navigation_pressed',
              linkPosition: 'header',
              linkText: text,
            })
          }
        >
          <span className={styles.linkText}>{text}</span>
        </Link>
      </Text>
      <Box
        component="span"
        style={{ color: vars.backgroundColor[activeTone] }}
        className={styles.linkUnderline}
      />
    </Box>
  );
};

export const NavLinkGroup = ({
  links,
  alignLinks = 'left',
  activeRoute,
}: {
  links: NestedLink[];
  alignLinks?: 'left' | 'right';
  activeRoute: string;
}) => (
  <Box
    display="flex"
    justifyContent={{
      tablet: 'flexStart',
      desktop: alignLinks === 'left' ? 'flexStart' : 'flexEnd',
    }}
    style={{
      width: '100%',
    }}
  >
    <Inline space="gutter">
      {links.map((link, i) => {
        const { url, text, subLinks } = link;
        const isActive = activeRoute === getUrlPath(url);

        return (
          <Box paddingY="xxsmall" key={i} position="relative">
            <Inline space="xxsmall">
              <NavLink url={url} text={text} active={isActive} />
              {subLinks.length > 0 && (
                <Box paddingY="small">
                  <MenuRenderer
                    offsetSpace="small"
                    align="right"
                    trigger={(triggerProps, { open }) => (
                      <Box userSelect="none" cursor="pointer" {...triggerProps}>
                        <Text size="small">
                          <IconChevron
                            direction={open ? 'up' : 'down'}
                            alignY="lowercase"
                            tone={isActive ? 'neutral' : 'secondary'}
                          />
                        </Text>
                      </Box>
                    )}
                  >
                    {subLinks.map(
                      (subLink, index) =>
                        subLink.url && (
                          <MenuItemLink
                            key={index}
                            href={subLink.url}
                            onClick={() =>
                              trackLink('navigation_pressed', {
                                linkName: 'navigation_pressed',
                                linkPosition: 'header',
                                linkText: text,
                              })
                            }
                          >
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight:
                                  getUrlPath(subLink.url) === activeRoute
                                    ? vars.textWeight.strong
                                    : vars.textWeight.regular,
                              }}
                            >
                              {subLink.text}
                            </span>
                          </MenuItemLink>
                        ),
                    )}
                  </MenuRenderer>
                </Box>
              )}
            </Inline>
          </Box>
        );
      })}
    </Inline>
  </Box>
);
