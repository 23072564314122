import type { Brand } from '@seek/melways-sites';
import { JobsdbLogoSvg } from './Logo/JobsdbLogo';
import React from 'react';
import { SeekAnzLogoBlueSvg } from './Logo/SeekAnzLogoBlue';
import { JobstreetLogoSG } from './Logo/JobstreetLogo';
import { SeekAnzLogoSvg } from './Logo/SeekAnzLogo';

export const getLogoByBrand = (brand: Brand) => {
  switch (brand) {
    case 'seek':
      return <SeekAnzLogoBlueSvg />;
    case 'jobsdb':
      return <JobsdbLogoSvg height={32} />;
    case 'jobstreet':
      return <JobstreetLogoSG height={32} />;
    default:
      return <SeekAnzLogoSvg height={32} />;
  }
};
