import type { Locale } from '@seek/cmsu-cms-connect';
import { prefixUrlWithLanguage } from '../../helpers/prefixUrlWithLanguage';

type BreadCrumbItem = {
  url?: string;
  text: string;
};

const slugToTitle = (str: string): string =>
  str
    .split('-')
    .map((part) => part[0].toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');

export type BreadcrumbItemsProps = {
  locale: Locale;
  section: string;
  title?: string;
  parentCategoryName?: string;
  parentCategorySlug?: string;
  categoryName?: string;
  categorySlug?: string;
  parentSection?: string;
};

export const getBreadcrumbItems = ({
  locale,
  section,
  title = '',
  parentCategoryName,
  parentCategorySlug,
  categoryName,
  categorySlug,
  parentSection,
}: BreadcrumbItemsProps) => {
  const items: BreadCrumbItem[] = [
    {
      text: section && slugToTitle(section),
      url: prefixUrlWithLanguage({
        url: `${parentSection ? `/${parentSection}` : ''}/${section}`,
        locale,
      }),
    },
  ];
  if (categoryName && categorySlug) {
    items.push({
      text: categoryName || '',
      url: prefixUrlWithLanguage({
        url: `${
          parentSection ? `/${parentSection}` : ''
        }/${section}/category/${categorySlug}`,
        locale,
      }),
    });

    if (parentCategoryName && parentCategoryName.trim().length) {
      items.splice(1, 0, {
        text: parentCategoryName,
        url: prefixUrlWithLanguage({
          url: `/${section}/category/${parentCategorySlug}`,
          locale,
        }),
      });
    }
  }

  if (title.trim().length) {
    items.push({
      text: title,
    });
  }
  items[items.length - 1].url = undefined;
  return items;
};
