import type { Icon } from '@seek/cmsu-cms-connect';
import type {
  GetFilterFieldsQuery,
  GetSurveyQuery,
} from '../../../../../apps/unified-apac/src/gql/tal/generated';
import type {
  CandidateCardProps,
  CandidateData,
} from './components/CandidateInformation/CandidateCard';
import type {
  Driver,
  DriverList,
  DriverOption,
} from './components/QueryFilter';
import type { FormValues } from './components/QueryFilterForm';
import { datalabMapper } from './datalabMapper';
import {
  joinSearchParamString,
  splitSearchParamString,
} from './utils/searchParams';

export interface DatalabComponent {
  __typename: 'Datalab';
  datalabSurvey: DatalabSurvey;
}

export interface DatalabQueryParams {
  primaryDriver: string;
  primaryDriverOptions?: string[];
  secondaryDriver?: string;
  secondaryDriverOptions?: string[];
}

export interface DatalabSurvey {
  queryFilters: GetFilterFieldsQuery;
  surveyResults: GetSurveyQuery | null;
  drivers: DatalabDriverData | null;
  queryFilterParams: DatalabQueryParams;
}

export interface DriverSubCategoryResult {
  name: string;
  value: number;
  percentage: number;
}

export interface SubCategoryDriverOption {
  name: string;
  total: number;
  results: DriverSubCategoryResult[];
}

export interface DriverSubCategory {
  name: string;
  driverOptions: SubCategoryDriverOption[];
}

// Secondary Driver Options
export interface CategoryDriverOption {
  name: string;
  totalRespondents: number;
  value: number;
  percentage: number;
}

export interface DriverCategory {
  name: string;
  driverOptions: CategoryDriverOption[];
  subCategories: DriverSubCategory[];
}

export interface SecondaryDriverOption {
  name: string;
  hasLackOfSampleSizeData: boolean;
  hasMissingData: boolean;
}

export interface PrimaryDriverOption {
  name: string;
  categories: DriverCategory[];
  secondaryDriverOptions: SecondaryDriverOption[];
  sortBy?: string;
}

export interface DatalabDriverData {
  driverOptions: PrimaryDriverOption[];
}

export type DemographicItem =
  | GetSurveyQuery['demographic']['gender']
  | GetSurveyQuery['demographic']['income']
  | GetSurveyQuery['demographic']['education']
  | GetSurveyQuery['demographic']['age']
  | GetSurveyQuery['demographic']['children']
  | GetSurveyQuery['demographic']['location']
  | GetSurveyQuery['demographic']['nationality']
  | GetSurveyQuery['demographic']['seniority'];

export type SurveyDrilldownType = GetSurveyQuery['survey'][0]['drilldowns'][0];
export type SurveyDrilldownMaxDiffType = NonNullable<
  SurveyDrilldownType['maxDiffs']
>[0];
export type SurveyDrilldownMaxDiffBreakdownType = NonNullable<
  SurveyDrilldownMaxDiffType['breakdowns']
>[0];
export type SurveyDrilldownMaxDiffBreakdownResultType = NonNullable<
  SurveyDrilldownMaxDiffBreakdownType['results']
>;

const driversMap: Map<string, string> = new Map([
  ['industries', 'Industry'],
  ['genders', 'Gender'],
  ['senioritys', 'Seniority'],
  ['generations', 'Generation'],
  ['locations', 'Location'],
  ['workTypes', 'Work type'],
]);
export const ALL_DRIVER = 'All' as const;
export const TAL = 'talent-attraction-lab' as const;
export const TAL_DATA_LAB_SLUG = 'data-lab' as const;

const hasNoSurveyData = (surveyResults: GetSurveyQuery | undefined | null) =>
  !surveyResults ||
  !surveyResults.survey ||
  surveyResults.survey.length === 0 ||
  datalabHelper.hasNoSurveyDetailData(surveyResults.survey);

const hasNoSurveyDetailData = (survey: GetSurveyQuery['survey']) =>
  survey.every((item) =>
    item.drilldowns.every(
      (drilldownItem) =>
        !drilldownItem.maxDiffs ||
        drilldownItem.maxDiffs === null ||
        drilldownItem.maxDiffs.length === 0,
    ),
  );

const isSurveyResultLackOfSampleDataSize = (
  drilldown: SurveyDrilldownType,
): boolean => drilldown.indicative === true;

const isSurveyResultMissingSecondaryDriverOptionDiffData = (
  drilldown: SurveyDrilldownType,
): boolean => !drilldown.maxDiffs || drilldown.maxDiffs.length === 0;

const isSurveyDrilldownItemIncomplete = (
  survey: GetSurveyQuery['survey'],
): boolean =>
  survey.length === 0 ||
  survey[0].drilldowns.some(
    (item) => item.indicative || !item.maxDiffs || item.maxDiffs.length === 0,
  );

const getQueryFilterParams = (
  queryParams: URLSearchParams,
): DatalabQueryParams => ({
  primaryDriver: queryParams.get('primaryDriver') || ALL_DRIVER,
  primaryDriverOptions: queryParams.get('primaryDriverOptions')
    ? splitSearchParamString(queryParams.get('primaryDriverOptions') || '')
    : undefined,
  secondaryDriver: queryParams.get('secondaryDriver') || undefined,
  secondaryDriverOptions: queryParams.get('secondaryDriverOptions')
    ? splitSearchParamString(queryParams.get('secondaryDriverOptions') || '')
    : undefined,
});

const getDriverListFrom = (queryFilter: GetFilterFieldsQuery): DriverList => {
  const entries = Object.entries(queryFilter);
  const drivers = entries.map(
    ([key, values]) =>
      ({
        name: driversMap.get(key),
        options: values.map((value: DriverOption) => ({ name: value.name })),
      } as Driver),
  );

  return {
    drivers,
  };
};

interface SyncDriverList {
  primaryDrivers: DriverList;
  secondaryDrivers: DriverList;
  selectedPrimaryDriver: string;
}

const updateSecondaryDriverList = ({
  primaryDrivers,
  secondaryDrivers,
  selectedPrimaryDriver,
}: SyncDriverList) => {
  secondaryDrivers.drivers = [
    { name: 'null', options: [] },
    ...primaryDrivers.drivers.filter(
      (driver) =>
        driver.name !== ALL_DRIVER && driver.name !== selectedPrimaryDriver,
    ),
  ];
};

const isFormValueValidForPrimaryDriverQueryParam = (formValues: FormValues) =>
  formValues.primaryDriverSelect &&
  (formValues.primaryDriverSelect === ALL_DRIVER ||
    formValues.primaryDriverOptionsSelect.length > 0);

const isFormValueValidForPrimaryDriverOptionsQueryParam = (
  formValues: FormValues,
) =>
  formValues.primaryDriverSelect &&
  formValues.primaryDriverSelect !== ALL_DRIVER &&
  formValues.primaryDriverOptionsSelect.length > 0;

const isFormValueValidForSecondaryDriverQueryParam = (formValues: FormValues) =>
  formValues.secondaryDriverSelect &&
  formValues.secondaryDriverOptionsSelect.length > 0;

const generateUrlQueryParamFrom = (formValues: FormValues) => {
  const primayDriver = isFormValueValidForPrimaryDriverQueryParam(formValues)
    ? `?primaryDriver=${encodeURIComponent(formValues.primaryDriverSelect)}`
    : '';

  const primaryDriverOptions =
    isFormValueValidForPrimaryDriverOptionsQueryParam(formValues)
      ? `&primaryDriverOptions=${joinSearchParamString(
          formValues.primaryDriverOptionsSelect.map((option) =>
            encodeURIComponent(option),
          ),
        )}`
      : '';

  const secondaryDriver = isFormValueValidForSecondaryDriverQueryParam(
    formValues,
  )
    ? `&secondaryDriver=${encodeURIComponent(formValues.secondaryDriverSelect)}`
    : '';

  const secondaryDriverOptions = isFormValueValidForSecondaryDriverQueryParam(
    formValues,
  )
    ? `&secondaryDriverOptions=${joinSearchParamString(
        formValues.secondaryDriverOptionsSelect.map((option) =>
          encodeURIComponent(option),
        ),
      )}`
    : '';

  return `${primayDriver}${primaryDriverOptions}${secondaryDriver}${secondaryDriverOptions}`;
};

const generateDriverSummaryTitleFromFilteredDriverOptionsInQueryParam = (
  queryFilterParams: DatalabQueryParams,
): string =>
  (queryFilterParams.primaryDriverOptions || [])
    .concat(queryFilterParams.secondaryDriverOptions || [])
    .join(', ');

const generateDriverSummaryHeadingFrom = (
  filteredDriverOptions: string | undefined,
): {
  hasDriverOptions: boolean;
  title: 'Showing results that match' | 'Showing results from';
} => {
  const hasDriverOptions: boolean =
    filteredDriverOptions !== undefined &&
    filteredDriverOptions !== '' &&
    filteredDriverOptions !== ALL_DRIVER;
  const title = hasDriverOptions
    ? `Showing results that match`
    : `Showing results from`;
  return { hasDriverOptions, title };
};

const mapAndSortSurveyDemographicItemCandidateData = (
  demograhicItem: DemographicItem,
): CandidateData[] =>
  demograhicItem.results.map((item) => ({
    name: item.name,
    value: `${Math.round((item.count / demograhicItem.sum) * 100)}%`,
  }));

const mapSurveyDemographicItem = (
  demograhicItem: DemographicItem,
): CandidateCardProps => ({
  title: demograhicItem.name,
  titleIcon: demograhicItem.icon.replace('Icon', '') as Icon,
  data: datalabHelper.mapAndSortSurveyDemographicItemCandidateData(
    demograhicItem,
  ),
});

const mapAndSortAllSurveyDemographicItems = (
  demographic: GetSurveyQuery['demographic'],
): CandidateCardProps[] => [
  datalabHelper.mapSurveyDemographicItem(demographic.gender),
  datalabHelper.mapSurveyDemographicItem(demographic.age),

  datalabHelper.mapSurveyDemographicItem(demographic.location),
  datalabHelper.mapSurveyDemographicItem(demographic.seniority),

  datalabHelper.mapSurveyDemographicItem(demographic.income),
  datalabHelper.mapSurveyDemographicItem(demographic.education),

  datalabHelper.mapSurveyDemographicItem(demographic.nationality),
  datalabHelper.mapSurveyDemographicItem(demographic.children),
];

const mapDriverBreakdownOptions = (
  survey: GetSurveyQuery['survey'],
): DatalabDriverData => ({
  driverOptions: survey
    .map(
      (option) =>
        ({
          name: option.driverName,
          categories: datalabHelper.mapDriverBreakdownOptionCategories(
            option.drilldowns,
          ),
        } as PrimaryDriverOption),
    )
    .sort((a, b) => (a.name < b.name ? -1 : 1)),
});

const addToSubCategoryDrivers = (
  subCategoryDrivers: Map<string, DriverSubCategory[]>,
  maxDiff: SurveyDrilldownMaxDiffType,
  breakdown: SurveyDrilldownMaxDiffBreakdownType,
  subCategoryDriverOption: SubCategoryDriverOption,
) => {
  if (subCategoryDrivers.has(maxDiff.name)) {
    const foundCategory = subCategoryDrivers.get(maxDiff.name);

    const foundSubCategory = foundCategory?.find(
      (item) => item.name === breakdown.name,
    );
    if (foundSubCategory) {
      foundSubCategory.driverOptions.push(subCategoryDriverOption);
      foundSubCategory.driverOptions.sort((a, b) => (a.name < b.name ? -1 : 1));
    } else {
      const subCategory: DriverSubCategory = {
        name: breakdown.name,
        driverOptions: [subCategoryDriverOption],
      };
      subCategoryDrivers.get(maxDiff.name)?.push(subCategory);
    }
  } else {
    const subCategory: DriverSubCategory = {
      name: breakdown.name,
      driverOptions: [subCategoryDriverOption],
    };
    subCategoryDrivers.set(maxDiff.name, [subCategory]);
  }
};

// No need to sort for Driver Option Cateory since it's already sorted by category results
const mapDriverBreakdownOptionCategories = (
  drilldowns: SurveyDrilldownType[],
): DriverCategory[] => {
  const categoryDrivers: Map<string, CategoryDriverOption[]> = new Map();
  const subCategoryDrivers: Map<string, DriverSubCategory[]> = new Map();
  // Map to Secondary Driver Option (drilldown) to each Category (maxDiffs)
  drilldowns.forEach((drilldown) => {
    drilldown.maxDiffs?.forEach((maxDiff) => {
      const categoryDriverOption: CategoryDriverOption = {
        name: drilldown.drilldownName,
        totalRespondents: drilldown.respondents,
        value: maxDiff.result,
        percentage: (maxDiff.result / drilldown.respondents) * 100,
      };

      if (categoryDrivers.has(maxDiff.name)) {
        categoryDrivers.get(maxDiff.name)?.push(categoryDriverOption);
      } else {
        categoryDrivers.set(maxDiff.name, [categoryDriverOption]);
      }

      // ToDo: Refactor below dirty implementation
      // Map maxDiff's breakdowns to Sub Category
      maxDiff.breakdowns?.map((breakdown) => {
        const results = breakdown.results;

        if (results) {
          const subCategoryDriverOption: SubCategoryDriverOption | undefined =
            datalabMapper.mapToSubCategoryDriverOption(
              drilldown.drilldownName,
              results,
            );

          if (subCategoryDriverOption)
            datalabHelper.addToSubCategoryDrivers(
              subCategoryDrivers,
              maxDiff,
              breakdown,
              subCategoryDriverOption,
            );
        }
      });
    });
  });

  return Array.from(categoryDrivers.keys()).map(
    (category) =>
      ({
        name: category,
        // Sort category drivers by name
        driverOptions: categoryDrivers
          .get(category)
          ?.sort((a, b) => (a.name < b.name ? -1 : 1)),
        subCategories: subCategoryDrivers.get(category)
          ? subCategoryDrivers
              .get(category)
              ?.sort((a, b) => (a.name < b.name ? -1 : 1))
          : [],
      } as DriverCategory),
  );
};

const sortAllSecondaryDriverOptionsInPrimaryDriverOptions = (
  driverOptions: PrimaryDriverOption[],
  sortBy: string,
) => {
  driverOptions.forEach((option) => {
    option.categories.forEach((categoryOption) => {
      categoryOption.driverOptions.sort((a) => (a.name === sortBy ? -1 : 1));
      categoryOption.subCategories?.forEach((subCategory) => {
        subCategory.driverOptions.sort((a) => (a.name === sortBy ? -1 : 1));
      });
    });
  });
};

const getMaxResultPercentagePerPrimaryDriverOption = (
  driverOptions: PrimaryDriverOption[],
) => {
  const maxResultPerPrimaryDriverOption: Map<string, number> = new Map();
  driverOptions.forEach((option) => {
    if (option.categories && option.categories.length > 0) {
      const allCategoryOptions = option.categories.flatMap((category) =>
        category.driverOptions.map(
          (categoryOption) => categoryOption.percentage,
        ),
      );
      const maxResult: number =
        allCategoryOptions && allCategoryOptions.length > 0
          ? allCategoryOptions.reduce((prev, current) =>
              prev > current ? prev : current,
            )
          : 0;
      maxResultPerPrimaryDriverOption.set(option.name, maxResult);
    }
  });

  return maxResultPerPrimaryDriverOption;
};

const getMaxResultPercentageForGivenPrimaryDriverOptions = (
  driverOptions: PrimaryDriverOption[],
) =>
  driverOptions
    .flatMap((option) =>
      option.categories.flatMap((category) =>
        category.driverOptions.reduce(
          (prev, current) =>
            prev.percentage > current.percentage ? prev : current,
          { percentage: 0 },
        ),
      ),
    )
    .reduce(
      (prev, current) =>
        prev.percentage > current.percentage ? prev : current,
      { percentage: 0 },
    ).percentage | 0;

const filterOutPrimaryDriverOptionWithoutCategoryDriverOptions = (
  driverOptions: PrimaryDriverOption[],
) =>
  driverOptions.filter(
    (option) =>
      option.categories.filter(
        (categoryOption) =>
          !categoryOption.driverOptions ||
          categoryOption.driverOptions.length > 0,
      ).length > 0,
  );

const getSortedUniqueSurveyDrilldownNamesWithMaxDiffs = (
  surveyResults: GetSurveyQuery,
) =>
  Array.from(
    new Set(
      surveyResults.survey.flatMap((item) =>
        item.drilldowns
          .map((drilldown) =>
            drilldown.maxDiffs && drilldown.maxDiffs.length > 0
              ? drilldown.drilldownName
              : undefined,
          )
          .filter((drilldown) => drilldown !== undefined),
      ),
    ),
  ).sort((a, b) => (a < b ? -1 : 1));

const showDriverTitle = (driverOptions: PrimaryDriverOption[]) =>
  driverOptions.filter(
    (driverOption) =>
      driverOption.name.length > 0 && driverOption.name !== ALL_DRIVER,
  ).length > 1;

const querySubmitHandler = (formValues: FormValues) => {
  // Get the current URL path
  const currentPath = window.location.pathname;

  const newPath = currentPath.substring(
    0,
    currentPath.indexOf(TAL) + TAL.length,
  );

  const queryParams = datalabHelper.generateUrlQueryParamFrom(formValues);

  // Append the query parameters to the new path and navigate
  window.location.href = `${newPath}/${TAL_DATA_LAB_SLUG}${queryParams}`;
};

const getCategoryOptionsWitMissingData = (
  driverOptions: PrimaryDriverOption[],
) => [
  ...new Set(
    driverOptions.flatMap((driverOption) =>
      driverOption.secondaryDriverOptions
        .filter((item) => item.hasMissingData)
        .flatMap((item) => item.name),
    ),
  ),
];

const getAvailableSortCategoryOptions = (
  driverOptions: PrimaryDriverOption[],
) => {
  const categoryOptionsWitMissingData: string[] =
    datalabHelper.getCategoryOptionsWitMissingData(driverOptions);

  return [
    ...new Set(
      driverOptions.flatMap((driverOption) =>
        driverOption.secondaryDriverOptions.flatMap((item) => item.name),
      ),
    ),
  ].filter((item) => !categoryOptionsWitMissingData.includes(item));
};

const getAvailableCategoryOptions = (driverOptions: PrimaryDriverOption[]) => [
  ...new Set(
    driverOptions.flatMap((driverOption) =>
      driverOption.secondaryDriverOptions.flatMap((item) => item.name),
    ),
  ),
];

export const datalabHelper = {
  hasNoSurveyData,
  hasNoSurveyDetailData,
  isSurveyResultLackOfSampleDataSize,
  isSurveyResultMissingSecondaryDriverOptionDiffData,
  isSurveyDrilldownItemIncomplete,
  getDriverListFrom,
  getQueryFilterParams,
  updateSecondaryDriverList,
  generateUrlQueryParamFrom,
  generateDriverSummaryTitleFromFilteredDriverOptionsInQueryParam,
  generateDriverSummaryHeadingFrom,
  mapAndSortSurveyDemographicItemCandidateData,
  mapSurveyDemographicItem,
  mapAndSortAllSurveyDemographicItems,
  mapDriverBreakdownOptions,
  mapDriverBreakdownOptionCategories,
  sortAllSecondaryDriverOptionsInPrimaryDriverOptions,
  getMaxResultPercentagePerPrimaryDriverOption,
  getMaxResultPercentageForGivenPrimaryDriverOptions,
  filterOutPrimaryDriverOptionWithoutCategoryDriverOptions,
  getSortedUniqueSurveyDrilldownNamesWithMaxDiffs,
  showDriverTitle,
  addToSubCategoryDrivers,
  querySubmitHandler,
  getCategoryOptionsWitMissingData,
  getAvailableSortCategoryOptions,
  getAvailableCategoryOptions,
};
