import React, { useEffect } from 'react';
import { useRouteError } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';

export function ErrorBoundary() {
  const error = useRouteError();
  useEffect(() => {
    if (error) {
      datadogRum.addError(error);
    }
  }, [error]);

  return (
    <div>
      <h1>Oops!</h1>
      <p>{JSON.stringify(error)}</p>
    </div>
  );
}
