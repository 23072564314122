import React from 'react';
import escapeHtml from 'escape-html';
import type { ImageProps } from '@graphcms/rich-text-types';
import { logger } from '../utils/logger';

export function Image({
  src,
  width,
  height,
  altText,
  title,
}: Partial<ImageProps>) {
  if (!src) {
    logger.warn(
      `[@seek/cmsu-rich-text]: src is required. You need to include a \`url\` in your query`,
    );
  }

  const shouldIncludeWidth = width && width > 0;
  const shouldIncludeHeight = height && height > 0;

  return (
    <img
      loading="lazy"
      src={escapeHtml(src)}
      {...(shouldIncludeWidth && { width })}
      {...(shouldIncludeHeight && { height })}
      alt={altText}
      title={title}
    />
  );
}
