import React from 'react';
import { TestimonialCarousel } from './components/TestimonialCarousel/TestimonialCarousel';
import { ContentBlock } from 'braid-design-system';
import type { Testimonials } from './types';

export interface TestimonialsCarouselProps {
  testimonials: Testimonials;
}

export const TestimonialGroup = ({
  testimonials,
}: TestimonialsCarouselProps) => (
  <ContentBlock>
    <TestimonialCarousel testimonials={testimonials} />
  </ContentBlock>
);
