import type { NumericalBlockFragment } from '@seek/cmsu-cms-connect';
import { Box, Text } from 'braid-design-system';
import { useEffect, useRef, useState } from 'react';
// import { toBraidTextWeight } from '../../utils';
import { toAnimationSpeed } from '../../utils/toAnimationSpeed';
import { toBraidTextTone } from '../../utils/toTone';
import { toCustomFontSize } from '../../utils/toCustomFontSize';

type Props = {
  numericalBlock: NumericalBlockFragment;
};

export const NumericalBlock = ({ numericalBlock }: Props) => {
  const {
    number,
    unit,
    numberTone,
    unitTone,
    hasAnimation,
    textSize,
    animationSpeed,
    // textWeight,
    sameFontSize,
  } = numericalBlock;
  const [displayNumber, setDisplayNumber] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(entry.target); // Stop observing once it's in view
        }
      },
      { threshold: 0.2 }, // Trigger when 20% of the component is in view
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, []);

  useEffect(() => {
    if (
      (hasAnimation === false && hasAnimation === undefined) ||
      hasAnimation === null
    )
      return; // Don't animate if hasAnimation is false

    if (!isInView) return; // Don't animate if not in view

    const targetNumber = parseFloat(number ?? '0');
    const isInteger = Number.isInteger(targetNumber); // To skip decimal places if it's an integer
    let start = 0;

    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = Math.min(
        (timestamp - start) / toAnimationSpeed(animationSpeed ?? 'slow'),
        1,
      );
      const currentNumber = targetNumber * progress;

      setDisplayNumber(
        isInteger
          ? Math.round(currentNumber)
          : parseFloat(currentNumber.toFixed(1)),
      );

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  }, [animationSpeed, hasAnimation, isInView, number]);

  return (
    <Box
      display="flex"
      alignItems="flexEnd"
      justifyContent={'center'}
      gap={'xxsmall'}
      ref={ref}
    >
      <Text
        tone={toBraidTextTone(numberTone)}
        // weight={toBraidTextWeight(textWeight)}
        weight="medium"
      >
        <span
          style={{
            fontSize: `${toCustomFontSize(textSize)}rem`,
            lineHeight: sameFontSize ? 'auto' : '0.92', // Adjust line height for smaller font sizes '0.92' for perfectly aligned text
          }}
        >
          {displayNumber}
        </span>
      </Text>
      <Text
        tone={toBraidTextTone(unitTone)}
        // weight={toBraidTextWeight(textWeight)}
        weight="medium"
      >
        <span
          style={{
            fontSize: `${
              sameFontSize
                ? toCustomFontSize(textSize)
                : toCustomFontSize(textSize) / 3
            }rem`,
          }}
        >
          {unit}
        </span>
      </Text>
    </Box>
  );
};
