import React from 'react';
import type { SvgLogoProps } from './types';

export const jobStreetLogoSGDefaultHeight = 30;
export const JobstreetLogoSG = ({
  className,
  height = jobStreetLogoSGDefaultHeight,
}: SvgLogoProps) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 334.72 65.96"
    height={height}
    className={className}
  >
    <defs>
      <style>
        {`
          .cls-1, .cls-2, .cls-3 {
            stroke - width: 0px;
      }

          .cls-2 {
            fill: #fff;
      }

          .cls-3 {
            fill: #0d3880;
      }
      `}
      </style>
    </defs>
    <path
      className="cls-3"
      d="m0,32.98C0,14.76,14.77,0,32.98,0s32.98,14.76,32.98,32.98-14.76,32.98-32.98,32.98S0,51.2,0,32.98"
    />
    <path
      className="cls-2"
      d="m33.76,12.58c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m40.18,19.51c0-1.26,1.02-2.28,2.27-2.28s2.28,1.02,2.28,2.28-1.02,2.27-2.28,2.27-2.27-1.02-2.27-2.27"
    />
    <path
      className="cls-2"
      d="m33.76,19.51c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.93-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m47,26.46c0-1.41,1.14-2.55,2.55-2.55s2.55,1.14,2.55,2.55-1.14,2.55-2.55,2.55-2.55-1.14-2.55-2.55"
    />
    <path
      className="cls-2"
      d="m40.18,26.44c0-1.26,1.02-2.27,2.27-2.27s2.28,1.02,2.28,2.27-1.02,2.27-2.28,2.27-2.27-1.02-2.27-2.27"
    />
    <path
      className="cls-2"
      d="m33.76,26.44c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m27.64,26.44c0-1,.81-1.8,1.8-1.8s1.81.81,1.81,1.8-.81,1.8-1.81,1.8-1.8-.81-1.8-1.8"
    />
    <path
      className="cls-2"
      d="m22.53,26.44c0-.85.69-1.55,1.54-1.55s1.54.69,1.54,1.55-.69,1.55-1.54,1.55-1.54-.69-1.54-1.55"
    />
    <path
      className="cls-2"
      d="m17.66,26.44c0-.71.58-1.29,1.29-1.29s1.29.58,1.29,1.29-.57,1.29-1.29,1.29-1.29-.58-1.29-1.29"
    />
    <path
      className="cls-2"
      d="m13.53,26.44c0-.57.46-1.03,1.03-1.03s1.03.46,1.03,1.03-.46,1.03-1.03,1.03-1.03-.46-1.03-1.03"
    />
    <path
      className="cls-2"
      d="m9.63,26.44c0-.43.34-.77.77-.77s.77.35.77.77-.34.77-.77.77-.77-.35-.77-.77"
    />
    <path
      className="cls-2"
      d="m6.33,26.44c0-.29.23-.51.52-.51s.51.23.51.51-.23.52-.51.52-.52-.23-.52-.52"
    />
    <path
      className="cls-2"
      d="m47,33.39c0-1.41,1.14-2.55,2.55-2.55s2.55,1.15,2.55,2.55-1.14,2.55-2.55,2.55-2.55-1.14-2.55-2.55"
    />
    <path
      className="cls-2"
      d="m40.18,33.37c0-1.26,1.02-2.27,2.27-2.27s2.28,1.02,2.28,2.27-1.02,2.28-2.28,2.28-2.27-1.02-2.27-2.28"
    />
    <path
      className="cls-2"
      d="m33.76,33.37c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m27.64,33.37c0-1,.81-1.8,1.8-1.8s1.81.81,1.81,1.8-.81,1.8-1.81,1.8-1.8-.81-1.8-1.8"
    />
    <path
      className="cls-2"
      d="m22.53,33.37c0-.85.69-1.55,1.54-1.55s1.54.69,1.54,1.55-.69,1.55-1.54,1.55-1.54-.69-1.54-1.55"
    />
    <path
      className="cls-2"
      d="m17.66,33.37c0-.71.58-1.29,1.29-1.29s1.29.57,1.29,1.29-.57,1.29-1.29,1.29-1.29-.58-1.29-1.29"
    />
    <path
      className="cls-2"
      d="m13.53,33.37c0-.57.46-1.03,1.03-1.03s1.03.46,1.03,1.03-.46,1.03-1.03,1.03-1.03-.46-1.03-1.03"
    />
    <path
      className="cls-2"
      d="m9.63,33.37c0-.43.34-.77.77-.77s.77.35.77.77-.34.77-.77.77-.77-.34-.77-.77"
    />
    <path
      className="cls-2"
      d="m6.33,33.37c0-.29.23-.52.52-.52s.51.23.51.52-.23.52-.51.52-.52-.23-.52-.52"
    />
    <path
      className="cls-2"
      d="m54.01,33.44c0-1.55,1.26-2.8,2.8-2.8s2.8,1.25,2.8,2.8-1.25,2.8-2.8,2.8-2.8-1.25-2.8-2.8"
    />
    <path
      className="cls-2"
      d="m47,40.32c0-1.41,1.14-2.55,2.55-2.55s2.55,1.14,2.55,2.55-1.14,2.55-2.55,2.55-2.55-1.14-2.55-2.55"
    />
    <path
      className="cls-2"
      d="m40.18,40.3c0-1.26,1.02-2.28,2.27-2.28s2.28,1.02,2.28,2.28-1.02,2.27-2.28,2.27-2.27-1.02-2.27-2.27"
    />
    <path
      className="cls-2"
      d="m33.76,40.3c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m27.64,40.3c0-1,.81-1.81,1.8-1.81s1.81.81,1.81,1.81-.81,1.8-1.81,1.8-1.8-.8-1.8-1.8"
    />
    <path
      className="cls-2"
      d="m22.53,40.3c0-.86.69-1.55,1.54-1.55s1.54.69,1.54,1.55-.69,1.54-1.54,1.54-1.54-.69-1.54-1.54"
    />
    <path
      className="cls-2"
      d="m17.66,40.3c0-.72.58-1.29,1.29-1.29s1.29.57,1.29,1.29-.57,1.29-1.29,1.29-1.29-.58-1.29-1.29"
    />
    <path
      className="cls-2"
      d="m13.53,40.3c0-.57.46-1.03,1.03-1.03s1.03.46,1.03,1.03-.46,1.03-1.03,1.03-1.03-.46-1.03-1.03"
    />
    <path
      className="cls-2"
      d="m9.63,40.3c0-.43.34-.78.77-.78s.77.35.77.78-.34.77-.77.77-.77-.35-.77-.77"
    />
    <path
      className="cls-2"
      d="m6.33,40.3c0-.29.23-.52.52-.52s.51.23.51.52-.23.51-.51.51-.52-.23-.52-.51"
    />
    <path
      className="cls-2"
      d="m40.18,47.23c0-1.26,1.02-2.28,2.27-2.28s2.28,1.02,2.28,2.28-1.02,2.27-2.28,2.27-2.27-1.02-2.27-2.27"
    />
    <path
      className="cls-2"
      d="m33.76,47.23c0-1.14.92-2.07,2.06-2.07s2.06.93,2.06,2.07-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-2"
      d="m33.76,54.16c0-1.14.92-2.06,2.06-2.06s2.06.92,2.06,2.06-.92,2.06-2.06,2.06-2.06-.92-2.06-2.06"
    />
    <path
      className="cls-1"
      d="m82.79,17.04h-5.98v-4.84h5.98v4.84Zm0,29.92c0,1.86-.55,3.41-1.64,4.66-1.25,1.43-3.01,2.15-5.3,2.15h-3.38v-5.02h2.26c1.39,0,2.08-.72,2.08-2.15v-25.53h5.98v25.9Z"
    />
    <path
      className="cls-1"
      d="m100.97,32.94c0-2.92-.45-4.84-1.36-5.76-.69-.7-1.61-1.05-2.76-1.05s-2.02.35-2.71,1.05c-.9.91-1.36,2.83-1.36,5.76s.45,4.89,1.36,5.8c.69.7,1.6,1.05,2.71,1.05s2.06-.35,2.76-1.05c.9-.91,1.36-2.85,1.36-5.8m5.98,0c0,2.28-.18,4.1-.55,5.44-.4,1.49-1.11,2.77-2.15,3.84-1.86,1.95-4.32,2.92-7.4,2.92s-5.5-.97-7.35-2.92c-1.04-1.07-1.75-2.34-2.15-3.84-.37-1.34-.55-3.15-.55-5.44,0-4.26.91-7.35,2.74-9.27,1.83-1.92,4.26-2.88,7.31-2.88s5.53.96,7.35,2.88c1.83,1.92,2.74,5.01,2.74,9.27"
    />
    <path
      className="cls-1"
      d="m124.99,32.94c0-2.1-.17-3.61-.5-4.52-.6-1.52-1.76-2.28-3.48-2.28s-2.88.76-3.48,2.28c-.33.91-.5,2.42-.5,4.52s.17,3.61.5,4.52c.6,1.55,1.76,2.33,3.48,2.33s2.88-.78,3.48-2.33c.33-.91.5-2.42.5-4.52m5.98,0c0,2.44-.11,4.26-.32,5.48-.34,1.98-1.04,3.5-2.1,4.57-1.43,1.43-3.37,2.15-5.8,2.15s-4.42-.84-5.94-2.51v2.24h-5.76V12.34h5.98v10.83c1.43-1.58,3.34-2.38,5.74-2.38s4.35.72,5.78,2.15c1.06,1.07,1.76,2.59,2.09,4.57.21,1.22.32,3.03.32,5.44"
    />
    <path
      className="cls-1"
      d="m153.04,37.37c0,2.53-.98,4.48-2.92,5.85-1.83,1.28-4.22,1.92-7.17,1.92-2.22,0-4.04-.2-5.44-.59-1.77-.52-3.33-1.46-4.7-2.83l3.88-3.88c1.49,1.49,3.61,2.24,6.35,2.24s4.2-.82,4.2-2.47c0-1.31-.84-2.04-2.51-2.19l-3.75-.37c-4.63-.46-6.94-2.68-6.94-6.67,0-2.38.93-4.26,2.79-5.66,1.71-1.28,3.84-1.92,6.4-1.92,4.08,0,7.11.93,9.09,2.79l-3.65,3.7c-1.19-1.07-3.03-1.6-5.53-1.6-2.25,0-3.38.76-3.38,2.28,0,1.22.82,1.9,2.47,2.06l3.75.37c4.72.46,7.08,2.79,7.08,6.99"
    />
    <path
      className="cls-1"
      d="m167.16,44.86h-3.24c-2.25,0-4-.71-5.25-2.15-1.1-1.25-1.64-2.8-1.64-4.66v-11.79h-2.51v-4.52h2.51v-7.03h5.98v7.03h4.16v4.52h-4.16v11.42c0,1.43.68,2.15,2.03,2.15h2.12v5.02Z"
    />
    <path
      className="cls-1"
      d="m188.35,23.02l-4.48,4.52c-.94-.94-2-1.42-3.15-1.42-1.01,0-1.87.35-2.6,1.05-.82.82-1.23,1.93-1.23,3.33v14.34h-5.94v-23.8h5.8v2.28c1.43-1.7,3.43-2.56,5.98-2.56,2.25,0,4.13.75,5.62,2.24"
    />
    <path
      className="cls-1"
      d="m203.88,30.74c-.03-.97-.21-1.83-.55-2.56-.73-1.64-2.06-2.47-3.97-2.47s-3.24.82-3.97,2.47c-.34.73-.52,1.58-.55,2.56h9.04Zm5.85,4.07h-14.89c0,1.58.46,2.86,1.39,3.84.93.97,2.2,1.46,3.81,1.46,2.1,0,3.9-.75,5.39-2.24l3.61,3.52c-1.31,1.31-2.59,2.24-3.84,2.79-1.43.64-3.17.96-5.21.96-7.34,0-11.01-4.07-11.01-12.2,0-3.81.96-6.81,2.88-9,1.86-2.1,4.35-3.15,7.49-3.15s5.79,1.08,7.67,3.24c1.8,2.07,2.7,4.78,2.7,8.13v2.65Z"
    />
    <path
      className="cls-1"
      d="m227.36,30.74c-.03-.97-.21-1.83-.55-2.56-.73-1.64-2.06-2.47-3.97-2.47s-3.24.82-3.97,2.47c-.34.73-.52,1.58-.55,2.56h9.04Zm5.85,4.07h-14.89c0,1.58.46,2.86,1.39,3.84.93.97,2.2,1.46,3.81,1.46,2.1,0,3.9-.75,5.39-2.24l3.61,3.52c-1.31,1.31-2.59,2.24-3.84,2.79-1.43.64-3.17.96-5.21.96-7.34,0-11.01-4.07-11.01-12.2,0-3.81.96-6.81,2.88-9,1.86-2.1,4.35-3.15,7.49-3.15s5.79,1.08,7.67,3.24c1.8,2.07,2.69,4.78,2.69,8.13v2.65Z"
    />
    <path
      className="cls-1"
      d="m247.87,44.86h-3.24c-2.25,0-4-.71-5.25-2.15-1.1-1.25-1.64-2.8-1.64-4.66v-11.79h-2.51v-4.52h2.51v-7.03h5.98v7.03h4.16v4.52h-4.16v11.42c0,1.43.68,2.15,2.03,2.15h2.12v5.02Z"
    />
    <path
      className="cls-1"
      d="m267.33,38.68c0-1.09-.09-1.87-.26-2.34-.31-.79-.91-1.18-1.8-1.18s-1.49.39-1.8,1.18c-.17.47-.26,1.25-.26,2.34s.09,1.87.26,2.34c.31.8.91,1.21,1.8,1.21s1.49-.4,1.8-1.21c.17-.47.26-1.25.26-2.34m3.1,0c0,1.26-.06,2.21-.17,2.84-.17,1.03-.54,1.82-1.09,2.37-.74.74-1.74,1.11-3.01,1.11s-2.29-.43-3.08-1.3v1.16h-2.98v-16.85h3.1v5.61c.74-.82,1.73-1.23,2.97-1.23s2.26.37,3,1.11c.55.55.91,1.34,1.09,2.37.11.63.17,1.57.17,2.82"
    />
    <path
      className="cls-1"
      d="m282.42,32.53l-5.28,14.32c-.25.69-.56,1.22-.92,1.59-.63.63-1.52.95-2.65.95h-1.21v-2.77h.71c.46,0,.79-.08,1.01-.24.21-.16.39-.45.53-.88l.52-1.52-4.21-11.45h3.24l2.56,7.62,2.46-7.62h3.24Z"
    />
    <path
      className="cls-1"
      d="m297.43,40.98c0,1.31-.51,2.32-1.51,3.03-.95.66-2.19.99-3.72.99-1.15,0-2.09-.1-2.82-.31-.92-.27-1.73-.76-2.44-1.47l2.01-2.01c.77.77,1.87,1.16,3.29,1.16s2.18-.43,2.18-1.28c0-.68-.43-1.06-1.3-1.14l-1.94-.19c-2.4-.24-3.6-1.39-3.6-3.46,0-1.23.48-2.21,1.44-2.93.88-.66,1.99-.99,3.31-.99,2.11,0,3.68.48,4.71,1.44l-1.89,1.92c-.62-.55-1.57-.83-2.86-.83-1.17,0-1.75.39-1.75,1.18,0,.63.43.99,1.28,1.06l1.94.19c2.45.24,3.67,1.44,3.67,3.62"
    />
    <path
      className="cls-1"
      d="m306.28,37.54c-.02-.5-.11-.95-.28-1.33-.38-.85-1.06-1.28-2.06-1.28s-1.68.43-2.06,1.28c-.17.38-.27.82-.28,1.33h4.69Zm3.03,2.11h-7.72c0,.82.24,1.48.72,1.99.48.51,1.14.76,1.98.76,1.09,0,2.02-.39,2.79-1.16l1.87,1.82c-.68.68-1.34,1.16-1.99,1.44-.74.33-1.64.5-2.7.5-3.8,0-5.7-2.11-5.7-6.32,0-1.97.5-3.53,1.49-4.66.96-1.09,2.26-1.63,3.88-1.63s3,.56,3.98,1.68c.93,1.07,1.4,2.48,1.4,4.21v1.37Z"
    />
    <path
      className="cls-1"
      d="m318.44,37.54c-.02-.5-.11-.95-.28-1.33-.38-.85-1.06-1.28-2.06-1.28s-1.68.43-2.06,1.28c-.17.38-.27.82-.28,1.33h4.69Zm3.03,2.11h-7.72c0,.82.24,1.48.72,1.99.48.51,1.14.76,1.98.76,1.09,0,2.02-.39,2.79-1.16l1.87,1.82c-.68.68-1.34,1.16-1.99,1.44-.74.33-1.64.5-2.7.5-3.8,0-5.7-2.11-5.7-6.32,0-1.97.5-3.53,1.49-4.66.96-1.09,2.26-1.63,3.88-1.63s3,.56,3.98,1.68c.93,1.07,1.4,2.48,1.4,4.21v1.37Z"
    />
    <polygon
      className="cls-1"
      points="334.72 44.86 330.93 44.86 327.91 39.72 326.61 41.19 326.61 44.86 323.51 44.86 323.51 28.01 326.61 28.01 326.61 37.57 330.69 32.53 334.39 32.53 329.99 37.5 334.72 44.86"
    />
  </svg>
);
