import { Columns, Column, Box, Text } from 'braid-design-system';
import React from 'react';
import {
  VerticalDivider,
  VerticalDividerText,
  VerticalDividerWrapper,
  VerticalLine,
} from './styles.css';
import { Asset, AssetType } from '../Asset/Asset';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import type {
  DividerFragment,
  InlineDividerFragment,
} from '@seek/cmsu-cms-connect';

type Props = Pick<DividerFragment | InlineDividerFragment, 'image'> & {
  actions: DividerFragment['actionGroup'] | InlineDividerFragment;
};

export const BannerDivider = ({ image, actions }: Props) => (
  <Columns space="medium">
    <Column>
      <Box display="flex" height="full" justifyContent="center">
        <Asset {...image} assetType={AssetType.DEFAULT_IMAGE} />
      </Box>
    </Column>
    <Column width="content">
      <Box className={VerticalDividerWrapper}>
        <Box className={VerticalDivider}>
          <Box className={VerticalLine} />
          <Box className={VerticalDividerText}>
            <Text tone="neutral">OR</Text>
          </Box>
          <Box className={VerticalLine} />
        </Box>
      </Box>
    </Column>
    <Column>
      <Box
        display="flex"
        height="full"
        justifyContent="center"
        alignItems="center"
      >
        <ActionGroup {...actions} />
      </Box>
    </Column>
  </Columns>
);
