import type { ProfileBioFragment } from '@seek/cmsu-cms-connect';
import {
  Box,
  Column,
  Columns,
  Heading,
  IconChevron,
  Secondary,
  Stack,
  Text,
  TextLinkButton,
} from 'braid-design-system';
import { Asset, AssetType } from '../Asset/Asset';
import translations from '../../.vocab';
import { useTranslations } from '@vocab/react';
import { useState } from 'react';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Paragraph } from '../Paragraph/Paragraph';
import { richTextHelper } from '@seek/cmsu-rich-text/src/utils/richTextHelper';

const DESKTOP_MAX_CHAR_LENGTH = 350;
const MOBILE_MAX_CHAR_LENGTH = 175;

const shortenDescription = (desc: string, maxLength: number) => {
  if (desc.length < maxLength) {
    return desc;
  }

  const truncated = desc.slice(0, maxLength - 3);
  return `${truncated}...`;
};

type Props = ProfileBioFragment;

export const ProfileBio = ({ image, name, roleTitle, description }: Props) => {
  const { t } = useTranslations(translations);
  const { isMobile, isTablet } = useScreenSize();

  return (
    <Box
      paddingX={{
        mobile: 'none',
        tablet: 'xsmall',
        desktop: 'xsmall',
        wide: 'xsmall',
      }}
      paddingY="small"
    >
      <Columns
        space={{
          mobile: 'xsmall',
          tablet: 'xsmall',
          desktop: 'medium',
          wide: 'medium',
        }}
        collapseBelow="desktop"
      >
        <Column width="content">
          <ProfileBioImage
            image={image}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </Column>
        <Column>
          <Box paddingY="xsmall">
            <Stack space="medium">
              <ProfileBioNameRole name={name} roleTitle={roleTitle} />
              <ProfileBioDescription
                description={description}
                isMobile={isMobile}
                t={t}
              />
            </Stack>
          </Box>
        </Column>
      </Columns>
    </Box>
  );
};

type ProfileBioImageProps = Pick<ProfileBioFragment, 'image'> & {
  isMobile: boolean | null;
  isTablet: boolean | null;
};

const ProfileBioImage = ({
  image,
  isMobile,
  isTablet,
}: ProfileBioImageProps) => (
  <>
    {image && (
      <Asset
        assetType={AssetType.DEFAULT_IMAGE}
        url={image.url}
        alt={image.alt}
        style={{
          borderRadius: '10px',
          maxHeight: isMobile || isTablet ? '150px' : '250px',
          maxWidth: isMobile || isTablet ? '150px' : '250px',
        }}
      />
    )}
  </>
);

type ProfileBioNameRoleProps = Pick<ProfileBioFragment, 'name' | 'roleTitle'>;

const ProfileBioNameRole = ({ name, roleTitle }: ProfileBioNameRoleProps) => (
  <Stack space="xsmall">
    <Heading level="3">{name}</Heading>
    <Text weight="strong">
      <Secondary>{roleTitle}</Secondary>
    </Text>
  </Stack>
);

type ProfileBioDescriptionProps = Pick<ProfileBioFragment, 'description'> & {
  isMobile: boolean | null;
  t: any;
};

const ProfileBioDescription = ({
  description,
  isMobile,
  t,
}: ProfileBioDescriptionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpand = () => setIsExpanded(!isExpanded);

  return (
    <Stack space="large">
      <Description
        description={description}
        isMobile={isMobile}
        isExpanded={isExpanded}
      />
      <ExpandButton
        description={description}
        isMobile={isMobile}
        isExpanded={isExpanded}
        handleExpand={handleExpand}
        t={t}
      />
    </Stack>
  );
};

type DescriptionProps = Pick<ProfileBioFragment, 'description'> & {
  isMobile: boolean | null;
  isExpanded: boolean;
};

const Description = ({
  description,
  isMobile,
  isExpanded,
}: DescriptionProps) => {
  const cleanDesc = richTextHelper.getTextWithoutFormatting(description);
  const characterLength = isMobile
    ? MOBILE_MAX_CHAR_LENGTH
    : DESKTOP_MAX_CHAR_LENGTH;

  return (
    <>
      {description && cleanDesc && (
        <>
          {isExpanded || cleanDesc.length < characterLength ? (
            <Paragraph content={description.raw} />
          ) : (
            <Text>{shortenDescription(cleanDesc, characterLength)}</Text>
          )}
        </>
      )}
    </>
  );
};

type ExpandButtonProps = Pick<ProfileBioFragment, 'description'> & {
  isMobile: boolean | null;
  isExpanded: boolean;
  handleExpand: () => void;
  t: any;
};

const ExpandButton = ({
  description,
  isMobile,
  isExpanded,
  handleExpand,
  t,
}: ExpandButtonProps) => (
  <>
    {description &&
      description.text.length >
        (isMobile ? MOBILE_MAX_CHAR_LENGTH : DESKTOP_MAX_CHAR_LENGTH) && (
        <Box display="inlineBlock">
          <Text>
            <TextLinkButton
              weight="regular"
              onClick={handleExpand}
              iconPosition="trailing"
              icon={
                isExpanded ? (
                  <IconChevron direction="up" alignY="lowercase" />
                ) : (
                  <IconChevron direction="down" alignY="lowercase" />
                )
              }
            >
              {isExpanded ? t('Show less') : t('Show more')}
            </TextLinkButton>
          </Text>
        </Box>
      )}
  </>
);
