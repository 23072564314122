import { useMemo } from 'react';
import type { DriverList } from '../components/QueryFilter';
import { ALL_DRIVER } from '../datalabHelper';
import { useQueryFilterBannerFormFields } from './useQueryFilterBannerFormFields';

export const useGenerateSingleDriverList = (driverList: DriverList) => {
  const { primaryDriverSelect } = useQueryFilterBannerFormFields();

  return {
    primaryDriverList: useMemo(
      () => ({
        drivers: [{ name: ALL_DRIVER, options: [] }, ...driverList.drivers],
      }),
      [driverList],
    ),
    primaryOptionsList: driverList.drivers
      .find((d) => d.name === primaryDriverSelect.value)
      ?.options.map((option) => ({ value: option.name, label: option.name })),
  };
};
