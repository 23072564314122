import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import type { SelectedQuestion } from './APAC_Builder';
import SeekLogoInterviewBuilderLogo from './assets/seek-logo-interview-builder.png';
import YourInterviewHeader from './assets/your-interview-header.jpg';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 18,
  },
  image: {
    width: '100%',
  },
  section: {
    height: 100,
  },
  heading: {
    backgroundColor: '#0d3880',
    padding: '4 8',
    marginTop: 24,
  },
  question: {
    fontSize: 11,
    fontWeight: 700,
    color: '#ffffff',
  },
  pageNumber: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    fontSize: 11,
    bottom: 30,
    left: 18,
    right: 18,
  },
  logo: {
    height: 36,
    flexGrow: 0,
    flexShrink: 0,
  },
});
interface InterviewPDFProps {
  activeList: SelectedQuestion[];
}

export const InterviewPDF = ({ activeList }: InterviewPDFProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <Image src={YourInterviewHeader} style={styles.image} />
      </View>
      {activeList.map((item) => (
        <View key={item.question.slice(0, 40)} wrap={false}>
          <View style={styles.heading}>
            <Text style={styles.question}>{item.question}</Text>
          </View>
          <View style={styles.section} />
        </View>
      ))}

      <View style={styles.pageNumber} fixed={true}>
        <Text render={({ pageNumber }) => `Page: ${pageNumber}`} fixed={true} />
        <Image src={SeekLogoInterviewBuilderLogo} style={styles.logo} />
      </View>
    </Page>
  </Document>
);
