import type { Heading as BraidHeading } from 'braid-design-system';

type BraidLevel = React.ComponentProps<typeof BraidHeading>['level'];

/**
 * Convert a number into a Braid level value.
 * This is needed because Braid with throw a type error if we try to pass a number.
 */
export const toLevel = (
  num?: number,
  fallback: BraidLevel = '2',
): BraidLevel => {
  const value = ['1', '2', '3', '4'] as const;
  return value.find((val) => val === String(num)) || fallback;
};
