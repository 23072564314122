import 'braid-design-system/reset';
import React from 'react';
import escapeHtml from 'escape-html';
import type { LinkElement } from '@graphcms/rich-text-types';
import type { LinkRendererProps } from '../types';
import { useTrackLink } from '@seek/cmsu-analytics';
import { TextLink } from 'braid-design-system';

export function Link({ children, ...rest }: LinkRendererProps) {
  const { href, rel, id, title, openInNewTab, className } = rest;

  const props: Pick<LinkElement, 'rel' | 'id' | 'title' | 'className'> & {
    target?: string;
  } = {};

  const onClickLink = useTrackLink(id);

  if (rel) props.rel = rel;
  if (id) props.id = id;
  if (title) props.title = title;
  if (className) props.className = className;
  if (openInNewTab) props.target = '_blank';

  return (
    <TextLink
      onClick={() => (onClickLink ? onClickLink() : undefined)}
      href={escapeHtml(href)}
      {...props}
    >
      {children}
    </TextLink>
  );
}
