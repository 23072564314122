import React from 'react';

export const SeekAnzLogoBlueSvg = () => (
  <svg
    width="83"
    height="32"
    viewBox="0 0 83 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.515625 16.0321C0.515625 7.16463 7.68026 0 16.5156 0C25.3509 0 32.5155 7.16463 32.5155 15.9999C32.5155 24.8353 25.3509 31.9999 16.5156 31.9999C7.68026 32.032 0.515625 24.8674 0.515625 16.0321Z"
      fill="#233D7C"
    />
    <path
      d="M16.9004 6.10536C16.9004 5.55917 17.3502 5.10938 17.8964 5.10938C18.4426 5.10938 18.8924 5.55917 18.8924 6.10536C18.8924 6.65154 18.4426 7.10134 17.8964 7.10134C17.3502 7.10134 16.9004 6.65154 16.9004 6.10536Z"
      fill="white"
    />
    <path
      d="M20.0176 9.47713C20.0176 8.86669 20.4995 8.38477 21.1099 8.38477C21.7204 8.38477 22.2023 8.86669 22.2023 9.47713C22.2023 10.0876 21.7204 10.5695 21.1099 10.5695C20.5316 10.5695 20.0176 10.0876 20.0176 9.47713Z"
      fill="white"
    />
    <path
      d="M16.9004 9.4784C16.9004 8.93222 17.3502 8.48242 17.8964 8.48242C18.4426 8.48242 18.8924 8.93222 18.8924 9.4784C18.8924 10.0246 18.4426 10.4744 17.8964 10.4744C17.3502 10.4744 16.9004 10.0246 16.9004 9.4784Z"
      fill="white"
    />
    <path
      d="M23.3262 12.8507C23.3262 12.176 23.8724 11.5977 24.5792 11.5977C25.2539 11.5977 25.8322 12.1438 25.8322 12.8507C25.8322 13.5254 25.286 14.1037 24.5792 14.1037C23.9045 14.1037 23.3262 13.5254 23.3262 12.8507Z"
      fill="white"
    />
    <path
      d="M20.0176 12.8521C20.0176 12.2417 20.4995 11.7598 21.1099 11.7598C21.7204 11.7598 22.2023 12.2417 22.2023 12.8521C22.2023 13.4626 21.7204 13.9445 21.1099 13.9445C20.5316 13.9445 20.0176 13.4626 20.0176 12.8521Z"
      fill="white"
    />
    <path
      d="M16.9004 12.8514C16.9004 12.3053 17.3502 11.8555 17.8964 11.8555C18.4426 11.8555 18.8924 12.3053 18.8924 12.8514C18.8924 13.3976 18.4426 13.8474 17.8964 13.8474C17.3502 13.8474 16.9004 13.3976 16.9004 12.8514Z"
      fill="white"
    />
    <path
      d="M13.9453 12.8518C13.9453 12.3699 14.3309 11.9844 14.8128 11.9844C15.2947 11.9844 15.6802 12.3699 15.6802 12.8518C15.6802 13.3338 15.2947 13.7193 14.8128 13.7193C14.3309 13.7193 13.9453 13.3338 13.9453 12.8518Z"
      fill="white"
    />
    <path
      d="M11.4395 12.8522C11.4395 12.4346 11.7607 12.1133 12.1784 12.1133C12.5961 12.1133 12.9174 12.4346 12.9174 12.8522C12.9174 13.2699 12.5961 13.5912 12.1784 13.5912C11.7929 13.5912 11.4395 13.2699 11.4395 12.8522Z"
      fill="white"
    />
    <path
      d="M9.09375 12.8507C9.09375 12.4973 9.38291 12.2402 9.70419 12.2402C10.0576 12.2402 10.3146 12.5294 10.3146 12.8507C10.3146 13.2041 10.0255 13.4932 9.70419 13.4932C9.38291 13.4611 9.09375 13.172 9.09375 12.8507Z"
      fill="white"
    />
    <path
      d="M7.10156 12.8519C7.10156 12.5628 7.32646 12.3379 7.61562 12.3379C7.90477 12.3379 8.12967 12.5628 8.12967 12.8519C8.12967 13.1411 7.90477 13.366 7.61562 13.366C7.32646 13.3339 7.10156 13.109 7.10156 12.8519Z"
      fill="white"
    />
    <path
      d="M5.20703 12.8523C5.20703 12.6596 5.36767 12.4668 5.59257 12.4668C5.78534 12.4668 5.97811 12.6274 5.97811 12.8523C5.97811 13.0451 5.81747 13.2379 5.59257 13.2379C5.36767 13.2058 5.20703 13.0451 5.20703 12.8523Z"
      fill="white"
    />
    <path
      d="M3.59961 12.8508C3.59961 12.7223 3.72812 12.5938 3.85664 12.5938C3.98515 12.5938 4.11366 12.6901 4.11366 12.8508C4.11366 12.9793 4.01728 13.1078 3.85664 13.1078C3.69599 13.1078 3.59961 12.9793 3.59961 12.8508Z"
      fill="white"
    />
    <path
      d="M23.3262 16.2257C23.3262 15.551 23.8724 14.9727 24.5792 14.9727C25.2539 14.9727 25.8322 15.5188 25.8322 16.2257C25.8322 16.9004 25.286 17.4787 24.5792 17.4787C23.9045 17.4465 23.3262 16.9004 23.3262 16.2257Z"
      fill="white"
    />
    <path
      d="M20.0176 16.192C20.0176 15.5815 20.4995 15.0996 21.1099 15.0996C21.7204 15.0996 22.2023 15.5815 22.2023 16.192C22.2023 16.8024 21.7204 17.2843 21.1099 17.2843C20.5316 17.3165 20.0176 16.8024 20.0176 16.192Z"
      fill="white"
    />
    <path
      d="M16.9004 16.1932C16.9004 15.6471 17.3502 15.1973 17.8964 15.1973C18.4426 15.1973 18.8924 15.6471 18.8924 16.1932C18.8924 16.7394 18.4426 17.1892 17.8964 17.1892C17.3502 17.2214 16.9004 16.7716 16.9004 16.1932Z"
      fill="white"
    />
    <path
      d="M13.9453 16.1936C13.9453 15.7117 14.3309 15.3262 14.8128 15.3262C15.2947 15.3262 15.6802 15.7117 15.6802 16.1936C15.6802 16.6756 15.2947 17.0611 14.8128 17.0611C14.3309 17.0932 13.9453 16.6756 13.9453 16.1936Z"
      fill="white"
    />
    <path
      d="M11.4395 16.1921C11.4395 15.7744 11.7607 15.4531 12.1784 15.4531C12.5961 15.4531 12.9174 15.7744 12.9174 16.1921C12.9174 16.6097 12.5961 16.931 12.1784 16.931C11.7929 16.9632 11.4395 16.6097 11.4395 16.1921Z"
      fill="white"
    />
    <path
      d="M9.09375 16.1925C9.09375 15.8391 9.38291 15.582 9.70419 15.582C10.0576 15.582 10.3146 15.8712 10.3146 16.1925C10.3146 16.5459 10.0255 16.835 9.70419 16.835C9.38291 16.835 9.09375 16.5459 9.09375 16.1925Z"
      fill="white"
    />
    <path
      d="M7.10156 16.1918C7.10156 15.9026 7.32646 15.6777 7.61562 15.6777C7.90477 15.6777 8.12967 15.9026 8.12967 16.1918C8.12967 16.4809 7.90477 16.7058 7.61562 16.7058C7.32646 16.7058 7.10156 16.4809 7.10156 16.1918Z"
      fill="white"
    />
    <path
      d="M5.20703 16.1922C5.20703 15.9994 5.36767 15.8066 5.59257 15.8066C5.78534 15.8066 5.97811 15.9673 5.97811 16.1922C5.97811 16.385 5.81747 16.5777 5.59257 16.5777C5.36767 16.5777 5.20703 16.4171 5.20703 16.1922Z"
      fill="white"
    />
    <path
      d="M3.59961 16.1926C3.59961 16.0641 3.72812 15.9355 3.85664 15.9355C3.98515 15.9355 4.11366 16.0319 4.11366 16.1926C4.11366 16.3211 4.01728 16.4496 3.85664 16.4496C3.69599 16.4496 3.59961 16.3532 3.59961 16.1926Z"
      fill="white"
    />
    <path
      d="M26.7324 16.2244C26.7324 15.4854 27.3429 14.875 28.0818 14.875C28.8208 14.875 29.4312 15.4854 29.4312 16.2244C29.4312 16.9633 28.8208 17.5738 28.0818 17.5738C27.3429 17.6059 26.7324 16.9955 26.7324 16.2244Z"
      fill="white"
    />
    <path
      d="M23.3262 19.5655C23.3262 18.8908 23.8724 18.3125 24.5792 18.3125C25.2539 18.3125 25.8322 18.8587 25.8322 19.5655C25.8322 20.2402 25.286 20.8185 24.5792 20.8185C23.9045 20.8185 23.3262 20.2723 23.3262 19.5655Z"
      fill="white"
    />
    <path
      d="M20.0176 19.567C20.0176 18.9565 20.4995 18.4746 21.1099 18.4746C21.7204 18.4746 22.2023 18.9565 22.2023 19.567C22.2023 20.1774 21.7204 20.6593 21.1099 20.6593C20.5316 20.6915 20.0176 20.1774 20.0176 19.567Z"
      fill="white"
    />
    <path
      d="M16.9004 19.5663C16.9004 19.0201 17.3502 18.5703 17.8964 18.5703C18.4426 18.5703 18.8924 19.0201 18.8924 19.5663C18.8924 20.1125 18.4426 20.5623 17.8964 20.5623C17.3502 20.5623 16.9004 20.1125 16.9004 19.5663Z"
      fill="white"
    />
    <path
      d="M13.9453 19.5667C13.9453 19.0848 14.3309 18.6992 14.8128 18.6992C15.2947 18.6992 15.6802 19.0848 15.6802 19.5667C15.6802 20.0486 15.2947 20.4342 14.8128 20.4342C14.3309 20.4342 13.9453 20.0486 13.9453 19.5667Z"
      fill="white"
    />
    <path
      d="M11.4395 19.5671C11.4395 19.1494 11.7607 18.8281 12.1784 18.8281C12.5961 18.8281 12.9174 19.1494 12.9174 19.5671C12.9174 19.9847 12.5961 20.306 12.1784 20.306C11.7929 20.3382 11.4395 19.9847 11.4395 19.5671Z"
      fill="white"
    />
    <path
      d="M9.09375 19.5655C9.09375 19.2121 9.38291 18.9551 9.70419 18.9551C10.0576 18.9551 10.3146 19.2442 10.3146 19.5655C10.3146 19.9189 10.0255 20.176 9.70419 20.176C9.38291 20.2081 9.09375 19.9189 9.09375 19.5655Z"
      fill="white"
    />
    <path
      d="M7.10156 19.5668C7.10156 19.2776 7.32646 19.0527 7.61562 19.0527C7.90477 19.0527 8.12967 19.2776 8.12967 19.5668C8.12967 19.8559 7.90477 20.0808 7.61562 20.0808C7.32646 20.0808 7.10156 19.8559 7.10156 19.5668Z"
      fill="white"
    />
    <path
      d="M5.20703 19.5672C5.20703 19.3744 5.36767 19.1816 5.59257 19.1816C5.78534 19.1816 5.97811 19.3423 5.97811 19.5672C5.97811 19.76 5.81747 19.9527 5.59257 19.9527C5.36767 19.9527 5.20703 19.7921 5.20703 19.5672Z"
      fill="white"
    />
    <path
      d="M3.59961 19.5656C3.59961 19.4371 3.72812 19.3086 3.85664 19.3086C3.98515 19.3086 4.11366 19.4371 4.11366 19.5656C4.11366 19.6941 4.01728 19.8226 3.85664 19.8226C3.69599 19.8226 3.59961 19.6941 3.59961 19.5656Z"
      fill="white"
    />
    <path
      d="M20.0176 22.94C20.0176 22.3296 20.4995 21.8477 21.1099 21.8477C21.7204 21.8477 22.2023 22.3296 22.2023 22.94C22.2023 23.5505 21.7204 24.0324 21.1099 24.0324C20.5316 24.0324 20.0176 23.5505 20.0176 22.94Z"
      fill="white"
    />
    <path
      d="M16.9004 22.9393C16.9004 22.3932 17.3502 21.9434 17.8964 21.9434C18.4426 21.9434 18.8924 22.3932 18.8924 22.9393C18.8924 23.4855 18.4426 23.9353 17.8964 23.9353C17.3502 23.9353 16.9004 23.4855 16.9004 22.9393Z"
      fill="white"
    />
    <path
      d="M16.9004 26.3124C16.9004 25.7662 17.3502 25.3164 17.8964 25.3164C18.4426 25.3164 18.8924 25.7662 18.8924 26.3124C18.8924 26.8586 18.4426 27.3084 17.8964 27.3084C17.3502 27.3084 16.9004 26.8586 16.9004 26.3124Z"
      fill="white"
    />
    <path
      d="M42.4602 22.4746C40.6387 22.4746 38.9931 22.2736 37.5273 20.8068L39.4163 18.9182C40.3732 19.8731 41.6172 20.0062 42.5051 20.0062C43.5057 20.0062 44.5488 19.6736 44.5488 18.8069C44.5488 18.2285 44.2389 17.8289 43.3274 17.7392L41.5049 17.5618C39.4159 17.3628 38.1279 16.4517 38.1279 14.3168C38.1279 11.9178 40.2377 10.6289 42.5937 10.6289C44.3936 10.6289 45.9047 10.9403 47.0162 11.9839L45.239 13.7837C44.5719 13.1841 43.5491 13.0072 42.5494 13.0072C41.3945 13.0072 40.9058 13.5403 40.9058 14.1178C40.9058 14.54 41.0836 15.0293 42.106 15.118L43.929 15.2958C46.2165 15.5176 47.3739 16.7405 47.3739 18.6955C47.3729 21.2512 45.1942 22.4746 42.4602 22.4746Z"
      fill="white"
    />
    <path
      d="M51.2833 17.4515C51.2833 18.9404 52.1948 20.0293 53.8172 20.0293C55.0825 20.0293 55.7053 19.6736 56.4384 18.9404L58.1944 20.6511C57.0163 21.8297 55.8831 22.4746 53.7936 22.4746C51.0606 22.4746 48.4395 21.23 48.4395 16.5399C48.4395 12.7618 50.4832 10.6289 53.4827 10.6289C56.7044 10.6289 58.5269 12.9855 58.5269 16.163V17.451L51.2833 17.4515ZM55.417 14.2281C55.1042 13.5403 54.4597 13.0289 53.4827 13.0289C52.5042 13.0289 51.8602 13.5403 51.5494 14.2281C51.3715 14.6514 51.306 14.9628 51.2828 15.4742H55.6836C55.6609 14.9628 55.5944 14.6519 55.417 14.2281Z"
      fill="white"
    />
    <path
      d="M62.6827 17.4515C62.6827 18.9404 63.5952 20.0293 65.2157 20.0293C66.4829 20.0293 67.1057 19.6736 67.8388 18.9404L69.5938 20.6511C68.4158 21.8297 67.2825 22.4746 65.194 22.4746C62.46 22.4746 59.8379 21.23 59.8379 16.5399C59.8379 12.7618 61.8826 10.6289 64.8831 10.6289C68.1049 10.6289 69.9273 12.9855 69.9273 16.163V17.451L62.6827 17.4515ZM66.8165 14.2281C66.5046 13.5403 65.8601 13.0289 64.8831 13.0289C63.9046 13.0289 63.2597 13.5403 62.9488 14.2281C62.7709 14.6514 62.7044 14.9628 62.6822 15.4742H67.083C67.0608 14.9628 66.9938 14.6519 66.8165 14.2281Z"
      fill="white"
    />
    <path
      d="M78.7718 22.34L75.9289 17.5154L74.7041 18.8959V22.34H71.8164V6.51562H74.7041V15.4948L78.5496 10.7608H82.0399L77.9056 15.4282L82.3498 22.3405L78.7718 22.34Z"
      fill="white"
    />
  </svg>
);
