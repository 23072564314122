import { BraidSizes, type TileFragment } from '@seek/cmsu-cms-connect';
import type { Tiles as BraidTiles } from 'braid-design-system';

type TileProps = {
  columns:
    | NonNullable<TileFragment['responsiveColumnValue']>
    | TileFragment['columns'];
  space: TileFragment['space'];
  dividers?: TileFragment['dividers'];
  background?: TileFragment['background'];
};
// Consume a TileFragment and combine the `columns` and `responsiveColumnValue` properties
// so that our `Tiles` component props are consistent with the Braid Tiles component.
export const getTileProps = (tile?: TileFragment | null): TileProps => {
  let tileProps: TileProps = {
    columns: 1,
    space: BraidSizes.Gutter,
  };

  if (!tile) {
    return tileProps;
  }

  const { columns, responsiveColumnValue, ...restProps } = tile;
  tileProps = {
    ...tileProps,
    columns: responsiveColumnValue || columns,
    ...restProps,
  };
  return tileProps;
};

type ResponsiveColumns = TileFragment['responsiveColumnValue'];

// Enforce the columns value to return a full spread of
// responsive values to avoid inheritence issues of undefined responsive sizes.
export const spreadColumnsToResponsiveValues = (
  columns: React.ComponentProps<typeof BraidTiles>['columns'],
) => {
  let columnNum = 1;

  if (typeof columns === 'number') {
    columnNum = columns;
  }

  const responsiveValues = {
    mobile: columnNum,
    tablet: columnNum,
    desktop: columnNum,
    wide: columnNum,
  };

  // Provide fallback values at each responsive value
  responsiveValues.mobile = (columns as ResponsiveColumns)?.mobile || 1;
  responsiveValues.tablet =
    (columns as ResponsiveColumns)?.tablet ||
    (columns as ResponsiveColumns)?.mobile ||
    columnNum;
  responsiveValues.desktop =
    (columns as ResponsiveColumns)?.desktop ||
    (columns as ResponsiveColumns)?.tablet ||
    (columns as ResponsiveColumns)?.mobile ||
    columnNum;
  responsiveValues.wide =
    (columns as ResponsiveColumns)?.wide ||
    (columns as ResponsiveColumns)?.desktop ||
    (columns as ResponsiveColumns)?.tablet ||
    (columns as ResponsiveColumns)?.mobile ||
    columnNum;

  return responsiveValues;
};
