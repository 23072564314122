import type { HeadingFragment } from '@seek/cmsu-cms-connect';
import { Heading as BraidHeading } from 'braid-design-system';
import { toLevel } from '../../utils';
import { renderIcon } from '../IconBlockGroup/renderer';
import { vars } from 'braid-design-system/css';
import { toCustomFontSize } from '../../utils/toCustomFontSize';

type Props = Pick<HeadingFragment, 'text'> &
  Partial<
    Pick<
      HeadingFragment,
      'component' | 'level' | 'weight' | 'icon' | 'tone' | 'customHeadingSize'
    >
  > & {
    data?: React.ComponentProps<typeof BraidHeading>['data'];
  };

export const Heading = ({
  level,
  text,
  component,
  weight,
  icon,
  tone,
  data,
  customHeadingSize,
}: Props) => (
  <BraidHeading
    level={toLevel(level)}
    // Using `as` here because we cannot type-check if a string is React.ElementType.
    // This will error if someone puts in the wrong type. Onus is on the user to make sure it's correct.
    component={(component as React.ElementType<any>) || undefined}
    weight={weight || 'regular'}
    icon={(icon && renderIcon(icon)) || undefined}
    data={data}
  >
    <span
      style={{
        color: vars.foregroundColor[tone!] || undefined,
        ...(customHeadingSize
          ? { fontSize: `${toCustomFontSize(customHeadingSize)}rem` }
          : {}),
      }}
    >
      {text}
    </span>
  </BraidHeading>
);
