import {
  type AlertFragment,
  CParagraphMarginBottom,
} from '@seek/cmsu-cms-connect';
import { Alert as BraidAlert } from 'braid-design-system';
import React from 'react';

import { Paragraph } from '../Paragraph/Paragraph';
import { toBraidTone } from '../../utils';

type Props = Pick<AlertFragment, 'alertTone' | 'paragraph'>;

const Alert = ({ alertTone, paragraph }: Props) => (
  <BraidAlert tone={toBraidTone(alertTone)}>
    <Paragraph
      content={paragraph.Paragraph_text?.raw}
      marginBottom={CParagraphMarginBottom.None}
      align={paragraph.align}
      tone={paragraph.tone}
      size={paragraph.size}
    />
  </BraidAlert>
);

export { Alert };
