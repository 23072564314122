import type { CustomComponentFragment } from '@seek/cmsu-cms-connect';
import type { FindByTypename } from '../FindByTypename';
import type { AdditionalData } from '../AdditionalData';

/**
 * Union of all Action types
 */
type CustomComponent = NonNullable<Required<CustomComponentFragment>['data']>;

/**
 * Returns the Type given the __typename
 */
type BlockOf<Type extends CustomComponent['__typename']> = FindByTypename<
  CustomComponent,
  Type
>;

export type CustomComponentRenderers = Partial<{
  [Property in CustomComponent['__typename']]: (
    block: BlockOf<Property>,
    additionalData: AdditionalData,
  ) => JSX.Element;
}>;

type Props = {
  renderers: CustomComponentRenderers;
  data: CustomComponent;
  additionalData: AdditionalData;
};

/**
 * Matches an Action to the given renders
 */
export const CustomComponentRenderer = ({
  renderers,
  data,
  additionalData,
}: Props) =>
  // Setting `action` as any to suppress `never` errors
  renderers[data.__typename]?.(data as any, additionalData) || null;
