import { CustomDecoration } from '@seek/cmsu-cms-connect';
import React from 'react';
import { ContactUsDecoration } from './ContactUsDecoration';
import { TALDecoration2 } from './TALDecoration2';
import { TALDecoration } from './TALDecoration';

export const DecorationRenderer = ({
  decorationType,
}: {
  decorationType: CustomDecoration | null | undefined;
}) => {
  switch (decorationType) {
    case CustomDecoration.ContactUsBannerDeco:
      return <ContactUsDecoration />;
    case CustomDecoration.TalBannerDeco:
      return <TALDecoration />;
    case CustomDecoration.TalBannerDeco_2:
      return <TALDecoration2 />;
    default:
      return null;
  }
};
