import { Box, Inline } from 'braid-design-system';
import React, { type MouseEvent } from 'react';

import {
  selectedBackground,
  bulletPointBase,
} from './TestimonialCarouselBottomNav.css';

export type MoveSlidesFunction = (
  event: MouseEvent,
  slidesToMove: number,
) => void;

interface TestimonialCarouselBottomNavProps {
  focusedSlideIndex: number;
  moveSlidesLeft: MoveSlidesFunction;
  moveSlidesRight: MoveSlidesFunction;
  slides: any[];
}

interface TestimonialCarouselBottomNavItemProps {
  index: number;
}

export const TestimonialCarouselBottomNav = ({
  focusedSlideIndex,
  moveSlidesLeft,
  moveSlidesRight,
  slides,
}: TestimonialCarouselBottomNavProps) => {
  const goToSlide = (event: MouseEvent, slideIndex: number) => {
    if (slideIndex === focusedSlideIndex) {
      return;
    }

    const moveLeftOrRight = focusedSlideIndex - slideIndex;

    if (moveLeftOrRight > 0) {
      moveSlidesLeft(event, moveLeftOrRight);
    } else {
      moveSlidesRight(event, -moveLeftOrRight);
    }
  };

  const TestimonialCarouselBottomNavItem = ({
    index,
  }: TestimonialCarouselBottomNavItemProps) => {
    const selected = focusedSlideIndex === index;

    return (
      <Box
        component="button"
        onClick={(event) => goToSlide(event, index)}
        cursor={!selected ? 'pointer' : undefined}
        background={selected ? undefined : 'neutralLight'}
        className={[bulletPointBase, selected ? selectedBackground : undefined]}
      />
    );
  };

  if (slides.length <= 1) {
    return null;
  }

  return (
    <Inline
      space="xsmall"
      align="center"
      data={{ testid: 'testimonial-carousel-bottom-nav' }}
    >
      {slides.map((_, index) => (
        <TestimonialCarouselBottomNavItem
          index={index}
          key={`bulletpoint-${index}`}
        />
      ))}
    </Inline>
  );
};
