// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Show more': () => 'Show more' | '[Šḩöööŵ m̂öööřẽẽẽ]';
    'Show less': () => 'Show less' | '[Šḩöööŵ ƚẽẽẽšš]';
    'Filter by year': () =>
      | 'Filter by year'
      | 'ตัวกรองตามปี'
      | 'Filter berdasarkan tahun'
      | '[Ƒììƚṯẽẽř ßýý ýýẽẽăăř]';
    Menu: () => 'Menu' | 'เมนู' | '[Ṃẽẽกี้ǚǚ]';
    Filters: () => 'Filters' | 'คัดกรอง' | 'Filter' | '[Ƒìììƚṯẽẽẽřš]';
    'Select a filter': () =>
      | 'Select a filter'
      | 'เลือกตัวกรอง'
      | 'Pilih filter'
      | '[Šẽẽẽƚẽẽẽçṯ ăăă ƒìììƚṯẽẽẽř]';
    'Select up to two options': () =>
      | 'Select up to two options'
      | 'เลือกได้สูงสุด 2 ตัวเลือก'
      | 'Pilih hingga dua opsi'
      | '[Šẽẽẽƚẽẽẽçṯ ǚǚǚƥ ṯööö ṯŵööö öööƥṯìììöööกี้š]';
    'Select options': () =>
      | 'Select options'
      | 'เลือกตัวเลือก'
      | 'Pilih opsi'
      | '[Šẽẽẽƚẽẽẽçṯ öööƥṯìììöööกี้š]';
    'Apply filters': () =>
      | 'Apply filters'
      | 'ใช้ตัวกรอง'
      | 'Terapkan filter'
      | '[Ậƥƥƚýýý ƒìììƚṯẽẽẽřš]';
    'Clear all': () =>
      | 'Clear all'
      | 'ล้างตัวกรอง'
      | 'Hapus semua'
      | '[Çƚẽẽẽăăăř ăăăƚƚ]';
    'Filter by': () =>
      | 'Filter by'
      | 'คัดกรองตาม'
      | 'Filter berdasarkan'
      | '[Ƒìììƚṯẽẽẽř ßýýý]';
    'Select an option': () =>
      | 'Select an option'
      | 'เลือกหนึ่งตัวเลือก'
      | 'Pilih satu opsi'
      | '[Šẽẽẽƚẽẽẽçṯ ăăăกี้ öööƥṯìììöööกี้]';
    'Sort by': () =>
      | 'Sort by'
      | 'เรียงตาม'
      | 'Urutkan berdasarkan'
      | '[Šöööřṯ ßýýý]';
    'Show details': () =>
      | 'Show details'
      | 'แสดงเนื้อหา'
      | 'Tampilkan detail'
      | '[Šḩöööŵ ƌẽẽẽṯăăăìììƚš]';
    'Hide details': () =>
      | 'Hide details'
      | 'ซ่อนเนื้อหา'
      | 'Sembunyikan detail'
      | '[Ḩììƌẽẽ ƌẽẽṯăăììƚš]';
  }
>({
  en: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  th: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'ตัวกรองตามปี',
    Menu: 'เมนู',
    Filters: 'คัดกรอง',
    'Select a filter': 'เลือกตัวกรอง',
    'Select up to two options': 'เลือกได้สูงสุด 2 ตัวเลือก',
    'Select options': 'เลือกตัวเลือก',
    'Apply filters': 'ใช้ตัวกรอง',
    'Clear all': 'ล้างตัวกรอง',
    'Filter by': 'คัดกรองตาม',
    'Select an option': 'เลือกหนึ่งตัวเลือก',
    'Sort by': 'เรียงตาม',
    'Show details': 'แสดงเนื้อหา',
    'Hide details': 'ซ่อนเนื้อหา',
  }),
  id: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter berdasarkan tahun',
    Menu: 'Menu',
    Filters: 'Filter',
    'Select a filter': 'Pilih filter',
    'Select up to two options': 'Pilih hingga dua opsi',
    'Select options': 'Pilih opsi',
    'Apply filters': 'Terapkan filter',
    'Clear all': 'Hapus semua',
    'Filter by': 'Filter berdasarkan',
    'Select an option': 'Pilih satu opsi',
    'Sort by': 'Urutkan berdasarkan',
    'Show details': 'Tampilkan detail',
    'Hide details': 'Sembunyikan detail',
  }),
  zh: createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-AU': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-MY': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-NZ': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-PH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-SG': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'th-TH': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'ตัวกรองตามปี',
    Menu: 'เมนู',
    Filters: 'คัดกรอง',
    'Select a filter': 'เลือกตัวกรอง',
    'Select up to two options': 'เลือกได้สูงสุด 2 ตัวเลือก',
    'Select options': 'เลือกตัวเลือก',
    'Apply filters': 'ใช้ตัวกรอง',
    'Clear all': 'ล้างตัวกรอง',
    'Filter by': 'คัดกรองตาม',
    'Select an option': 'เลือกหนึ่งตัวเลือก',
    'Sort by': 'เรียงตาม',
    'Show details': 'แสดงเนื้อหา',
    'Hide details': 'ซ่อนเนื้อหา',
  }),
  'id-ID': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter berdasarkan tahun',
    Menu: 'Menu',
    Filters: 'Filter',
    'Select a filter': 'Pilih filter',
    'Select up to two options': 'Pilih hingga dua opsi',
    'Select options': 'Pilih opsi',
    'Apply filters': 'Terapkan filter',
    'Clear all': 'Hapus semua',
    'Filter by': 'Filter berdasarkan',
    'Select an option': 'Pilih satu opsi',
    'Sort by': 'Urutkan berdasarkan',
    'Show details': 'Tampilkan detail',
    'Hide details': 'Sembunyikan detail',
  }),
  'zh-HK': createLanguage({
    'Show more': 'Show more',
    'Show less': 'Show less',
    'Filter by year': 'Filter by year',
    Menu: 'Menu',
    Filters: 'Filters',
    'Select a filter': 'Select a filter',
    'Select up to two options': 'Select up to two options',
    'Select options': 'Select options',
    'Apply filters': 'Apply filters',
    'Clear all': 'Clear all',
    'Filter by': 'Filter by',
    'Select an option': 'Select an option',
    'Sort by': 'Sort by',
    'Show details': 'Show details',
    'Hide details': 'Hide details',
  }),
  'en-PSEUDO': createLanguage({
    'Show more': '[Šḩöööŵ m̂öööřẽẽẽ]',
    'Show less': '[Šḩöööŵ ƚẽẽẽšš]',
    'Filter by year': '[Ƒììƚṯẽẽř ßýý ýýẽẽăăř]',
    Menu: '[Ṃẽẽกี้ǚǚ]',
    Filters: '[Ƒìììƚṯẽẽẽřš]',
    'Select a filter': '[Šẽẽẽƚẽẽẽçṯ ăăă ƒìììƚṯẽẽẽř]',
    'Select up to two options': '[Šẽẽẽƚẽẽẽçṯ ǚǚǚƥ ṯööö ṯŵööö öööƥṯìììöööกี้š]',
    'Select options': '[Šẽẽẽƚẽẽẽçṯ öööƥṯìììöööกี้š]',
    'Apply filters': '[Ậƥƥƚýýý ƒìììƚṯẽẽẽřš]',
    'Clear all': '[Çƚẽẽẽăăăř ăăăƚƚ]',
    'Filter by': '[Ƒìììƚṯẽẽẽř ßýýý]',
    'Select an option': '[Šẽẽẽƚẽẽẽçṯ ăăăกี้ öööƥṯìììöööกี้]',
    'Sort by': '[Šöööřṯ ßýýý]',
    'Show details': '[Šḩöööŵ ƌẽẽẽṯăăăìììƚš]',
    'Hide details': '[Ḩììƌẽẽ ƌẽẽṯăăììƚš]',
  }),
});

export default translations;
