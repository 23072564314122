import React from 'react';
import type { ActionRenderers } from '../ActionRenderer';
import { TextLink } from '../../../modules/ActionGroup/TextLink';
import { ButtonLink } from '../../../modules/ActionGroup/ButtonLink';
import { ImageLink } from '../../../modules/ActionGroup/ImageLink';
import { useCMSUContext } from '../../../hooks/useCMSUContext';
import { toUrl } from '../../../utils/toUrl';
import { getElementAction } from '../../../utils/getElementAction';

export const universal: ActionRenderers = {
  CTextLink: (props) => {
    const { language } = useCMSUContext();
    const url = toUrl(props.link?.to, language);
    return (
      <TextLink
        {...props}
        uniqueTrackingId="text-link"
        trackingAttributes={{
          elementText: props.text,
          elementAction: getElementAction(props.link?.to),
          elementLink: url,
        }}
      />
    );
  },
  CButtonLink: (props) => {
    const { language } = useCMSUContext();
    const url = toUrl(props.link?.to, language);
    return (
      <ButtonLink
        {...props}
        uniqueTrackingId="button-link"
        trackingAttributes={{
          elementText: props.text,
          elementAction: getElementAction(props.link?.to),
          elementLink: url,
        }}
      />
    );
  },
  CImageLink: (props) => {
    const url = toUrl(props.link?.to);
    return (
      <ImageLink
        {...props}
        uniqueTrackingId="image-link"
        trackingAttributes={{
          elementText: props.image?.alt || '',
          elementAction: getElementAction(props.link?.to),
          elementLink: url,
        }}
      />
    );
  },
};
