// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import type { FormatXMLElementFn } from '@vocab/core';
import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Select filters to uncover deeper insights about candidates in country.': (values: {
      country: string;
    }) => string;
    'Explore drivers by': () =>
      | 'Explore drivers by'
      | 'ค้นหาปัจจัยตาม'
      | 'Jelajahi penggerak berdasarkan'
      | '[Ëꭕƥƚöööřẽẽẽ ƌřìììṽẽẽẽřš ßýýý]';
    'Select at least one option': () =>
      | 'Select at least one option'
      | 'เลือกอย่างน้อย 1 ตัวเลือก'
      | 'Pilih setidaknya satu opsi'
      | '[Šẽẽẽƚẽẽẽçṯ ăăăṯ ƚẽẽẽăăăšṯ öööกี้ẽẽẽ öööƥṯìììöööกี้]';
    Share: () => 'Share' | 'แชร์บน' | 'Bagikan' | '[Šḩăăřẽẽ]';
    'Share on Facebook': () =>
      | 'Share on Facebook'
      | 'แชร์บน Facebook'
      | 'Bagikan di Facebook'
      | '[Šḩăăřẽẽ ööกี้ Ƒăăçẽẽßööööķ]';
    'Share on X': () =>
      | 'Share on X'
      | 'แชร์บน X'
      | 'Bagikan di X'
      | '[Šḩăăăřẽẽẽ öööกี้ X̂]';
    'Share on LinkedIn': () =>
      | 'Share on LinkedIn'
      | 'แชร์บน LinkedIn'
      | 'Bagikan di LinkedIn'
      | '[Šḩăăăřẽẽẽ öööกี้ Łìììกี้ķẽẽẽƌÏกี้]';
    'Copy Link': () =>
      | 'Copy Link'
      | 'คัดลองลิงก์'
      | 'Salin tautan'
      | '[Çöööƥýýý Łìììกี้ķ]';
    'Link copied to clipboard': () =>
      | 'Link copied to clipboard'
      | 'คัดลอกลิงก์'
      | 'Tautan disalin ke papan clip'
      | '[Łìììกี้ķ çöööƥìììẽẽẽƌ ṯööö çƚìììƥßöööăăăřƌ]';
    'Clipboard access not available': () =>
      | 'Clipboard access not available'
      | 'ไม่สามารถเข้าถึงคลิปบอร์ดได้'
      | 'Akses ke papan klik tidak tersedia'
      | '[Çƚìììƥßöööăăăřƌ ăăăççẽẽẽšš กี้öööṯ ăăăṽăăăìììƚăăăßƚẽẽẽ]';
    'Download PDF': () =>
      | 'Download PDF'
      | 'ดาวน์โหลด PDF'
      | 'Unduh PDF'
      | '[Ḋöööŵกี้ƚöööăăăƌ ƤḊƑ]';
    'Showing results from': () =>
      | 'Showing results from'
      | 'แสดงผลลัพธ์จาก'
      | 'Menampilkan Hasil dari'
      | '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ƒřöööm̂]';
    'All respondents': () =>
      | 'All respondents'
      | 'ผู้ตอบแบบสอบถามทุกคน'
      | 'Semua responden'
      | '[Ậƚƚ řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš]';
    'Showing results that match': () =>
      | 'Showing results that match'
      | 'แสดงผลลัพธ์ที่ตรงความต้องการ'
      | 'Menampilkan hasil yang cocok'
      | '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ṯḩăăăṯ m̂ăăăṯçḩ]';
    'Results are based on': () =>
      | 'Results are based on'
      | 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม'
      | 'Hasil berdasarkan'
      | '[Řẽẽẽšǚǚǚƚṯš ăăăřẽẽẽ ßăăăšẽẽẽƌ öööกี้]';
    'respondents from a total of': () =>
      | 'respondents from a total of'
      | 'คน จากผู้หางานทั้งหมด'
      | 'responden dari total'
      | '[řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš ƒřöööm̂ ăăă ṯöööṯăăăƚ öööƒ]';
    'candidates surveyed': () =>
      | 'candidates surveyed'
      | 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง'
      | 'kandidat yang disurvei dalam'
      | '[çăăกี้ƌììƌăăṯẽẽš šǚǚřṽẽẽýýẽẽƌ]';
    'Drivers that attract these candidates': () =>
      | 'Drivers that attract these candidates'
      | 'สิ่งที่ดึงดูดใจผู้สมัครงาน'
      | 'Faktor yang menarik kandidat'
      | '[Ḋřìììṽẽẽẽřš ṯḩăăăṯ ăăăṯṯřăăăçṯ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš]';
    'About these candidates': () =>
      | 'About these candidates'
      | 'เกี่ยวกับผู้หางาน'
      | 'Tentang kandidiat ini'
      | '[Ậßöööǚǚǚṯ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš]';
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.": () =>
      | "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations."
      | "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล. ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร"
      | "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan."
      | "[Ṯḩìììš % ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš řăăăกี้ķ ṯḩẽẽẽ ƌřìììṽẽẽẽř öööกี้ ìììm̂ƥöööřṯăăăกี้çẽẽẽ. Ậƚƚ ƌřìììṽẽẽẽřš çöööǚǚǚƚƌ ßẽẽẽ 'ìììm̂ƥöööřṯăăăกี้ṯ' ßǚǚǚṯ ṯḩìììš ḩẽẽẽƚƥš ýýýöööǚǚǚ ƥřìììöööřìììṯìììšẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯööö ƒöööçǚǚǚš öööกี้ ìììกี้ ĵöööß ăăăƌš öööř çöööกี้ṽẽẽẽřšăăăṯìììöööกี้š.]";
    "We'll show this data when we have more responses from people who match your search.": () =>
      | "We'll show this data when we have more responses from people who match your search."
      | 'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ'
      | 'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.'
      | "[Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ m̂öööřẽẽẽ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ƥẽẽẽöööƥƚẽẽẽ ŵḩööö m̂ăăăṯçḩ ýýýöööǚǚǚř šẽẽẽăăăřçḩ.]";
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.": () =>
      | "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do."
      | 'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น'
      | 'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.'
      | "[Ŵẽẽẽ’řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ģăăăṯḩẽẽẽřìììกี้ģ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö çöööกี้ƒìììƌẽẽẽกี้ṯƚýýý šḩăăăřẽẽẽ ŵḩăăăṯ ăăăṯṯřăăăçṯš ṯḩẽẽẽm̂ ṯööö ăăă řöööƚẽẽẽ. Ŵẽẽẽ'ƚƚ ǚǚǚƥƌăăăṯẽẽẽ ṯḩẽẽẽ ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ƌööö.]";
    'In the meantime, you can adjust your filters to try a broader search.': () =>
      | 'In the meantime, you can adjust your filters to try a broader search.'
      | 'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น'
      | 'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.'
      | '[Ïกี้ ṯḩẽẽẽ m̂ẽẽẽăăăกี้ṯìììm̂ẽẽẽ, ýýýöööǚǚǚ çăăăกี้ ăăăƌĵǚǚǚšṯ ýýýöööǚǚǚř ƒìììƚṯẽẽẽřš ṯööö ṯřýýý ăăă ßřöööăăăƌẽẽẽř šẽẽẽăăăřçḩ.]';
    'These are the drivers candidates consider when looking at roles.': () =>
      | 'These are the drivers candidates consider when looking at roles.'
      | 'นี่คือสิ่งที่ผู้สมัครงานให้พิจารณาเมื่อมองหางาน.'
      | 'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.'
      | '[Ṯḩẽẽẽšẽẽẽ ăăăřẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš çăăăกี้ƌìììƌăăăṯẽẽẽš çöööกี้šìììƌẽẽẽř ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ăăăṯ řöööƚẽẽẽš.]';
    'Drivers are ranked with the most important driver at the top.': () =>
      | 'Drivers are ranked with the most important driver at the top.'
      | 'ปัจจัยที่มีผลต่อการมองหางานโดยเรียงตามปัจจัยที่มีผลมากที่สุดอยู่ด้านบน.'
      | 'Faktor pendorong diurutkan dari yang terpenting di bagian atas.'
      | '[Ḋřìììṽẽẽẽřš ăăăřẽẽẽ řăăăกี้ķẽẽẽƌ ŵìììṯḩ ṯḩẽẽẽ m̂öööšṯ ìììm̂ƥöööřṯăăăกี้ṯ ƌřìììṽẽẽẽř ăăăṯ ṯḩẽẽẽ ṯöööƥ.]';
    'Expand each driver for more detail.': () =>
      | 'Expand each driver for more detail.'
      | 'อธิบายเกี่ยวกับแต่ละปัจจัยเพิ่มเติม.'
      | 'Perluas setiap faktor untuk detail lebih lanjut.'
      | '[Ëꭕƥăăăกี้ƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ƒöööř m̂öööřẽẽẽ ƌẽẽẽṯăăăìììƚ.]';
    "No data available yet for selected filters. We'll show this when we have enough responses.": (values: {
      filter: string;
    }) => string;
    'Sample size for selected filters is small, so there is greater margin of error.': <
      T = string,
    >(values: {
      filter: string;
      u: FormatXMLElementFn<T>;
    }) => ReturnType<FormatXMLElementFn<T>>;
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ": () =>
      | "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. "
      | "[Ŵḩẽẽẽกี้ ŵřìììṯìììกี้ģ ĵöööß ăăăƌš, ƒöööçǚǚǚš öööกี้ ƌẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ ăăăกี้ƌ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ ýýýöööǚǚǚř çöööm̂ƥẽẽẽṯìììṯöööřš. Ḋöööกี้'ṯ ŵăăăšṯẽẽẽ šƥăăăçẽẽẽ öööกี้ กี้öööṯ-ßöööṯḩẽẽẽřẽẽẽƌ ƌřìììṽẽẽẽřš, ăăăกี้ƌ ḩìììģḩƚìììģḩṯ ƥǚǚǚṯ-öööƒƒš ẽẽẽăăăřƚýýý. ]";
    'How we define importance': () =>
      | 'How we define importance'
      | '[Ḩöööŵ ŵẽẽẽ ƌẽẽẽƒìììกี้ẽẽẽ ìììm̂ƥöööřṯăăăกี้çẽẽẽ]';
    'Candidates have ranked each driver according to 4 levels of importance:': () =>
      | 'Candidates have ranked each driver according to 4 levels of importance:'
      | 'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ'
      | 'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:'
      | '[Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ řăăăกี้ķẽẽẽƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ăăăççöööřƌìììกี้ģ ṯööö 4 ƚẽẽẽṽẽẽẽƚš öööƒ ìììm̂ƥöööřṯăăăกี้çẽẽẽ:]';
    'Must-have: Essentials that candidates expect a job to have.': () =>
      | 'Must-have: Essentials that candidates expect a job to have.'
      | 'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี'
      | 'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.'
      | '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ: Ëššẽẽẽกี้ṯìììăăăƚš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]';
    'Delighted-if: Extras that differentiate you from other companies.': () =>
      | 'Delighted-if: Extras that differentiate you from other companies.'
      | 'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น'
      | 'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.'
      | '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ: Ëꭕṯřăăăš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ öööṯḩẽẽẽř çöööm̂ƥăăăกี้ìììẽẽẽš.]';
    "Not-bothered: Factors that don't really matter to candidates.": () =>
      | 'Not-bothered: Factors that don’t really matter to candidates.'
      | 'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ'
      | 'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.'
      | '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ: Ƒăăăçṯöööřš ṯḩăăăṯ ƌöööกี้’ṯ řẽẽẽăăăƚƚýýý m̂ăăăṯṯẽẽẽř ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš.]';
    "Put-off: Dealbreakers that candidates don't want in a role.": () =>
      | 'Put-off: Dealbreakers that candidates don’t want in a role.'
      | 'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน'
      | 'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.'
      | '[Ƥǚǚǚṯ-öööƒƒ: Ḋẽẽẽăăăƚßřẽẽẽăăăķẽẽẽřš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ƌöööกี้’ṯ ŵăăăกี้ṯ ìììกี้ ăăă řöööƚẽẽẽ.]';
    'Must-have': () =>
      | 'Must-have'
      | 'สิ่งที่ต้องมี'
      | 'Harus dimiliki'
      | '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ]';
    'Delighted-if': () =>
      | 'Delighted-if'
      | 'ดีถ้ามี'
      | 'Bagus jika dimiliki'
      | '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ]';
    'Not-bothered': () =>
      | 'Not-bothered'
      | 'ไม่สนใจ'
      | 'Tidak perlu'
      | '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ]';
    'Put-off': () => 'Put-off' | 'ปฏิเสธ' | 'Penolakan' | '[Ƥǚǚǚṯ-öööƒƒ]';
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.': (values: {
      filter: string;
    }) => string;
  }
>({
  en: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  th: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'เลือกตัวกรองเพื่อเข้าถึงข้อมูลเชิงลึกเกี่ยวกับผู้สมัครใน ไทย',
    'Explore drivers by': 'ค้นหาปัจจัยตาม',
    'Select at least one option': 'เลือกอย่างน้อย 1 ตัวเลือก',
    Share: 'แชร์บน',
    'Share on Facebook': 'แชร์บน Facebook',
    'Share on X': 'แชร์บน X',
    'Share on LinkedIn': 'แชร์บน LinkedIn',
    'Copy Link': 'คัดลองลิงก์',
    'Link copied to clipboard': 'คัดลอกลิงก์',
    'Clipboard access not available': 'ไม่สามารถเข้าถึงคลิปบอร์ดได้',
    'Download PDF': 'ดาวน์โหลด PDF',
    'Showing results from': 'แสดงผลลัพธ์จาก',
    'All respondents': 'ผู้ตอบแบบสอบถามทุกคน',
    'Showing results that match': 'แสดงผลลัพธ์ที่ตรงความต้องการ',
    'Results are based on': 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม',
    'respondents from a total of': 'คน จากผู้หางานทั้งหมด',
    'candidates surveyed': 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง',
    'Drivers that attract these candidates': 'สิ่งที่ดึงดูดใจผู้สมัครงาน',
    'About these candidates': 'เกี่ยวกับผู้หางาน',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล. ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร",
    "We'll show this data when we have more responses from people who match your search.":
      'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น',
    'In the meantime, you can adjust your filters to try a broader search.':
      'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น',
    'These are the drivers candidates consider when looking at roles.':
      'นี่คือสิ่งที่ผู้สมัครงานให้พิจารณาเมื่อมองหางาน.',
    'Drivers are ranked with the most important driver at the top.':
      'ปัจจัยที่มีผลต่อการมองหางานโดยเรียงตามปัจจัยที่มีผลมากที่สุดอยู่ด้านบน.',
    'Expand each driver for more detail.':
      'อธิบายเกี่ยวกับแต่ละปัจจัยเพิ่มเติม.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'ยังไม่มีข้อมูลสำหรับ {filter}  เราจะแสดงข้อมูลนี้อีกครั้งหากมีรายละเอียดเพิ่มเติม',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'ขนาดตัวอย่างสำหรับ {filter} มีจำนวนน้อยเกินไป <u>ดังนั้นข้อมูลอาจมีค่าความคลาดเคลื่อนสูง</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ',
    'Must-have: Essentials that candidates expect a job to have.':
      'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    'Delighted-if: Extras that differentiate you from other companies.':
      'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น',
    "Not-bothered: Factors that don't really matter to candidates.":
      'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน',
    'Must-have': 'สิ่งที่ต้องมี',
    'Delighted-if': 'ดีถ้ามี',
    'Not-bothered': 'ไม่สนใจ',
    'Put-off': 'ปฏิเสธ',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'นี่คือวิธีที่ผู้สมัครใช้เพื่อจัดอันดับความต้องการของตนเองสำหรับปัจจัยเกี่ยวกับ {filter}. ผู้สมัครสามารถเลือกสิ่งที่ต้องมีได้มากกว่าหนึ่งตัวเลือก',
  }),
  id: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Pilih filter untuk mendapatkan wawasan lebih dalam tentang kandidat Indonesia',
    'Explore drivers by': 'Jelajahi penggerak berdasarkan',
    'Select at least one option': 'Pilih setidaknya satu opsi',
    Share: 'Bagikan',
    'Share on Facebook': 'Bagikan di Facebook',
    'Share on X': 'Bagikan di X',
    'Share on LinkedIn': 'Bagikan di LinkedIn',
    'Copy Link': 'Salin tautan',
    'Link copied to clipboard': 'Tautan disalin ke papan clip',
    'Clipboard access not available': 'Akses ke papan klik tidak tersedia',
    'Download PDF': 'Unduh PDF',
    'Showing results from': 'Menampilkan Hasil dari',
    'All respondents': 'Semua responden',
    'Showing results that match': 'Menampilkan hasil yang cocok',
    'Results are based on': 'Hasil berdasarkan',
    'respondents from a total of': 'responden dari total',
    'candidates surveyed': 'kandidat yang disurvei dalam',
    'Drivers that attract these candidates': 'Faktor yang menarik kandidat',
    'About these candidates': 'Tentang kandidiat ini',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan.",
    "We'll show this data when we have more responses from people who match your search.":
      'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.',
    'In the meantime, you can adjust your filters to try a broader search.':
      'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.',
    'These are the drivers candidates consider when looking at roles.':
      'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.',
    'Drivers are ranked with the most important driver at the top.':
      'Faktor pendorong diurutkan dari yang terpenting di bagian atas.',
    'Expand each driver for more detail.':
      'Perluas setiap faktor untuk detail lebih lanjut.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'Belum ada data yang tersedia untuk {filter}. Kami akan menunjukkan ini saat kami memiliki cukup respons.',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Ukuran sampel untuk {filter} kecil, jadi ada <u>margin kesalahan yang lebih besar.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.',
    'Must-have': 'Harus dimiliki',
    'Delighted-if': 'Bagus jika dimiliki',
    'Not-bothered': 'Tidak perlu',
    'Put-off': 'Penolakan',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'Ini adalah cara kandidat mengurutkan preferensi mereka untuk komponen {filter}. Kandidat memiliki opsi untuk memilih lebih dari satu yang harus dimiliki.',
  }),
  zh: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-AU': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-HK': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-ID': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-MY': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-NZ': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-PH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-SG': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-TH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'th-TH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'เลือกตัวกรองเพื่อเข้าถึงข้อมูลเชิงลึกเกี่ยวกับผู้สมัครใน ไทย',
    'Explore drivers by': 'ค้นหาปัจจัยตาม',
    'Select at least one option': 'เลือกอย่างน้อย 1 ตัวเลือก',
    Share: 'แชร์บน',
    'Share on Facebook': 'แชร์บน Facebook',
    'Share on X': 'แชร์บน X',
    'Share on LinkedIn': 'แชร์บน LinkedIn',
    'Copy Link': 'คัดลองลิงก์',
    'Link copied to clipboard': 'คัดลอกลิงก์',
    'Clipboard access not available': 'ไม่สามารถเข้าถึงคลิปบอร์ดได้',
    'Download PDF': 'ดาวน์โหลด PDF',
    'Showing results from': 'แสดงผลลัพธ์จาก',
    'All respondents': 'ผู้ตอบแบบสอบถามทุกคน',
    'Showing results that match': 'แสดงผลลัพธ์ที่ตรงความต้องการ',
    'Results are based on': 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม',
    'respondents from a total of': 'คน จากผู้หางานทั้งหมด',
    'candidates surveyed': 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง',
    'Drivers that attract these candidates': 'สิ่งที่ดึงดูดใจผู้สมัครงาน',
    'About these candidates': 'เกี่ยวกับผู้หางาน',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล. ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร",
    "We'll show this data when we have more responses from people who match your search.":
      'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น',
    'In the meantime, you can adjust your filters to try a broader search.':
      'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น',
    'These are the drivers candidates consider when looking at roles.':
      'นี่คือสิ่งที่ผู้สมัครงานให้พิจารณาเมื่อมองหางาน.',
    'Drivers are ranked with the most important driver at the top.':
      'ปัจจัยที่มีผลต่อการมองหางานโดยเรียงตามปัจจัยที่มีผลมากที่สุดอยู่ด้านบน.',
    'Expand each driver for more detail.':
      'อธิบายเกี่ยวกับแต่ละปัจจัยเพิ่มเติม.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'ยังไม่มีข้อมูลสำหรับ {filter}  เราจะแสดงข้อมูลนี้อีกครั้งหากมีรายละเอียดเพิ่มเติม',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'ขนาดตัวอย่างสำหรับ {filter} มีจำนวนน้อยเกินไป <u>ดังนั้นข้อมูลอาจมีค่าความคลาดเคลื่อนสูง</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ',
    'Must-have: Essentials that candidates expect a job to have.':
      'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    'Delighted-if: Extras that differentiate you from other companies.':
      'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น',
    "Not-bothered: Factors that don't really matter to candidates.":
      'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน',
    'Must-have': 'สิ่งที่ต้องมี',
    'Delighted-if': 'ดีถ้ามี',
    'Not-bothered': 'ไม่สนใจ',
    'Put-off': 'ปฏิเสธ',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'นี่คือวิธีที่ผู้สมัครใช้เพื่อจัดอันดับความต้องการของตนเองสำหรับปัจจัยเกี่ยวกับ {filter}. ผู้สมัครสามารถเลือกสิ่งที่ต้องมีได้มากกว่าหนึ่งตัวเลือก',
  }),
  'id-ID': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Pilih filter untuk mendapatkan wawasan lebih dalam tentang kandidat Indonesia',
    'Explore drivers by': 'Jelajahi penggerak berdasarkan',
    'Select at least one option': 'Pilih setidaknya satu opsi',
    Share: 'Bagikan',
    'Share on Facebook': 'Bagikan di Facebook',
    'Share on X': 'Bagikan di X',
    'Share on LinkedIn': 'Bagikan di LinkedIn',
    'Copy Link': 'Salin tautan',
    'Link copied to clipboard': 'Tautan disalin ke papan clip',
    'Clipboard access not available': 'Akses ke papan klik tidak tersedia',
    'Download PDF': 'Unduh PDF',
    'Showing results from': 'Menampilkan Hasil dari',
    'All respondents': 'Semua responden',
    'Showing results that match': 'Menampilkan hasil yang cocok',
    'Results are based on': 'Hasil berdasarkan',
    'respondents from a total of': 'responden dari total',
    'candidates surveyed': 'kandidat yang disurvei dalam',
    'Drivers that attract these candidates': 'Faktor yang menarik kandidat',
    'About these candidates': 'Tentang kandidiat ini',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan.",
    "We'll show this data when we have more responses from people who match your search.":
      'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.',
    'In the meantime, you can adjust your filters to try a broader search.':
      'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.',
    'These are the drivers candidates consider when looking at roles.':
      'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.',
    'Drivers are ranked with the most important driver at the top.':
      'Faktor pendorong diurutkan dari yang terpenting di bagian atas.',
    'Expand each driver for more detail.':
      'Perluas setiap faktor untuk detail lebih lanjut.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'Belum ada data yang tersedia untuk {filter}. Kami akan menunjukkan ini saat kami memiliki cukup respons.',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Ukuran sampel untuk {filter} kecil, jadi ada <u>margin kesalahan yang lebih besar.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.',
    'Must-have': 'Harus dimiliki',
    'Delighted-if': 'Bagus jika dimiliki',
    'Not-bothered': 'Tidak perlu',
    'Put-off': 'Penolakan',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'Ini adalah cara kandidat mengurutkan preferensi mereka untuk komponen {filter}. Kandidat memiliki opsi untuk memilih lebih dari satu yang harus dimiliki.',
  }),
  'zh-HK': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Drivers that attract these candidates',
    'About these candidates': 'About these candidates',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
  }),
  'en-PSEUDO': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      '[Šẽẽẽƚẽẽẽçṯ ƒìììƚṯẽẽẽřš ṯööö ǚǚǚกี้çöööṽẽẽẽř ƌẽẽẽẽẽẽƥẽẽẽř ìììกี้šìììģḩṯš ăăăßöööǚǚǚṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ìììกี้ {country}.]',
    'Explore drivers by': '[Ëꭕƥƚöööřẽẽẽ ƌřìììṽẽẽẽřš ßýýý]',
    'Select at least one option':
      '[Šẽẽẽƚẽẽẽçṯ ăăăṯ ƚẽẽẽăăăšṯ öööกี้ẽẽẽ öööƥṯìììöööกี้]',
    Share: '[Šḩăăřẽẽ]',
    'Share on Facebook': '[Šḩăăřẽẽ ööกี้ Ƒăăçẽẽßööööķ]',
    'Share on X': '[Šḩăăăřẽẽẽ öööกี้ X̂]',
    'Share on LinkedIn': '[Šḩăăăřẽẽẽ öööกี้ Łìììกี้ķẽẽẽƌÏกี้]',
    'Copy Link': '[Çöööƥýýý Łìììกี้ķ]',
    'Link copied to clipboard': '[Łìììกี้ķ çöööƥìììẽẽẽƌ ṯööö çƚìììƥßöööăăăřƌ]',
    'Clipboard access not available':
      '[Çƚìììƥßöööăăăřƌ ăăăççẽẽẽšš กี้öööṯ ăăăṽăăăìììƚăăăßƚẽẽẽ]',
    'Download PDF': '[Ḋöööŵกี้ƚöööăăăƌ ƤḊƑ]',
    'Showing results from': '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ƒřöööm̂]',
    'All respondents': '[Ậƚƚ řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš]',
    'Showing results that match': '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ṯḩăăăṯ m̂ăăăṯçḩ]',
    'Results are based on': '[Řẽẽẽšǚǚǚƚṯš ăăăřẽẽẽ ßăăăšẽẽẽƌ öööกี้]',
    'respondents from a total of':
      '[řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš ƒřöööm̂ ăăă ṯöööṯăăăƚ öööƒ]',
    'candidates surveyed': '[çăăกี้ƌììƌăăṯẽẽš šǚǚřṽẽẽýýẽẽƌ]',
    'Drivers that attract these candidates':
      '[Ḋřìììṽẽẽẽřš ṯḩăăăṯ ăăăṯṯřăăăçṯ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš]',
    'About these candidates': '[Ậßöööǚǚǚṯ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš]',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "[Ṯḩìììš % ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš řăăăกี้ķ ṯḩẽẽẽ ƌřìììṽẽẽẽř öööกี้ ìììm̂ƥöööřṯăăăกี้çẽẽẽ. Ậƚƚ ƌřìììṽẽẽẽřš çöööǚǚǚƚƌ ßẽẽẽ 'ìììm̂ƥöööřṯăăăกี้ṯ' ßǚǚǚṯ ṯḩìììš ḩẽẽẽƚƥš ýýýöööǚǚǚ ƥřìììöööřìììṯìììšẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯööö ƒöööçǚǚǚš öööกี้ ìììกี้ ĵöööß ăăăƌš öööř çöööกี้ṽẽẽẽřšăăăṯìììöööกี้š.]",
    "We'll show this data when we have more responses from people who match your search.":
      "[Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ m̂öööřẽẽẽ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ƥẽẽẽöööƥƚẽẽẽ ŵḩööö m̂ăăăṯçḩ ýýýöööǚǚǚř šẽẽẽăăăřçḩ.]",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "[Ŵẽẽẽ’řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ģăăăṯḩẽẽẽřìììกี้ģ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö çöööกี้ƒìììƌẽẽẽกี้ṯƚýýý šḩăăăřẽẽẽ ŵḩăăăṯ ăăăṯṯřăăăçṯš ṯḩẽẽẽm̂ ṯööö ăăă řöööƚẽẽẽ. Ŵẽẽẽ'ƚƚ ǚǚǚƥƌăăăṯẽẽẽ ṯḩẽẽẽ ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ƌööö.]",
    'In the meantime, you can adjust your filters to try a broader search.':
      '[Ïกี้ ṯḩẽẽẽ m̂ẽẽẽăăăกี้ṯìììm̂ẽẽẽ, ýýýöööǚǚǚ çăăăกี้ ăăăƌĵǚǚǚšṯ ýýýöööǚǚǚř ƒìììƚṯẽẽẽřš ṯööö ṯřýýý ăăă ßřöööăăăƌẽẽẽř šẽẽẽăăăřçḩ.]',
    'These are the drivers candidates consider when looking at roles.':
      '[Ṯḩẽẽẽšẽẽẽ ăăăřẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš çăăăกี้ƌìììƌăăăṯẽẽẽš çöööกี้šìììƌẽẽẽř ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ăăăṯ řöööƚẽẽẽš.]',
    'Drivers are ranked with the most important driver at the top.':
      '[Ḋřìììṽẽẽẽřš ăăăřẽẽẽ řăăăกี้ķẽẽẽƌ ŵìììṯḩ ṯḩẽẽẽ m̂öööšṯ ìììm̂ƥöööřṯăăăกี้ṯ ƌřìììṽẽẽẽř ăăăṯ ṯḩẽẽẽ ṯöööƥ.]',
    'Expand each driver for more detail.':
      '[Ëꭕƥăăăกี้ƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ƒöööř m̂öööřẽẽẽ ƌẽẽẽṯăăăìììƚ.]',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "[Ñöö ƌăăṯăă ăăṽăăììƚăăßƚẽẽ ýýẽẽṯ ƒööř {filter}. Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš.]",
    'Sample size for selected filters is small, so there is greater margin of error.':
      '[Šăăăm̂ƥƚẽẽẽ šìììƶẽẽẽ ƒöööř {filter} ìììš šm̂ăăăƚƚ, šööö ṯḩẽẽẽřẽẽẽ ìììš <u>ģřẽẽẽăăăṯẽẽẽř m̂ăăăřģìììกี้ öööƒ ẽẽẽřřöööř.</u>]',
    "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ":
      "[Ŵḩẽẽẽกี้ ŵřìììṯìììกี้ģ ĵöööß ăăăƌš, ƒöööçǚǚǚš öööกี้ ƌẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ ăăăกี้ƌ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ ýýýöööǚǚǚř çöööm̂ƥẽẽẽṯìììṯöööřš. Ḋöööกี้'ṯ ŵăăăšṯẽẽẽ šƥăăăçẽẽẽ öööกี้ กี้öööṯ-ßöööṯḩẽẽẽřẽẽẽƌ ƌřìììṽẽẽẽřš, ăăăกี้ƌ ḩìììģḩƚìììģḩṯ ƥǚǚǚṯ-öööƒƒš ẽẽẽăăăřƚýýý. ]",
    'How we define importance':
      '[Ḩöööŵ ŵẽẽẽ ƌẽẽẽƒìììกี้ẽẽẽ ìììm̂ƥöööřṯăăăกี้çẽẽẽ]',
    'Candidates have ranked each driver according to 4 levels of importance:':
      '[Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ řăăăกี้ķẽẽẽƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ăăăççöööřƌìììกี้ģ ṯööö 4 ƚẽẽẽṽẽẽẽƚš öööƒ ìììm̂ƥöööřṯăăăกี้çẽẽẽ:]',
    'Must-have: Essentials that candidates expect a job to have.':
      '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ: Ëššẽẽẽกี้ṯìììăăăƚš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]',
    'Delighted-if: Extras that differentiate you from other companies.':
      '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ: Ëꭕṯřăăăš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ öööṯḩẽẽẽř çöööm̂ƥăăăกี้ìììẽẽẽš.]',
    "Not-bothered: Factors that don't really matter to candidates.":
      '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ: Ƒăăăçṯöööřš ṯḩăăăṯ ƌöööกี้’ṯ řẽẽẽăăăƚƚýýý m̂ăăăṯṯẽẽẽř ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš.]',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      '[Ƥǚǚǚṯ-öööƒƒ: Ḋẽẽẽăăăƚßřẽẽẽăăăķẽẽẽřš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ƌöööกี้’ṯ ŵăăăกี้ṯ ìììกี้ ăăă řöööƚẽẽẽ.]',
    'Must-have': '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ]',
    'Delighted-if': '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ]',
    'Not-bothered': '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ]',
    'Put-off': '[Ƥǚǚǚṯ-öööƒƒ]',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      '[Ṯḩìììš ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš šöööřṯ ṯḩẽẽẽìììř ƥřẽẽẽƒẽẽẽřẽẽẽกี้çẽẽẽš ƒöööř ṯḩẽẽẽ çöööm̂ƥöööกี้ẽẽẽกี้ṯš öööƒ {filter}. Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ ṯḩẽẽẽ öööƥṯìììöööกี้ ṯööö çḩööööööšẽẽẽ m̂öööřẽẽẽ ṯḩăăăกี้ öööกี้ẽẽẽ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ.]',
  }),
});

export default translations;
