import type { ActionGroupFragment } from '@seek/cmsu-cms-connect';
import type { FindByTypename } from '../FindByTypename';
import { universal } from './renderers/universal';

/**
 * Union of all Action types
 */
type Action = Required<ActionGroupFragment['actions'][number]>;

/**
 * Returns the Type given the __typename
 */
type BlockOf<Type extends Action['__typename']> = FindByTypename<Action, Type>;

export type ActionRenderers = Partial<{
  [Property in Action['__typename']]: (block: BlockOf<Property>) => JSX.Element;
}>;

type Props = {
  renderers?: ActionRenderers;
  action: Action;
};

/**
 * Matches an Action to the given renders
 */
export const ActionRenderer = (
  { renderers = universal, action }: Props, // Setting `action` as any to suppress `never` errors
) => renderers[action.__typename]?.(action as any) || null;
