import type { Locale } from '@seek/melways-sites';
import type { RequireAtLeastOne } from '../types';
import { useLocale } from './useContext';

type LocaleContent<T> = { 'en-AU': T } & RequireAtLeastOne<{
  [K in Locale]: T;
}>;

const useLocalized = <T,>(content: LocaleContent<T>): T => {
  const locale = useLocale();
  const localizedContent = content[locale];

  /**
   * Fallback to 'en-AU' if requested region content isnt provided.
   * This means that 'en-AU' must be provided, even if it's `null`
   */
  if (localizedContent === undefined) {
    return content['en-AU'];
  }

  return localizedContent;
};

export { useLocalized, type LocaleContent };
