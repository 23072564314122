import React from 'react';
import { Stack, ContentBlock } from 'braid-design-system';
import { createForm } from '@seek/forms-ui';
import { Form } from './Form';
import type { RichTextContent } from '@graphcms/rich-text-types';
import type { Country, Language, Locale } from '@seek/melways-sites';
import type { FormType } from '@seek/cmsu-cms-connect';
import { VocabProvider } from '@vocab/react';
import { useCMSUContext } from '../../hooks/useCMSUContext';
export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companyPhoneNumber: string;
  companyName: string;
  jobTitle: string;
  industry: string;
  employerJobSeeker: '' | 'Employer' | 'Job Seeker';
  hiringNeeds: '' | 'Yes' | 'No';
  marketingComms: '' | 'Yes' | 'No';
  doesBusinessWithSEEK: '' | 'Yes' | 'No' | 'I am not sure';
}

const { FormProvider, useField } = createForm<FormValues, any, Language>();

type WebFormProps = {
  country: Country;
  locale: Locale;
  sourceName: string;
  privacyUrl: string;
  recaptchaKey: string;
  columns: number;
  formType: FormType;
  confirmationMessage: RichTextContent;
  document?: string;
  wptSalesForceApi: string;
  privacyLink: string;
};

export const WebForm = ({
  country,
  locale,
  sourceName,
  privacyUrl,
  recaptchaKey,
  columns,
  formType,
  document,
  confirmationMessage,
  wptSalesForceApi,
  privacyLink,
}: WebFormProps) => {
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    companyPhoneNumber: '',
    companyName: '',
    jobTitle: '',
    industry: '',
    employerJobSeeker: '',
    hiringNeeds: '',
    marketingComms: '',
    doesBusinessWithSEEK: '',
  };

  const { language } = useCMSUContext();

  return (
    <ContentBlock>
      <VocabProvider language={language}>
        <Stack space="xlarge">
          <FormProvider initialValues={initialValues} language={language}>
            {(formProps) => (
              <Form
                country={country}
                locale={locale}
                sourceName={sourceName}
                privacyUrl={privacyUrl}
                recaptchaKey={recaptchaKey}
                useField={useField}
                columns={columns}
                formType={formType}
                confirmationMessage={confirmationMessage}
                document={document}
                wptSalesForceApi={wptSalesForceApi}
                privacyLink={privacyLink}
                {...formProps}
              />
            )}
          </FormProvider>
        </Stack>
      </VocabProvider>
    </ContentBlock>
  );
};
