import { CTextAlign, type BannerFragment } from '@seek/cmsu-cms-connect';
import { Bleed, Box, Disclosure } from 'braid-design-system';

import {
  AccordionGroup,
  ActionGroup,
  Asset,
  Card,
  Heading,
  IconBlock,
  ImageBlock,
  StepBlock,
  InlineBanner,
  LOAIFrame,
  Paragraph,
  SearchBox,
  SocialMediaShare,
  TestimonialGroup,
  TabGroup,
  Alert,
  JobAd,
  ProfileBio,
  DocumentList,
  KeyStepsBlock,
  VideoDialog,
} from '../../../modules';
import { Video, VideoSrcType } from '../../../modules/Video/Video';
import {
  CustomComponentRenderer,
  type CustomComponentRenderers,
} from '../../CustomComponent';
import type { BlockItemRenderers } from '../BlockItemRenderer';
import { useTrackLink } from '@seek/cmsu-analytics';
import { ArticleBanner } from '../../../modules/Banner/CBannerArticle/ArticleBanner';
import { AssetType } from '../../../modules/Asset/Asset';
import {
  getBlockBorderProps,
  getBorderRadiusProps,
  getBoxBackgroundProps,
  getBoxHeightProps,
  getBoxWidthProps,
} from '../../../modules/PageComponent/helper';
import { Gallery } from '../../../modules/Gallery/Gallery';
import { BannerItem } from '../../../modules/BannerItem/BannerItem';
import type { DatalabComponent } from '../../../modules/Talent-Attraction-Lab/datalabHelper';
import { Tiles, getTileProps } from '../../../modules/Tiles/Tiles';

/**
 * Default BlockItem renderers for SEEK Hirer
 */
export const hirer: BlockItemRenderers = {
  CHeading: (props) => <Heading {...props} />,
  CParagraph: (paragraph) => (
    <Paragraph content={paragraph.Paragraph_text?.raw} {...paragraph} />
  ),
  CCardGroup: ({ cards, tile }) => (
    <Tiles {...getTileProps(tile)}>
      {cards.map((card) => {
        if (card.__typename === 'CCardPrimary') {
          return <Card key={card.id} {...card} />;
        }
        if (card.__typename === 'CCardJobAd') {
          return <JobAd key={card.id} {...card} />;
        }
      })}
    </Tiles>
  ),
  CActionGroup: (actionGroup) => (
    <ActionGroup size={actionGroup.actionGroupSize} {...actionGroup} />
  ),
  CGallery: ({ assets, tile, carousel }) => {
    if (carousel) {
      return <Gallery assets={assets} />;
    }
    return (
      <Tiles {...getTileProps(tile)}>
        {assets.map((image) => (
          // TODO: Add schema option for borderRadius
          <Box key={image.id} borderRadius={'xlarge'} overflow="hidden">
            <Asset {...image} assetType={AssetType.DEFAULT_IMAGE} />
          </Box>
        ))}
      </Tiles>
    );
  },
  CBanner: (data) => {
    const { banner_items } = data as BannerFragment;
    const { datalabSurvey } = data as DatalabComponent;
    if (banner_items?.__typename === 'CBannerArticle') {
      return <ArticleBanner banner={banner_items} />;
    }
    if (banner_items?.__typename === 'CBannerInline') {
      return (
        <InlineBanner bannerData={banner_items} datalabSurvey={datalabSurvey} />
      );
    }
    return null;
  },
  CIconBlockGroup: ({ items, tile, ...blockProps }) => (
    <Tiles {...getTileProps(tile)}>
      {items.map((item) => (
        <IconBlock key={item.id} {...item} {...blockProps} />
      ))}
    </Tiles>
  ),
  CImageBlockGroup: ({ items, tile, box, ...blockProps }) => (
    <Box
      {...getBlockBorderProps(box?.blockBorder)}
      {...getBorderRadiusProps(box?.borderRadius)}
      {...getBoxBackgroundProps(box?.background)}
      {...getBoxWidthProps(box?.paddingWidth)}
      {...getBoxHeightProps(box?.paddingHeight)}
    >
      <Tiles {...getTileProps(tile)}>
        {items.map((item) => (
          <ImageBlock
            key={item.id}
            {...item}
            {...blockProps}
            inlineTextJustify={box?.justifyContent}
          />
        ))}
      </Tiles>
    </Box>
  ),
  CStepBlockGroup: ({ items, tile, ...blockProps }) => (
    <Tiles {...getTileProps(tile)}>
      {items.map((item, index) => (
        <StepBlock key={item.id} index={index} {...item} {...blockProps} />
      ))}
    </Tiles>
  ),
  AccordionGroup: ({ accordions }) => (
    <AccordionGroup accordions={accordions} />
  ),
  CTestimonialGroup: ({ testimonials }) => (
    <TestimonialGroup testimonials={testimonials} />
  ),
  Disclosure: ({ id, content, label }) => {
    const onClickLink = useTrackLink('disclosure-label', {
      elementText: label,
      elementDesign: 'disclosure',
      elementAction: label,
    });
    return (
      <Disclosure
        collapseLabel={label}
        expandLabel={label}
        id={id}
        onToggle={() => (onClickLink ? onClickLink() : undefined)}
      >
        <Paragraph content={content.raw} />
      </Disclosure>
    );
  },
  CCustomComponent: ({ data }, additionalData) =>
    data ? (
      <CustomComponentRenderer
        data={data}
        renderers={customComponentRenderers}
        additionalData={additionalData}
      />
    ) : null,
  Video: ({ box, source }: any) => {
    if (!source) {
      return null;
    }
    const url =
      source.__typename === VideoSrcType.Brightcove
        ? source?.videoUrl
        : `https://www.youtube.com/embed/${source?.videoId}`;
    return (
      <Box
        background={box?.background || undefined}
        textAlign={box?.textAlign || 'left'}
        borderRadius="large"
      >
        <Box
          maxWidth="medium"
          style={{
            marginLeft: box?.textAlign === CTextAlign.Left ? 'auto' : undefined,
            marginRight:
              box?.textAlign === CTextAlign.Right ? 'auto' : undefined,
            margin: box?.textAlign === CTextAlign.Center ? 'auto' : undefined,
          }}
        >
          <Video url={url} type={source.__typename as VideoSrcType} />
        </Box>
      </Box>
    );
  },
  VideoDialogGroup: ({ videos, tile }) => (
    <Tiles {...getTileProps(tile)}>
      {videos.map((video, index) => (
        <VideoDialog
          key={index}
          title={video.title as string}
          description={video.description as string}
          url={video.video?.videoUrl as string}
        />
      ))}
    </Tiles>
  ),
  TabGroup: ({ tabs }) => <TabGroup tabs={tabs} />,
  CAlert: (alertProps) => <Alert {...alertProps} />,
  ProfileBio: (profileBioProps) => <ProfileBio {...profileBioProps} />,
  DocumentList: (documentListProps) => <DocumentList {...documentListProps} />,
  KeyStepsBlock: (props) => <KeyStepsBlock {...props} />,
  BannerItemList: (data) => (
    <>
      {data?.bannerItems?.map((item, i) => (
        <BannerItem key={i} item={item} />
      ))}
    </>
  ),
};

export const customComponentRenderers: CustomComponentRenderers = {
  SearchBox: () => (
    <Bleed top="none" bottom={'large'}>
      <SearchBox />
    </Bleed>
  ),
  LoaDataLab: ({ url }) => <LOAIFrame url={url} />,
  SocialMediaShare: (
    { bleedTop, alignment },
    { page: { seo, title }, fullURL },
  ) => (
    <Bleed top={bleedTop || 'none'}>
      <Box display={'flex'} justifyContent={alignment || 'flexStart'}>
        <SocialMediaShare
          pageUrl={fullURL}
          summary={seo?.description || title}
          title={seo?.title || title}
        />
      </Box>
    </Bleed>
  ),
};
