import React from 'react';

const Logo = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // width="170px"
    // height="68px"
    width="128px"
    height="51px"
    viewBox="0 0 170.1 68"
  >
    <g>
      <path
        fill="#000"
        d="M86.6,45.6c-3.7,0-7-0.4-10-3.4l3.8-3.8c1.9,1.9,4.5,2.2,6.3,2.2c2,0,4.1-0.7,4.1-2.4c0-1.2-0.6-2-2.5-2.2
		l-3.7-0.4c-4.2-0.4-6.9-2.3-6.9-6.6c0-4.9,4.3-7.5,9.1-7.5c3.7,0,6.7,0.6,9,2.8L92.3,28c-1.4-1.2-3.4-1.6-5.5-1.6
		c-2.3,0-3.3,1.1-3.3,2.3c0,0.9,0.4,1.9,2.4,2l3.7,0.4c4.6,0.5,7,2.9,7,6.9C96.6,43.1,92.2,45.6,86.6,45.6z"
      />
      <path
        fill="#000"
        d="M104.5,35.4c0,3,1.8,5.2,5.1,5.2c2.6,0,3.8-0.7,5.3-2.2l3.6,3.5c-2.4,2.4-4.7,3.7-8.9,3.7
		c-5.5,0-10.9-2.5-10.9-12c0-7.7,4.1-12,10.2-12c6.5,0,10.2,4.8,10.2,11.2v2.6L104.5,35.4L104.5,35.4z M112.9,28.9
		c-0.6-1.4-1.9-2.4-3.9-2.4c-2,0-3.3,1-3.9,2.4c-0.4,0.9-0.5,1.5-0.5,2.5h8.9C113.4,30.4,113.3,29.7,112.9,28.9z"
      />
      <path
        fill="#000"
        d="M127.7,35.4c0,3,1.9,5.2,5.1,5.2c2.6,0,3.8-0.7,5.3-2.2l3.6,3.5c-2.4,2.4-4.7,3.7-8.9,3.7
		c-5.5,0-10.9-2.5-10.9-12c0-7.7,4.2-12,10.2-12c6.5,0,10.2,4.8,10.2,11.2v2.6L127.7,35.4L127.7,35.4z M136.1,28.9
		c-0.6-1.4-1.9-2.4-3.9-2.4c-2,0-3.3,1-3.9,2.4c-0.4,0.9-0.5,1.5-0.5,2.5h8.9C136.6,30.4,136.4,29.7,136.1,28.9z"
      />
      <path
        fill="#000"
        d="M160.3,45.3l-5.8-9.8l-2.5,2.8v7h-5.9V13.2h5.9v18.2l7.8-9.6h7.1l-8.4,9.5l9,14L160.3,45.3L160.3,45.3z"
      />
    </g>
    <path
      fill="#0d3880"
      d="M34,1.5C16.1,1.5,1.5,16.1,1.5,34c0,18,14.6,32.5,32.5,32.5C52,66.5,66.5,52,66.5,34C66.5,16.1,52,1.5,34,1.5z
	 M8.3,41.7c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C8.8,41.5,8.5,41.7,8.3,41.7z M8.3,34.9
	c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5S8.5,34.9,8.3,34.9z M8.3,28.1c-0.3,0-0.5-0.2-0.5-0.5
	c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5C8.8,27.8,8.5,28.1,8.3,28.1z M11.8,42c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8
	c0.4,0,0.8,0.3,0.8,0.8C12.5,41.6,12.2,42,11.8,42z M11.8,35.2c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8
	C12.5,34.8,12.2,35.2,11.8,35.2z M11.8,28.3c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8
	C12.5,28,12.2,28.3,11.8,28.3z M15.9,42.2c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1C16.9,41.8,16.4,42.2,15.9,42.2z
	 M15.9,35.4c-0.6,0-1-0.5-1-1s0.5-1,1-1c0.6,0,1,0.5,1,1C16.9,35,16.4,35.4,15.9,35.4z M15.9,28.6c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1
	c0.6,0,1,0.5,1,1C16.9,28.1,16.4,28.6,15.9,28.6z M20.2,42.5c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
	C21.5,41.9,20.9,42.5,20.2,42.5z M20.2,35.7c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3S20.9,35.7,20.2,35.7z
	 M20.2,28.8c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3S20.9,28.8,20.2,28.8z M25.2,42.8c-0.8,0-1.5-0.7-1.5-1.5
	c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C26.8,42.1,26.1,42.8,25.2,42.8z M25.2,35.9c-0.8,0-1.5-0.7-1.5-1.5
	c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C26.8,35.2,26.1,35.9,25.2,35.9z M25.2,29.1c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5
	c0.8,0,1.5,0.7,1.5,1.5S26.1,29.1,25.2,29.1z M30.5,43c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8
	C32.3,42.2,31.5,43,30.5,43z M30.5,36.2c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S31.5,36.2,30.5,36.2z M30.5,29.3
	c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S31.5,29.3,30.5,29.3z M36.8,56.9c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2
	c1.1,0,2,0.9,2,2C38.8,56,37.9,56.9,36.8,56.9z M36.8,50.1c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2
	C38.8,49.2,37.9,50.1,36.8,50.1z M36.8,43.3c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C38.8,42.4,37.9,43.3,36.8,43.3z
	 M36.8,36.4c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C38.8,35.5,37.9,36.4,36.8,36.4z M36.8,29.6c-1.1,0-2-0.9-2-2
	c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2S37.9,29.6,36.8,29.6z M36.8,22.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2
	C38.8,21.9,37.9,22.8,36.8,22.8z M36.8,15.9c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C38.8,15,37.9,15.9,36.8,15.9z
	 M43.3,50.3c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C45.6,49.3,44.6,50.3,43.3,50.3z M43.3,43.5
	c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C45.6,42.5,44.6,43.5,43.3,43.5z M43.3,36.6c-1.2,0-2.2-1-2.2-2.2
	c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C45.6,35.6,44.6,36.6,43.3,36.6z M43.3,29.8c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
	c1.2,0,2.2,1,2.2,2.2C45.6,28.8,44.6,29.8,43.3,29.8z M43.3,23c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2
	C45.6,22,44.6,23,43.3,23z M50.4,43.8c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C52.9,42.6,51.7,43.8,50.4,43.8z M50.4,36.9c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C52.9,35.8,51.7,36.9,50.4,36.9z M50.4,30.1c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5
	C52.9,29,51.7,30.1,50.4,30.1z M57.5,37.2c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8c1.5,0,2.8,1.2,2.8,2.8
	C60.2,36,59,37.2,57.5,37.2z"
    />
  </svg>
);

export { Logo };
