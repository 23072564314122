import React from 'react';

export const SeekSemiDonutShape = ({ color }: { color: string }) => (
  <svg
    width="200"
    height="100"
    viewBox="0 0 200 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200ZM100 133.333C118.409 133.333 133.333 118.409 133.333 100C133.333 81.5905 118.409 66.6667 100 66.6667C81.5905 66.6667 66.6667 81.5905 66.6667 100C66.6667 118.409 81.5905 133.333 100 133.333Z"
      fill={color}
    />
  </svg>
);
