import { CustomComponent } from '@seek/cmsu-cms-connect';
import React from 'react';
import { QueryFilterBannerForm } from '../../Talent-Attraction-Lab/components/QueryFilterBanner/QueryFilterBannerForm';
import type { DatalabSurvey } from '../../Talent-Attraction-Lab/datalabHelper';
export const InlineBannerCustomComponent = ({
  componentType,
  datalabSurvey,
}: {
  componentType: CustomComponent;
  datalabSurvey?: DatalabSurvey;
}) => {
  switch (componentType) {
    case CustomComponent.TalLandingQueryFilterForm:
      return <QueryFilterBannerForm datalabSurvey={datalabSurvey} />;

    default:
      return null;
  }
};
