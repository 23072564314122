import { useResponsiveValue } from 'braid-design-system';
type ViewPoints = {
  isMobile: boolean | null;
  isDesktop: boolean | null;
  isTablet: boolean | null;
  isWide: boolean | null;
};
const useScreenSize = () => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    desktop: false,
    tablet: false,
    wide: false,
  });
  const isDesktop = responsiveValue({
    mobile: false,
    desktop: true,
    tablet: false,
    wide: false,
  });
  const isTablet = responsiveValue({
    mobile: false,
    desktop: false,
    tablet: true,
    wide: false,
  });
  const isWide = responsiveValue({
    mobile: false,
    desktop: false,
    tablet: false,
    wide: true,
  });

  return {
    isMobile,
    isDesktop,
    isTablet,
    isWide,
  };
};

export { useScreenSize, type ViewPoints };
