const HYPGRAPH_MEDIA_REGX = /graphassets.com/;

const WIDTH = 1200;
const HEIGHT = 630;

export const isHygraphMediaAsset = (imageUrl: string) =>
  HYPGRAPH_MEDIA_REGX.test(imageUrl);

export type ImageSizeTransformation = {
  width?: number;
  height?: number;
  fit?: 'max' | 'clip' | 'crop';
};

type ImageProps = {
  imageUrl: string;
} & ImageSizeTransformation;

export const resizeImageFromHygraph = ({
  imageUrl,
  width = WIDTH,
  height = HEIGHT,
  fit = 'crop',
}: ImageProps) => {
  if (!isHygraphMediaAsset(imageUrl)) {
    return imageUrl;
  }

  const transform = `resize=fit:${fit},width:${width},height:${height}`;
  return applyImageTransformationToUrl(imageUrl, transform);
};

export const applyImageTransformationToUrl = (
  imageUrl: string,
  transform: string,
) => {
  if (imageUrl.indexOf('resize=') > -1) return imageUrl;
  const imageSource = imageUrl.substring(0, imageUrl.lastIndexOf('/'));
  const imageId = imageUrl.substring(
    imageUrl.lastIndexOf('/') + 1,
    imageUrl.length,
  );
  return `${imageSource}/${transform}/${imageId}`;
};
