import type { UseQueryBannerFormFieldsTypes } from './useQueryFilterBannerFormFields';
import type { UseFieldsTypes } from '../components/useFields';
import { ALL_DRIVER } from '../datalabHelper';

export interface QueryFilterSelectionDisabled {
  primaryDriverOptionsDisabled: boolean;
  secondaryDriverDisabled: boolean;
  secondaryDriverOptionsDisabled: boolean;
}

interface Props {
  primaryDriverSelect:
    | UseFieldsTypes['primaryDriverSelect']
    | UseQueryBannerFormFieldsTypes['primaryDriverSelect'];
  primaryDriverOptionsSelect:
    | UseFieldsTypes['primaryDriverOptionsSelect']
    | UseQueryBannerFormFieldsTypes['primaryDriverOptionsSelect'];
  secondaryDriverSelect?: UseFieldsTypes['secondaryDriverSelect'];
}

export const useQueryFilterDisabledFormState = ({
  primaryDriverSelect,
  primaryDriverOptionsSelect,
  secondaryDriverSelect,
}: Props) => {
  const primaryDriverOptionsDisabled =
    primaryDriverSelect.value === '' ||
    primaryDriverSelect.value === ALL_DRIVER;

  const secondaryDriverDisabled =
    primaryDriverOptionsDisabled ||
    primaryDriverOptionsSelect.value.length === 0;

  const secondaryDriverOptionsDisabled =
    secondaryDriverSelect?.value === '' ||
    secondaryDriverSelect?.value === 'null';

  return {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  };
};
