import type { ElementNode } from '@graphcms/rich-text-types';
import type { ListType } from '../types';

const DEPTH_MAP = {
  1: 'alpha',
  2: 'roman',
} as Record<number, ListType>;

const addDepthToNumberedList = (
  items: ElementNode[],
  parentDepth: number = 0,
): ElementNode[] =>
  items.map((item: ElementNode) => {
    if ('type' in item && item.type === 'numbered-list') {
      const newItem = {
        ...item,
        depth: parentDepth + 1,
        listType: DEPTH_MAP[parentDepth + 1] || 'number',
      };

      return {
        ...newItem,
        children: addDepthToNumberedList(
          (newItem.children || []) as unknown as ElementNode[],
          newItem.depth,
        ),
      };
    } else if ('children' in item && item.children.length > 0) {
      const newItem = { ...item };

      return {
        ...newItem,
        children: addDepthToNumberedList(
          newItem.children as unknown as ElementNode[],
          parentDepth,
        ),
      };
    }

    return item;
  });

/**
 * The `formatNumberedList` function takes an array of elements and returns a new array with the same
 * elements, but with added depth to any numbered list elements.
 * @param {(ElementNode | Text)[]} elements - An array of elements, where each element can be either an
 * ElementNode or a Text.
 * @returns The function `formatNumberedList` returns an array of `ElementNode` or `Text` elements.
 */
export const formatNumberedList = (
  elements: (ElementNode | Text)[],
): (ElementNode | Text)[] => {
  const updatedElements = elements.map((element: ElementNode | Text) => {
    if ('type' in element && element.type === 'numbered-list') {
      return {
        ...element,
        children: addDepthToNumberedList(
          (element.children || []) as ElementNode[],
        ),
      };
    }

    return element;
  });

  return updatedElements;
};
