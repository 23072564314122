import { Box, Stack } from 'braid-design-system';
import React from 'react';
import * as styles from './styles.css';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const Table = ({ children }: Props) => (
  <Box className={styles.TableWrapperStyle}>
    <table className={styles.TableStyle}>{children}</table>
  </Box>
);

export const TableHead = ({ children }: Props) => (
  <Box
    className={[styles.TheadStyle, 'table_head']}
    component="thead"
    background="neutral"
  >
    {children}
  </Box>
);

export const TableHeaderCell = ({ children }: Props) => (
  <Box className={[styles.ThStyle, 'table_header_cell']} component="th">
    {children}
  </Box>
);

export const TableBody = ({ children }: Props) => (
  <tbody className={styles.TbodyStyle}>{children}</tbody>
);

export const TableRow = ({ children }: Props) => (
  <tr className={styles.TrStyle}>{children}</tr>
);

export const TableCell = ({ children }: Props) => (
  <Box
    className={[styles.TcellStyle, 'table_cell']}
    paddingX="small"
    paddingY="small"
    component="td"
  >
    {/* Using span because div can't be a child of <td/> */}
    <Stack space="medium" component="span">
      {children}
    </Stack>
  </Box>
);
