// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    readarticle: () =>
      | 'Read article'
      | 'อ่านบทความนี้'
      | 'Baca artikel'
      | '[Řẽẽăăƌ ăăřṯììçƚẽẽ]';
  }
>({
  en: createLanguage({ readarticle: 'Read article' }),
  th: createLanguage({ readarticle: 'อ่านบทความนี้' }),
  id: createLanguage({ readarticle: 'Baca artikel' }),
  zh: createLanguage({ readarticle: 'Read article' }),
  'en-AU': createLanguage({ readarticle: 'Read article' }),
  'en-HK': createLanguage({ readarticle: 'Read article' }),
  'en-ID': createLanguage({ readarticle: 'Read article' }),
  'en-MY': createLanguage({ readarticle: 'Read article' }),
  'en-NZ': createLanguage({ readarticle: 'Read article' }),
  'en-PH': createLanguage({ readarticle: 'Read article' }),
  'en-SG': createLanguage({ readarticle: 'Read article' }),
  'en-TH': createLanguage({ readarticle: 'Read article' }),
  'th-TH': createLanguage({ readarticle: 'อ่านบทความนี้' }),
  'id-ID': createLanguage({ readarticle: 'Baca artikel' }),
  'zh-HK': createLanguage({ readarticle: 'Read article' }),
  'en-PSEUDO': createLanguage({ readarticle: '[Řẽẽăăƌ ăăřṯììçƚẽẽ]' }),
});

export default translations;
