import type { LinkFragment } from '@seek/cmsu-cms-connect';

const getElementAction = (to?: LinkFragment['to']) => {
  let elementAction = '';
  if (!Boolean(to)) {
    return '';
  }
  switch (to?.__typename) {
    case 'ExternalUrl':
      const externalUrl = to?.externalUrl;
      if (externalUrl) {
        elementAction = externalUrl.includes('#')
          ? 'anchor'
          : 'external redirect';
      }
      break;
    default:
      elementAction = 'internal redirect';
  }
  return elementAction;
};
export { getElementAction };
