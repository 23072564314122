import { type Language, languages } from '@seek/melways-sites';
import {
  Locale,
  type GetPagesQuery,
  type CButtonLink,
  type CTextLink,
  type Section,
  type Maybe,
  type SectionWhereInput,
  type CImageLink,
} from '@seek/cmsu-cms-connect';

type Blocks = GetPagesQuery['pages'][0]['containers'][0]['blocks'];

type BlockItems = Blocks[0]['items'];

/**
 * Checks if the content is Not Available.
 * Added more line, remove this
 * If the content value is dash (-), it means the content isn't available.
 * @param text
 */
export const isContentNA = (text: any) => {
  if (!text) {
    return true;
  }

  return text.trim() === '-';
};

export const supportedDefaultLocales: string[] = [
  'default',
  'en_PH', // English (Philippines) Candidate
  'en_MY', // English (Malaysia) Candidate
  'en_HK', // English (Hong Kong)
  'en_PH', // English (Philippines)
  'en_SG', // English (Singapore)
  'en_ID', // English (Indonesia)
  'en_TH', // Thai (English)
  'en_AU', // English (Australia)
  'en_NZ', // English (New Zealand)
];

/**
 * Filters an array of block items to return only blocks that do not have default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleItems = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if ('locale' in item && item.locale === Locale.Default) {
      return false;
    }

    if (
      item.__typename === 'Video' &&
      item.source &&
      'locale' in item.source &&
      item.source.locale === Locale.Default
    ) {
      return false;
    }

    return true;
  });

/**
 * The function filters out block items contents that are not default locale.
 * @param blockItems
 */
export const filterOutDefaultLocaleContents = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.map((item) => {
    if (item.__typename === 'AccordionGroup') {
      return {
        ...item,
        accordions: item.accordions.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CCardGroup') {
      return {
        ...item,
        cards: item.cards.filter(
          (card) =>
            card.heading?.locale !== Locale.Default &&
            card.paragraph?.locale !== Locale.Default &&
            card.image?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'CGallery') {
      return {
        ...item,
        assets: item.assets.filter((a) => a.locale !== Locale.Default),
      };
    }

    if (item.__typename === 'CImageBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.image?.locale !== Locale.Default &&
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (item.__typename === 'TabGroup') {
      return {
        ...item,
        tabs: item.tabs.filter((t) => t.locale !== Locale.Default),
      };
    }

    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerInline'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          image:
            item.banner_items?.image?.locale !== Locale.Default
              ? item.banner_items?.image
              : undefined,
          heading:
            item.banner_items?.heading?.locale !== Locale.Default
              ? item.banner_items?.heading
              : undefined,
          actionGroup: item.banner_items?.actionGroup
            ? {
                ...item.banner_items?.actionGroup,
                actions: item.banner_items?.actionGroup.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          inlineDivider: item.banner_items?.inlineDivider
            ? {
                ...item.banner_items?.inlineDivider,
                actions: item.banner_items?.inlineDivider.actions.filter(
                  (a) => !isLinkItemWithDefaultLocale(a),
                ),
              }
            : undefined,
          paragraph:
            item.banner_items?.paragraph?.locale !== Locale.Default
              ? item.banner_items?.paragraph
              : undefined,
        },
      };
    }
    if (
      item.__typename === 'CBanner' &&
      item.banner_items &&
      item.banner_items.__typename === 'CBannerArticle'
    ) {
      return {
        ...item,
        banner_items: {
          ...item.banner_items,
          name:
            item.banner_items?.locale !== Locale.Default
              ? item.banner_items?.name
              : undefined,
        },
      };
    }

    if (item.__typename === 'CActionGroup') {
      return {
        ...item,
        actions: item.actions.filter((a) => !isLinkItemWithDefaultLocale(a)),
      };
    }

    if (item.__typename === 'CIconBlockGroup') {
      return {
        ...item,
        items: item.items.filter(
          (i) =>
            i.heading?.locale !== Locale.Default &&
            i.paragraph?.locale !== Locale.Default,
        ),
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'WebForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.locale !== Locale.Default ? { ...item.data } : undefined,
      };
    }

    if (
      item.__typename === 'CCustomComponent' &&
      item.data?.__typename === 'CForm'
    ) {
      return {
        ...item,
        data:
          item?.data?.form?.locale !== Locale.Default
            ? { ...item.data }
            : undefined,
      };
    }

    if (item.__typename === 'CAlert') {
      return {
        ...item,
        paragraph: {
          ...item.paragraph,
          Paragraph_text: {
            raw:
              item.paragraph?.locale !== Locale.Default
                ? item.paragraph?.Paragraph_text?.raw
                : undefined,
          },
        },
      };
    }

    return item;
  });

/**
 * The function keeps block items from an array that have content based on their type.
 * @param blockItems
 */
export const keepBlockItemsThatHaveContent = (
  blockItems: BlockItems,
): BlockItems =>
  blockItems.filter((item) => {
    if (item.__typename === 'AccordionGroup') {
      return item.accordions.length !== 0;
    }

    if (item.__typename === 'CImageBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CCardGroup') {
      return item.cards.length !== 0;
    }

    if (item.__typename === 'TabGroup') {
      return item.tabs.length !== 0;
    }

    if (item.__typename === 'CIconBlockGroup') {
      return item.items.length !== 0;
    }

    if (item.__typename === 'CActionGroup') {
      return item.actions.length !== 0;
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerInline') {
        return item.banner_items?.image !== undefined;
      }
    }

    if (item.__typename === 'CBanner') {
      if (item.banner_items?.__typename === 'CBannerArticle') {
        return item.banner_items?.name !== undefined;
      }
    }

    if (item.__typename === 'CCustomComponent') {
      return item.data?.__typename !== undefined;
    }

    if (item.__typename === 'CAlert') {
      return item.paragraph?.Paragraph_text?.raw !== undefined;
    }
    if (item.__typename === 'CGallery') {
      return item.assets.length !== 0;
    }

    return true;
  });

/**
 * The function filters out block items that do not have default locale and have no content.
 * @param blockItems
 */
export const filterBlockItemsByLocale = (blockItems: BlockItems): BlockItems =>
  keepBlockItemsThatHaveContent(
    filterOutDefaultLocaleContents(filterOutDefaultLocaleItems(blockItems)),
  );

/**
 * This function removes blocks that have an empty items array property.
 * @param blocks
 */
export const removeBlocksThatHaveHaveNoItems = (blocks: Blocks): Blocks =>
  blocks.filter((block) => block.items.length !== 0);

/**
 * The function formats blocks and removes blocks that have an empty items array property.
 * @param blocks
 */
export const formatBlocksForRendering = (blocks: Blocks): Blocks =>
  removeBlocksThatHaveHaveNoItems(
    blocks.map((b) => {
      const formattedItems = !b.skipDefaultLocale
        ? b.items
        : filterBlockItemsByLocale(b.items);

      return {
        ...b,
        items: formattedItems,
      };
    }),
  );

/**
 * Recursively creates the query for sections
 * NOTE: routes needs to be reverse with the child in the first order
 * @param routes
 * @returns
 */
export const getSectionQuery: (routes?: string[]) => SectionWhereInput = (
  routes,
) => {
  if (!routes || routes.length === 0) {
    return {};
  }

  const query: SectionWhereInput = {
    sectionName: routes[0],
  };

  if (routes.length > 1) {
    query.parentSection = getSectionQuery(routes.slice(1));
  }

  return query;
};

/**
 * Recursively gets the name of the sections from the loader's payload
 * @param section
 */
export const getAllSections: (
  section: Maybe<Section> | undefined,
  path?: string,
) => string[] = (section, path = '') => {
  if (!section) {
    return [];
  }

  let nestedSections: string[] = [];

  for (const key in section) {
    if (section.hasOwnProperty(key)) {
      const currentPath = path ? `${path}.${key}` : key;
      if (key === 'parentSection') {
        nestedSections = nestedSections.concat(
          getAllSections(section.parentSection, currentPath),
        );
      } else if (key === 'sectionName') {
        nestedSections.push(section.sectionName);
      }
    }
  }

  return nestedSections;
};

export const isLinkItemWithDefaultLocale = (item: {
  __typename?: string;
}): boolean => {
  const linkTypes = ['CButtonLink', 'CTextLink', 'CImageLink'];
  return (
    linkTypes.includes(item.__typename ?? '') &&
    (item as CButtonLink | CTextLink | CImageLink).locale === Locale.Default
  );
};

export const getRouteInfo = (routeArray: string[]) => {
  const routes = routeArray.filter(
    (r) => r !== '' && !languages.includes(r as Language),
  );

  const hasSubsections = routes.length > 1;
  const slug = hasSubsections ? routes.pop() : routes.slice().pop();
  const sections = routes;

  return { sections, slug };
};

export const isEverySectionsMatchesSectionsFromRoute = (
  sections: string[],
  sectionsFromRoute: string[],
): boolean => sections.every((section) => sectionsFromRoute.includes(section));
