import {
  Checkbox,
  Dropdown,
  TextField,
  Textarea,
  FieldLabel,
  Stack,
  FieldMessage,
} from 'braid-design-system';
import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import type { Language } from '@seek/forms-ui/lib/types';
import { disallowHtml, required } from '@seek/validators-js';
import React from 'react';
import type {
  DynamicFormValues,
  FormField,
  FormValidators,
  ValidatorOptions,
} from './types';
import { transformValueToValidator } from './helper';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

type TextFieldType = 'text' | 'number' | 'email';

const mapTextFieldType: Record<string, TextFieldType> = {
  FormInputText: 'text',
  FormInputNumber: 'number',
  FormInputEmail: 'email',
};

const renderFieldComponent = (
  field: FormField,
  fieldHook: any,
): JSX.Element => {
  switch (field.__typename) {
    case 'FormInputText':
    case 'FormInputNumber':
    case 'FormInputEmail':
      return (
        <TextField
          id={field.key}
          aria-label="last-name-label"
          label={field.inputLabel}
          secondaryLabel={field.required ? null : 'optional'}
          type={mapTextFieldType[field.__typename]}
          message={fieldHook.errorMessage}
          tone={!fieldHook.valid ? 'critical' : 'neutral'}
          value={fieldHook.value}
          placeholder={field.placeholder || ''}
          onChange={fieldHook.onChange}
        />
      );
    case 'FormSelect':
      return (
        <Dropdown
          id={field.key}
          aria-label="last-name-label"
          label={field.selectLabel}
          secondaryLabel={field.required ? null : 'optional'}
          message={fieldHook.errorMessage}
          tone={!fieldHook.valid ? 'critical' : 'neutral'}
          value={fieldHook.value}
          onChange={fieldHook.onChange}
          placeholder={field.placeholder || ''}
        >
          {field.options.map((item) => (
            <option key={item.label}>{item.value}</option>
          ))}
        </Dropdown>
      );
    case 'FormTextarea':
      return (
        <Textarea
          id={field.key}
          message={fieldHook.errorMessage}
          tone={!fieldHook.valid ? 'critical' : 'neutral'}
          label={field.textareaLabel}
          secondaryLabel={field.required ? null : 'optional'}
          onChange={fieldHook.onChange}
          placeholder={field.placeholder || ''}
          value={fieldHook.value}
        />
      );
    case 'FormCheckbox':
      return (
        <Checkbox
          id={field.key}
          message={fieldHook.errorMessage}
          tone={!fieldHook.valid ? 'critical' : 'neutral'}
          checked={fieldHook.value}
          onChange={fieldHook.onChange}
          label={field.checkboxLabel}
        />
      );
    case 'FormCheckboxGroup':
      return (
        <Stack space="small">
          <FieldLabel
            htmlFor={'checkbox-group'}
            label={field.label}
            secondaryLabel={field.required ? null : 'optional'}
          />
          {field.options.map((option) => (
            <Checkbox
              key={option.name}
              id={option.name}
              checked={fieldHook.value[option.name]}
              onChange={() =>
                fieldHook.onChange({
                  ...fieldHook.value,
                  [option.name]: !fieldHook.value[option.name],
                })
              }
              label={option.checkboxLabel}
            />
          ))}
          {fieldHook.errorMessage ? (
            <FieldMessage
              id="error-message"
              tone="critical"
              message={fieldHook.errorMessage}
            />
          ) : (
            <></>
          )}
        </Stack>
      );
    default:
      return (
        <>
          <div>not supported</div>
        </>
      );
  }
};

export const RenderField = ({
  field,
  useField,
}: {
  field: FormField;
  useField: UseField<DynamicFormValues, Language>;
}) => {
  const { t } = useTranslations(translations);

  const basicValidators = [disallowHtml];
  const validatorsFromHygraph =
    field.__typename === 'FormSelect' || field.__typename === 'FormCheckbox'
      ? []
      : field.validators.map((value: ValidatorOptions) =>
          transformValueToValidator(value, t),
        );
  const requiredCheckedValidator = {
    getErrorMessage: () => Promise.resolve(t('Required field')),
    test: ({ value }: { value: boolean }) => value === true,
  };
  const requiredCheckedGroupValidator = {
    getErrorMessage: () => Promise.resolve(t('Required field')),
    test: ({ value: obj }: { value: object }) =>
      Object.values(obj).some((value) => value === true),
  };

  const validators: FormValidators = [
    ...validatorsFromHygraph,
    ...basicValidators,
  ];

  if (field.required && field.__typename === 'FormCheckbox') {
    validators.unshift(requiredCheckedValidator);
  } else if (field.required && field.__typename === 'FormCheckboxGroup') {
    validators.unshift(requiredCheckedGroupValidator);
  } else if (field.required) {
    validators.unshift(required);
  }

  const fieldHook = useField({
    id: field.key,
    validators,
    validatorProps: { requiredMessage: t('Required field') },
  });

  return renderFieldComponent(field, fieldHook);
};
