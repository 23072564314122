import type { TabGroupFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import {
  TabsProvider,
  TabPanels,
  Stack,
  Tabs,
  Tab,
  TabPanel,
} from 'braid-design-system';
import React from 'react';

type Props = Pick<TabGroupFragment, 'tabs'>;

export const TabGroup = ({ tabs }: Props) => {
  const tabsLabels = tabs.map((tab) => ({ id: tab.id, label: tab.label }));
  const tabsContents = tabs.map((tab) => ({
    id: tab.id,
    content: tab.content,
  }));
  const tabsIds = tabs.map((tab) => tab.id);

  return (
    <TabsProvider id={`TabsProvider-${tabsIds.join('-')}`}>
      <Stack space="medium">
        <Tabs label={`Tabs-${tabsIds.join('-')}`}>
          {tabsLabels.map((item) => (
            <Tab key={item.id}>{item.label}</Tab>
          ))}
        </Tabs>
        <TabPanels>
          {tabsContents.map((item) => (
            <TabPanel key={item.id}>
              <RichText content={item.content.raw} />
            </TabPanel>
          ))}
        </TabPanels>
      </Stack>
    </TabsProvider>
  );
};
