import React from 'react';
import { Box, Text, List, IconTick } from 'braid-design-system';
import type { RichTextContent } from '@graphcms/rich-text-types';
import {
  RichText,
  renderers,
  type NodeRendererType,
} from '@seek/cmsu-rich-text';
import type { ParagraphFragment } from '@seek/cmsu-cms-connect';

type Props = Pick<ParagraphFragment, 'align' | 'tone' | 'size'> & {
  content: RichTextContent;
  marginBottom?: ParagraphFragment['marginBottom'];
};

export const Paragraph = ({
  content,
  align,
  tone,
  size,
  marginBottom,
}: Props) => {
  const paragraph: NodeRendererType['p'] = ({ children }) => (
    <Box marginBottom={marginBottom || 'none'}>
      <Text
        component="p"
        tone={tone || undefined}
        align={align || undefined}
        size={size || undefined}
      >
        {children}
      </Text>
    </Box>
  );

  /**
   * Overriding the heading renderers so they render as paragraphs as well
   */
  const paragraphRenderers: NodeRendererType = {
    ...renderers,
    p: paragraph,
    h1: paragraph,
    h5: paragraph,
    h6: paragraph,
    ul: ({ children }) => (
      <List space={'medium'} type="icon" icon={<IconTick tone="formAccent" />}>
        {children}
      </List>
    ),
  };

  return <RichText renderers={paragraphRenderers} content={content} />;
};
