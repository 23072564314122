import type { RenderParams } from '@seek/forms-ui';
import { useTranslations } from '@vocab/react';
import { Box, Button, Inline, Stack } from 'braid-design-system';
import { useState } from 'react';
import translations from '../../../../.vocab';
import { useDriverFilterTranslation } from '../../hooks/useDriverFilterTranslation';
import { useGenerateSingleDriverList } from '../../hooks/useGenerateSingleDriverList';
import { useQueryFilterBannerFormFields } from '../../hooks/useQueryFilterBannerFormFields';
import { useQueryFilterDisabledFormState } from '../../hooks/useQueryFilterDisabledFormState';
import { DriverFilterSelector } from '../DriverFilterSelector';
import type { DriverList } from '../QueryFilter';
import type { FormValues } from '../QueryFilterForm';
import { datalabHelper } from '../../datalabHelper';

interface Props {
  driverList: DriverList;
  handleSubmit: RenderParams<FormValues>['handleSubmit'];
  clearField: (fieldName: keyof FormValues) => void;
}

export const BannerQueryFilter = ({
  driverList,
  handleSubmit,
  clearField,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslations(translations);

  const formFields = useQueryFilterBannerFormFields();
  const { primaryDriverSelect, primaryDriverOptionsSelect } = formFields;

  const { primaryDriverOptionsDisabled } = useQueryFilterDisabledFormState({
    primaryDriverSelect,
    primaryDriverOptionsSelect,
  });

  const { primaryDriverList, primaryOptionsList } =
    useGenerateSingleDriverList(driverList);

  const { primaryDriverFilter } = useDriverFilterTranslation();

  const onSubmit = handleSubmit((formValues: FormValues) => {
    setIsLoading(true);
    datalabHelper.querySubmitHandler(formValues);
  });

  const buttonLoading = isLoading ? { loading: true } : {};
  if (primaryDriverOptionsDisabled) clearField('primaryDriverOptionsSelect');

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Box>
        <Stack space="large">
          <DriverFilterSelector
            id="PRIMARY_FILTER"
            driver={primaryDriverSelect}
            driverList={primaryDriverList}
            driverOptionsList={primaryOptionsList}
            driverOptionsSelect={primaryDriverOptionsSelect}
            filterProp={primaryDriverFilter}
            driverDisabled={false}
            driverOptionDisabled={primaryDriverOptionsDisabled}
            isDark={true}
          />
          <Inline space="small" collapseBelow="tablet">
            <Box style={{ minWidth: '155px' }}>
              <Button
                {...buttonLoading}
                variant="solid"
                type="submit"
                size="standard"
                tone="brandAccent"
              >
                {t('Apply filters')}
              </Button>
            </Box>
          </Inline>
        </Stack>
      </Box>
    </form>
  );
};
