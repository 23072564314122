import { Box, Email, Item, Span } from 'react-html-email';
import React from 'react';

const emailHeadCSS = `
  body {
    background-color: #F5F8FA;
  }
`.trim();

const backgroundStyle = {
  WebkitBoxShadow: '6px 6px 40px 3px rgba(140, 152, 164, 0.2)',
  backgroundColor: '#FFF',
  borderRadius: 7,
  boxShadow: '6px 6px 40px 3px rgba(140, 152, 164, 0.2)',
  margin: '0 auto',
  width: '100%',
  padding: '0 32px',
};

const containerStyle = {
  backgroundColor: '#F5F8FA',
  width: '100%',
};

const itemsValue = {
  fontSize: '22px',
  fontWeight: 'normal',
};

type Props = {
  company: string;
  email: string;
  name: string;
  subject: string;
  enquiry: string;
};

const EmailPreview = () => (
  <EmailContentComponent
    name="Sample Name"
    email="sampleName@seek.com.au"
    company="Seek"
    subject="SARAs Enquiry"
    enquiry="Help me please"
  />
);

const EmailContentComponent = ({
  name,
  email,
  company,
  enquiry,
  subject,
}: Props) => (
  <Box align="center" style={containerStyle}>
    <Email align="center" headCSS={emailHeadCSS} title={'SARAs Enquiry'}>
      <Item style={{ height: 45 }} />
      <Item align="center">
        <Box style={backgroundStyle}>
          <Item style={{ height: 80 }} />
          <Item>
            <Span fontSize={40} fontWeight="bold">
              {subject}
            </Span>
          </Item>
          <Item style={{ height: 25 }} />
          <Item>
            <Span fontSize={22} fontWeight="bold">
              Name: <Span style={itemsValue}>{name}</Span>
            </Span>
          </Item>
          <Item style={{ height: 25 }} />
          <Item>
            <Span fontSize={22} fontWeight="bold">
              Company: <Span style={itemsValue}>{company}</Span>
            </Span>
          </Item>
          <Item style={{ height: 25 }} />
          <Item style={{ height: 25 }} />
          <Item>
            <Span fontSize={22} fontWeight="bold">
              Email: <Span style={itemsValue}>{email}</Span>
            </Span>
          </Item>
          <Item>
            <Span fontSize={22} fontWeight="bold">
              Enquiry: <Span style={itemsValue}>{enquiry}</Span>
            </Span>
          </Item>
          <Item style={{ height: 25 }} />
        </Box>
      </Item>
    </Email>
  </Box>
);

export { EmailPreview, EmailContentComponent };
