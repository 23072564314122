import { createForm } from '@seek/forms-ui';
import {
  ALL_DRIVER,
  datalabHelper,
  type DatalabSurvey,
} from '../../datalabHelper';
import type { DriverList } from '../QueryFilter';
import type { FormValues } from '../QueryFilterForm';
import { BannerQueryFilter } from './BannerQueryFilter';

const { FormProvider, useField: useQueryFilterBannerField } =
  createForm<FormValues>();
export { useQueryFilterBannerField };

export const QueryFilterBannerForm = ({
  datalabSurvey,
}: {
  datalabSurvey?: DatalabSurvey;
}) => {
  if (datalabSurvey === undefined) return null;

  const { queryFilters } = datalabSurvey;
  const driverList: DriverList = datalabHelper.getDriverListFrom(queryFilters);
  const initialValues: FormValues = {
    primaryDriverSelect: ALL_DRIVER,
    primaryDriverOptionsSelect: [],
    secondaryDriverSelect: '',
    secondaryDriverOptionsSelect: [],
  };
  return (
    <FormProvider initialValues={initialValues}>
      {({ handleSubmit, setFieldValue }) => {
        const clearField = (fieldName: keyof FormValues) => {
          let emptyValue;
          if (Array.isArray(initialValues[fieldName])) {
            emptyValue = [];
          } else {
            emptyValue = fieldName === 'primaryDriverSelect' ? ALL_DRIVER : '';
          }
          setFieldValue(fieldName, emptyValue);
        };

        return (
          <BannerQueryFilter
            driverList={driverList}
            handleSubmit={handleSubmit}
            clearField={clearField}
          />
        );
      }}
    </FormProvider>
  );
};
