type SearchParamDelimiter = '_';

// Only underscore is supported as delimiter due to LOA using commas in query params
const VALID_DELIMITERS: ReadonlyArray<SearchParamDelimiter> = ['_'] as const;

/**
 * Splits a search parameter string into an array of strings.
 * @param {string} str - The string to split.
 * @param {SearchParamDelimiter} [delimiter='_'] - The delimiter to use for splitting.
 * @returns {string[]} An array of split strings.
 * @throws {Error} If an invalid delimiter is provided.
 */
export const splitSearchParamString = (
  str: string,
  delimiter: SearchParamDelimiter = '_',
): string[] => {
  if (!str) {
    return [];
  }
  if (!VALID_DELIMITERS.includes(delimiter)) {
    throw new Error(
      `Invalid delimiter. Only "${VALID_DELIMITERS.join(
        '" or "',
      )}" are allowed.`,
    );
  }
  return str.split(delimiter);
};

/**
 * Joins an array of strings into a search parameter string.
 * @param {string[]} arr - The array of strings to join.
 * @param {SearchParamDelimiter} [delimiter='_'] - The delimiter to use for joining.
 * @returns {string} The joined search parameter string.
 * @throws {Error} If an invalid delimiter is provided.
 */
export const joinSearchParamString = (
  arr: string[],
  delimiter: SearchParamDelimiter = '_',
): string => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return '';
  }
  if (!VALID_DELIMITERS.includes(delimiter)) {
    throw new Error(
      `Invalid delimiter. Only "${VALID_DELIMITERS.join(
        '" or "',
      )}" are allowed.`,
    );
  }
  return arr.join(delimiter);
};

// Add a new function to validate the delimiter
export const isValidDelimiter = (
  delimiter: string,
): delimiter is SearchParamDelimiter =>
  VALID_DELIMITERS.includes(delimiter as SearchParamDelimiter);
