import { Box, Text } from 'braid-design-system';
import React, { type PropsWithChildren } from 'react';
import { vars } from 'braid-design-system/css';

export const BlockQuote = ({ children }: PropsWithChildren) => (
  <Box
    paddingLeft={{ mobile: 'medium', desktop: 'large' }}
    paddingY="xsmall"
    marginY="large"
    style={{
      borderStyle: 'solid',
      borderColor: vars.borderColor.brandAccent,
      borderLeftWidth: vars.borderWidth.large,
    }}
  >
    <Text size="large">{children}</Text>
  </Box>
);
