import { Box } from 'braid-design-system';
import { SeekDonutShape } from '../images/SeekDonutShape';
import { SeekSemiDonutShape } from '../images/SeekSemiDonutShape';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { vars } from 'braid-design-system/css';

export const TALDecoration = () => {
  const { isMobile } = useScreenSize();
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        maxWidth: vars.contentWidth.large,
        height: '100%',
        inset: 0,
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: '10%',
          right: 0,
          transform: isMobile ? 'translateX(50%)' : 'translateX(0%)',
        }}
        position={'absolute'}
      >
        <SeekDonutShape color={vars.backgroundColor.formAccent} />
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          right: isMobile ? '10%' : '8%',
          height: '100px',
        }}
      >
        <SeekSemiDonutShape color={vars.backgroundColor.formAccent} />
      </Box>
    </div>
  );
};
