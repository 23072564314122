import {
  type ElementNode,
  EmptyElementsToRemove,
  isEmpty,
  isElement,
  elementTypeKeys,
} from '@graphcms/rich-text-types';
import React, { Fragment } from 'react';
import { logger } from './utils/logger';
import type { ListType, RichTextProps } from './types';
import { RenderNode } from './RenderNode';
import { List } from 'braid-design-system';

export function RenderElement({
  index,
  element,
  renderers,
  references,
  listType,
}: {
  index?: number;
  element: ElementNode;
  renderers?: RichTextProps['renderers'];
  references?: RichTextProps['references'];
  listType?: ListType;
}): JSX.Element {
  const { children, type, ...rest } = element;
  const { nodeId, nodeType } = rest;

  // Checks if the element is empty so that it can be removed.
  if (type in EmptyElementsToRemove && isEmpty({ children })) {
    return <Fragment />;
  }

  const isEmbed = nodeId && nodeType;

  /**
   * The .filter method returns an array with all elements found.
   * Since there won't be duplicated ID's, it's safe to use the first element.
   */
  const referenceValues = isEmbed
    ? references?.filter((ref) => ref.id === nodeId)[0]
    : null;

  /**
   * `id` is used to correctly find the props for the reference.
   * If it's not present, we show an error and render a Fragment.
   */
  if (isEmbed && !referenceValues?.id) {
    logger.error(
      `[@seek/cmsu-rich-text]: No id found for embed node ${nodeId}. In order to render custom embeds, \`id\` is required in your reference query.`,
    );

    return <Fragment />;
  }

  /**
   * `mimeType` is used to determine if the node is an image or a video.
   * That's why this is required and we show an error if it's not present.
   * Only for custom assets embeds.
   */
  if (isEmbed && nodeType === 'Asset' && !referenceValues?.mimeType) {
    logger.error(
      `[@seek/cmsu-rich-text]: No mimeType found for embed node ${nodeId}. In order to render custom assets, \`mimeType\` is required in your reference query.`,
    );

    return <Fragment />;
  }

  /**
   * `url` is needed to correctly render the image, video, audio or any other asset
   * Only for custom assets embeds.
   */
  if (isEmbed && nodeType === 'Asset' && !referenceValues?.url) {
    logger.error(
      `[@seek/cmsu-rich-text]: No url found for embed node ${nodeId}. In order to render custom assets, \`url\` is required in your reference query.`,
    );

    return <Fragment />;
  }

  /**
   * There's two options if the element is an embed.
   * 1. If it isn't an asset, then we simply try to use the renderer for that model.
   *  1.1 If we don't find a renderer, we render a Fragment and show a warning.
   * 2. If it is an asset, then:
   *  2.1 If we have a custom renderer for that specific mimeType, we use it.
   *  2.2 If we don't have, we use the default mimeType group renderer (application, image, video...).
   */
  let elementToRender;

  // Option 1
  if (isEmbed && nodeType !== 'Asset') {
    const elm =
      type === 'link'
        ? renderers?.link?.[nodeType]
        : renderers?.embed?.[nodeType];

    if (elm !== undefined) {
      elementToRender = elm;
    } else {
      // Option 1.1
      logger.warn(
        `[@seek/cmsu-rich-text]: No renderer found for custom ${type} nodeType ${nodeType}.`,
      );
      return <Fragment />;
    }
  }

  // Option 2
  if (isEmbed && nodeType === 'Asset') {
    const elm = renderers?.Asset?.[referenceValues?.mimeType];

    // Option 2.1
    if (elm !== undefined) {
      elementToRender = elm;
    } else {
      // Option 2.2
      const mimeTypeGroup = referenceValues?.mimeType.split('/')[0];
      elementToRender = renderers?.Asset?.[mimeTypeGroup];
    }
  }

  const elementNodeRenderer = isEmbed
    ? elementToRender
    : renderers?.[elementTypeKeys[type] as keyof RichTextProps['renderers']];

  const NodeRenderer = elementNodeRenderer;

  const props = { ...rest, ...referenceValues };

  if (!NodeRenderer) {
    return <Fragment />;
  }

  if (
    // Unwrap list-item-child if it has another list as a child (nested list).
    // This prevents "Text cannot be a child of Text" Braid errors.
    type === 'list-item-child' &&
    children.some(
      (node) =>
        isElement(node) &&
        (node.type === 'bulleted-list' ||
          node.type === 'numbered-list' ||
          node.type === 'paragraph'),
    )
  ) {
    return (
      <>
        {children.map((listItem, idx) => (
          <RenderNode
            key={idx}
            index={idx}
            node={listItem}
            parent={null}
            renderers={renderers}
            references={references}
          />
        ))}
      </>
    );
  }

  if (type === 'numbered-list' && children.length !== 0) {
    return (
      <List space={'medium'} type={listType || 'number'}>
        {children.map((listItem, idx) => (
          <RenderNode
            key={idx}
            index={idx}
            node={listItem}
            parent={null}
            renderers={renderers}
            references={references}
          />
        ))}
      </List>
    );
  }

  return (
    <NodeRenderer {...props} index={index}>
      {children.map((listItem, idx) => (
        <RenderNode
          key={idx}
          index={idx}
          node={listItem}
          parent={null}
          renderers={renderers}
          references={references}
        />
      ))}
    </NodeRenderer>
  );
}
