import { useTranslations } from '@vocab/react';
import translations from '../../../.vocab';
import translations_tal from '../.vocab';

export const useDriverFilterTranslation = () => {
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);

  return {
    primaryDriverFilter: {
      filterLabel: t_tal('Explore drivers by'),
      filterPlaceholder: t('Select a filter'),
      optionFilter: {
        filterLabel: t('Select up to two options'),
        filterPlaceholder: t('Select options'),
      },
    },

    secondaryDriverFilter: {
      filterLabel: t('Filter by'),
      filterPlaceholder: t('Select a filter'),
      optionFilter: {
        filterLabel: t('Select up to two options'),
        filterPlaceholder: t('Select options'),
      },
    },
  };
};
