import { createValidator, required } from '@seek/validators-js';
import translations from '../.vocab';
import genericTranslations from '../../../.vocab';
import { useTranslations } from '@vocab/react';
import { ALL_DRIVER } from '../datalabHelper';
import { useQueryFilterBannerField } from '../components/QueryFilterBanner/QueryFilterBannerForm';
export type UseQueryBannerFormFieldsTypes = ReturnType<
  typeof useQueryFilterBannerFormFields
>;

interface PrimaryDriverOptionsProps {
  value: string;
  validatorProps: {
    primaryDriverValue: string;
  };
}

export const { primaryDriverOptionsValidator } = createValidator({
  test: ({ value, validatorProps }: PrimaryDriverOptionsProps) => {
    const { primaryDriverValue } = validatorProps;

    if (primaryDriverValue === ALL_DRIVER) {
      return true;
    }

    // could be modified to check array for when we want more than one option
    if (value && value.length > 0) {
      return true;
    }

    return false;
  },
  formatErrorMessages: (messages) => () =>
    messages['Select at least one option'].format(),
  translations,
  validatorName: 'primaryDriverOptionsValidator',
});

interface SecondaryDriverOptionsProps {
  value: string;
  validatorProps: {
    primaryDriverValue: string;
  };
}

// this can also be changed to validate in case our options
// become an array of strings
export const { secondaryOptionsValidator } = createValidator({
  test: ({ validatorProps }: SecondaryDriverOptionsProps) => {
    const { primaryDriverValue } = validatorProps;

    if (primaryDriverValue === ALL_DRIVER) {
      return true;
    }

    return false;
  },
  formatErrorMessages:
    (messages) =>
    ({}) =>
      messages['Select at least one option'].format(),
  translations,
  validatorName: 'secondaryOptionsValidator',
});

export const useQueryFilterBannerFormFields = () => {
  const { t } = useTranslations(genericTranslations);

  const primaryDriverSelect = useQueryFilterBannerField({
    id: 'primaryDriverSelect',
    validators: [required],
    validatorProps: {
      requiredMessage: t('Select an option'),
    },
  });
  const primaryDriverOptionsSelect = useQueryFilterBannerField({
    id: 'primaryDriverOptionsSelect',
    validators: [primaryDriverOptionsValidator as any],
    validatorProps: {
      primaryDriverValue: primaryDriverSelect.value,
    },
  });

  return {
    primaryDriverSelect,
    primaryDriverOptionsSelect,
  };
};
