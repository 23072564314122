import {
  type ElementNode,
  isElement,
  isText,
  type Node,
} from '@graphcms/rich-text-types';
import React, { Fragment } from 'react';
import { logger } from './utils/logger';
import { RenderElement } from './RenderElement';
import { RenderText } from './RenderText';
import type { ListType, RichTextProps } from './types';

export function RenderNode({
  index,
  node,
  parent,
  renderers,
  references,
}: {
  index?: number;
  node: Node & { listType?: ListType };
  parent: Node | null;
  renderers?: RichTextProps['renderers'];
  references?: RichTextProps['references'];
}) {
  if (isText(node)) {
    const text = node.text;

    const shouldSerialize = Boolean(
      parent && isElement(parent) && parent.type !== 'code-block',
    );

    return (
      <RenderText
        textNode={{ ...node, text }}
        renderers={renderers}
        shouldSerialize={shouldSerialize}
      />
    );
  }

  if (isElement(node)) {
    // We have a particular use case where if there is a image link we need to support backwards compatibility due to migration.
    // This is to support ticket number WP-2886
    if (
      node.type === 'paragraph' &&
      node.children[0] &&
      isElement(node.children[0]) &&
      node.children[0].type === 'link' &&
      node.children[0].children[0] &&
      isElement(node.children[0].children[0]) &&
      node.children[0]?.children[0].type === 'image'
    ) {
      return (
        <RenderElement
          element={node?.children[0] as ElementNode}
          renderers={renderers}
          references={references}
          index={index}
          listType={node?.listType}
        />
      );
    }

    return (
      <RenderElement
        element={node}
        renderers={renderers}
        references={references}
        index={index}
        listType={node?.listType}
      />
    );
  }

  const { type } = node;

  logger.warn(`[@seek/cmsu-rich-text]: Unknown node type encountered: ${type}`);

  return <Fragment />;
}
