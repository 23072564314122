import { createForm } from '@seek/forms-ui';
import type { Country } from '@seek/melways-sites';
import type { ButtonStyleProps } from '../types';
import type { FormFragment } from '@seek/cmsu-cms-connect';
import { VocabProvider } from '@vocab/react';
import { ContentBlock, Alert, Text } from 'braid-design-system';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { Form } from './Form';
import React from 'react';
import type { DynamicFormValues } from './types';

const createFormValues = (fields: any[]): DynamicFormValues => {
  const formValues: DynamicFormValues = {};
  fields.forEach((field) => {
    if (field.__typename === 'FormCheckboxGroup') {
      field.options.forEach((option: { name: string | number }) => {
        if (!formValues.hasOwnProperty(field.key)) formValues[field.key] = {};
        formValues[field.key][option.name] = false;
      });
    } else {
      formValues[field.key] = '';
    }
  });
  return formValues;
};

export const CForm = ({
  locale,
  sourceName,
  privacyUrl,
  recaptchaKey,
  country,
  wptSalesForceApi,
  form,
}: {
  locale: string;
  sourceName: string;
  privacyUrl: string;
  recaptchaKey: string;
  country: Country;
  wptSalesForceApi: string;
  submitBtnStyle?: ButtonStyleProps;
  form: FormFragment;
}) => {
  const { FormProvider, useField } = createForm<DynamicFormValues>();
  const { language } = useCMSUContext();

  const formValues: DynamicFormValues = createFormValues(
    form && form.fields ? form.fields : [],
  );
  const initialValues: DynamicFormValues | null = formValues;

  return (
    <ContentBlock>
      <VocabProvider language={language}>
        {form ? (
          <FormProvider initialValues={initialValues}>
            {(formProps) => (
              <Form
                {...formProps}
                locale={locale}
                useField={useField}
                recaptchaKey={recaptchaKey}
                country={country}
                sourceName={sourceName}
                privacyUrl={privacyUrl}
                wptSalesForceApi={wptSalesForceApi}
                form={form}
              />
            )}
          </FormProvider>
        ) : (
          <Alert tone="caution">
            <Text>
              If you&apos;re seeing this, the form might not be published
            </Text>
          </Alert>
        )}
      </VocabProvider>
    </ContentBlock>
  );
};
