import { useTrackLink } from '@seek/cmsu-analytics';
import type { AccordionFragment } from '@seek/cmsu-cms-connect';
import { RichText } from '@seek/cmsu-rich-text';
import { AccordionItem } from 'braid-design-system';

type Props = Pick<AccordionFragment, 'content' | 'label'> & {
  uniqueTrackingId?: string;
  trackingAttributes?: Record<string, string>;
};

export const Accordion = ({
  content,
  label,
  uniqueTrackingId,
  trackingAttributes,
}: Props) => {
  const trackOnExpandEvent = useTrackLink(uniqueTrackingId, {
    ...trackingAttributes,
    elementDesign: 'accordion',
  });

  return (
    <AccordionItem
      label={label}
      id={label}
      key={label}
      onToggle={(expanded) => {
        if (expanded && trackOnExpandEvent) {
          trackOnExpandEvent();
        }
      }}
    >
      <RichText content={content.raw} />
    </AccordionItem>
  );
};
