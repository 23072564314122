import React from 'react';

export const SeekDonutShape = ({ color }: { color: string }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM40 53.3333C47.3638 53.3333 53.3333 47.3638 53.3333 40C53.3333 32.6362 47.3638 26.6667 40 26.6667C32.6362 26.6667 26.6667 32.6362 26.6667 40C26.6667 47.3638 32.6362 53.3333 40 53.3333Z"
      // fill="#1E47A9"
      fill={color}
    />
  </svg>
);
