type AnimationSpeed = 'very_fast' | 'fast' | 'normal' | 'slow' | 'very_slow';
const defaultSpeed = 1500;

export const toAnimationSpeed = (speed: AnimationSpeed) => {
  switch (speed) {
    case 'very_fast':
      return 500;
    case 'fast':
      return 1000;
    case 'normal':
      return defaultSpeed;
    case 'slow':
      return 2000;
    case 'very_slow':
      return 2500;
    default:
      return defaultSpeed;
  }
};
