type Tone = 'promote' | 'info' | 'positive' | 'caution' | 'critical';
import type { CTextTone } from '@seek/cmsu-cms-connect';
import type { Text } from 'braid-design-system';

type BraidTextProps = React.ComponentProps<typeof Text>;

export const toBraidTone = (tone: string, fallback: Tone = 'promote'): Tone => {
  const values = [
    'promote',
    'info',
    'positive',
    'caution',
    'critical',
  ] as const;
  return values.find((value) => value === tone) || fallback;
};

export const toBraidTextTone = (
  tone?: CTextTone | null,
  fallback: BraidTextProps['tone'] = 'neutral',
): BraidTextProps['tone'] => {
  const values = [
    'promote',
    'info',
    'positive',
    'caution',
    'critical',
    'neutral',
    'link',
    'brandAccent',
    'formAccent',
    'secondary',
  ] as const;
  return values.find((value) => value === tone) || fallback;
};
