import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_opjo4tf3zoifprrod44zuxxh6u/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Sy3KCMBTd8xV3ptMZWaSDClpw01V/w4nkitdCwoSgaMd/7xBDi9RHF2UDgcN53HNflvFWT+VqDJ8egMHGMJ5TJhNIURrUCw9gg5RtTALjIHhuzwVv2J6E2Vy+6mBhEJTNwjt5L4578n/c0yH39A73iqcfmVa1FExjidwkIJV7HHyv6IgJpEoaTrL99jgfwJGRFNgkEMd9S6G11CNPVa50Ak/v9sILcHQDvON6xNhyvKl2atZMat8aVlqgZpoLqqshJvX7vHPLK6gqc35IYJ2jtbytK0PrA2uTojT3mii5ECQzZlQ5lJr6fcBKGaOKa5gfO6/X7bR3Jkhjaki13am8Luz8bZmMDBZV3+TV6ZgLqfg80fOocly3kcoGKpWTuPKjM5FptU9g3K/+ctG4pb2p366Izkj+as56eytQEIcq1YgSuBQwKkh2iz4Pg7LxLX+nFtgTQIvqipl1e3fqAUMHdGUM5SNr7cHmCAdydmbn/vsq0d9UhgSPgsfxZBi8y+MQ0W0vtxEzh/heN6kkOkdfOM7KjPAEAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.14_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_opjo4tf3zoifprrod44zuxxh6u/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = '_9jr3nb6';
export var BannerTextColumnBoxStyle = '_9jr3nb4';
export var ShopfrontBannerTextColumnBoxStyle = '_9jr3nb5';
export var VerticalDivider = '_9jr3nb8';
export var VerticalDividerText = '_9jr3nba';
export var VerticalDividerWrapper = '_9jr3nb7';
export var VerticalLine = '_9jr3nb9';
export var bannerContainer = '_9jr3nb0';
export var headerImageStyle = '_9jr3nb2';
export var headerImageStyleEnlarged = '_9jr3nb1';
export var tertiaryImageStyle = '_9jr3nb3';