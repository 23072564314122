const dateLocale = 'en-GB';
const preferredDateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};
const alternativedDateOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const getDate = (date: number | Date | string) => new Date(date);

export const formatAbsoluteDatePreferredFormat = (
  date: number | Date | string,
): string => getDate(date).toLocaleDateString(dateLocale, preferredDateOptions);

export const formatAbsoluteDateAlternativeFormat = (
  date: Date | string,
): string =>
  getDate(date).toLocaleDateString(dateLocale, alternativedDateOptions);
