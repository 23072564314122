// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Browse all': () =>
      | 'Browse all'
      | 'เรียกดูทั้งหมด'
      | 'Telusuri semua'
      | '[ẞřöööŵšẽẽẽ ăăăƚƚ]';
  }
>({
  en: createLanguage({ 'Browse all': 'Browse all' }),
  th: createLanguage({ 'Browse all': 'เรียกดูทั้งหมด' }),
  id: createLanguage({ 'Browse all': 'Telusuri semua' }),
  zh: createLanguage({ 'Browse all': 'Browse all' }),
  'en-AU': createLanguage({ 'Browse all': 'Browse all' }),
  'en-HK': createLanguage({ 'Browse all': 'Browse all' }),
  'en-ID': createLanguage({ 'Browse all': 'Browse all' }),
  'en-MY': createLanguage({ 'Browse all': 'Browse all' }),
  'en-NZ': createLanguage({ 'Browse all': 'Browse all' }),
  'en-PH': createLanguage({ 'Browse all': 'Browse all' }),
  'en-SG': createLanguage({ 'Browse all': 'Browse all' }),
  'en-TH': createLanguage({ 'Browse all': 'Browse all' }),
  'th-TH': createLanguage({ 'Browse all': 'เรียกดูทั้งหมด' }),
  'id-ID': createLanguage({ 'Browse all': 'Telusuri semua' }),
  'zh-HK': createLanguage({ 'Browse all': 'Browse all' }),
  'en-PSEUDO': createLanguage({ 'Browse all': '[ẞřöööŵšẽẽẽ ăăăƚƚ]' }),
});

export default translations;
