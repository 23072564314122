import type { TileFragment } from '@seek/cmsu-cms-connect';
import { Tiles as BraidTiles, Divider } from 'braid-design-system';
import { spreadColumnsToResponsiveValues, getTileProps } from './helpers';
import type React from 'react';

type Props = Partial<Pick<TileFragment, 'dividers'>> & {
  columns: React.ComponentProps<typeof BraidTiles>['columns'];
  children: React.ComponentProps<typeof BraidTiles>['children'];
  space:
    | React.ComponentProps<typeof BraidTiles>['space']
    | TileFragment['space'];
};

const Tiles = ({ columns, dividers, children, space }: Props) => (
  <BraidTiles
    columns={spreadColumnsToResponsiveValues(columns)}
    space={space || 'gutter'}
  >
    {children}
    {dividers && <Divider />}
  </BraidTiles>
);

export { Tiles, spreadColumnsToResponsiveValues, getTileProps };
