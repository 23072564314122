import React from 'react';
import { BgTop } from './images/BgTop';
import { BgBottom } from './images/BgBottom';
import { Box } from 'braid-design-system';

export const getBg = () => (
  <Box display={{ desktop: 'inline', mobile: 'none' }}>
    <Box style={{ position: 'absolute', top: 0, right: 100 }}>
      <BgTop />
    </Box>
    <Box
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: '100px',
        borderBottomRightRadius: '16px',
        overflow: 'hidden',
      }}
    >
      <BgBottom />
    </Box>
  </Box>
);
