const prefixUrlWithLanguage = ({
  url,
  locale,
}: {
  url: string;
  locale?: string;
}) => {
  // locale would be default in some cases, so need to ignore if it is default
  const localeChars = locale?.slice(0, 2);
  if (localeChars === 'id' || localeChars === 'th') {
    return `/${localeChars}${url}`;
  }
  return url;
};

export { prefixUrlWithLanguage };
