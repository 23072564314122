import React from 'react';

export const SeekQuarterDonutShape = ({ color }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M200 100C200 44.7715 155.228 -6.78369e-06 100 -4.37014e-06C44.7715 -1.95658e-06 -6.78681e-06 44.7715 -4.37214e-06 100C-1.95747e-06 155.228 44.7715 200 100 200C155.228 200 200 155.228 200 100ZM133.333 100C133.333 81.5905 118.409 66.6667 100 66.6667C81.5905 66.6667 66.6667 81.5905 66.6667 100C66.6667 118.409 81.5905 133.333 100 133.333C118.409 133.333 133.333 118.409 133.333 100Z"
      fill={color}
    />
  </svg>
);
