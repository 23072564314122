import React from 'react';
import escapeHtml from 'escape-html';
import type { VideoProps } from '@graphcms/rich-text-types';

export function Video({ src, width, height, title }: Partial<VideoProps>) {
  return (
    <video
      src={escapeHtml(src)}
      controls
      width={width || '100%'}
      height={height || '100%'}
      title={title}
    >
      <span>
        Your browser doesn&apos;t support HTML5 video. Here is a{' '}
        <a href={src}>link to the video</a> instead.
      </span>
    </video>
  );
}
