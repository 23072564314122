import React from 'react';

export const SeekQuarterShape = ({ color }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.234372 0L64.2344 -2.79753e-06L64.2344 64C28.8881 64 0.234374 35.3463 0.234372 0Z"
      fill={color}
    />
  </svg>
);
