import { MAX_CARDS_PER_SLIDE } from '../components/TestimonialCarousel/TestimonialCarousel';
import type { Testimonials } from '../types';

export const getCarouselSlides = (cards: Testimonials) => {
  const slides: any[][] = [];
  let tempChunk: any = [];
  cards.forEach((card, index) => {
    tempChunk.push(card);
    if ((index + 1) % MAX_CARDS_PER_SLIDE === 0 || index === cards.length - 1) {
      slides.push(tempChunk);
      tempChunk = [];
    }
  });
  return slides;
};
